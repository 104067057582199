const helpOnClick = () => {
  if (window.HubSpotConversations) {
    if (window.HubSpotConversations.widget.status().loaded) {
      window.HubSpotConversations.widget.open()
      return
    }
    window.HubSpotConversations.widget.load({ widgetOpen: true })
  } else window.open('https://ayuda.aleluya.com/help/', '_blank')
}

export default helpOnClick

export const zohoHelpOnClick = () => {
  if (window.$zoho && window.$zoho.salesiq) {
    window.$zoho.salesiq.floatwindow.visible('0.2')
  } else {
    window.open('https://ayuda.aleluya.com/help/', '_blank')
  }
}
