import { Box, Button, Card, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { subscriptionPaymentCategories } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { capitalize } from 'utils/general'

import usePaymentModal from '../../PaymentModal/usePaymentModal'
import { PaymentStatus } from '../../helpers'
import { paymentMethodLogo } from './helpers'

const PaymentMethodCard = ({ subscriptionData }) => {
  const isAutomaticDebit =
    subscriptionData?.payment_category === 'automatic_debit'

  const isPsePayment = subscriptionData?.payment_category === 'pse'

  const isCreditCardPayment =
    subscriptionData?.payment_category === 'credit_card'

  const ccType =
    subscriptionData?.payment_method_info?.card_brand?.toLowerCase()
  const ccLastNumbers = subscriptionData?.payment_method_info?.card_last_numbers
  const accountNumber = subscriptionData?.account_number
  const paymentModal = usePaymentModal()

  const currentPaymentMethodTitle = isCreditCardPayment
    ? capitalize(subscriptionData?.payment_method_info?.card_brand)
    : `Cuenta ${capitalize(subscriptionData?.payment_method_info)}`

  const openPaymentModal = () =>
    paymentModal.openModal({
      isEditingPaymentMethod: true,
    })

  const showPaymentMethodTitle =
    !isPsePayment &&
    subscriptionData?.payment_category !== 'distributor_payment'

  return (
    <Card
      sx={(theme) => ({
        flexGrow: 1,
        width: '100%',
        padding: theme.spacing(2, 2, 5),
        '& p': {
          color: theme.palette.black.light,
        },
        '& b': {
          color: theme.palette.black.main,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(2),
        })}
      >
        <Typography variant="h5">Método de pago</Typography>
      </Box>
      {isAutomaticDebit ? (
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Typography variant="lead1">Estado</Typography>
          <PaymentStatus
            statusInfo={subscriptionData?.automatic_debit_account_info}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
          padding: theme.spacing(0.5, 1),
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '0.5rem',
          '& img': {
            marginRight: theme.spacing(1.5),
          },
        })}
      >
        <>
          {isCreditCardPayment ? (
            <>
              {paymentMethodLogo[ccType] ? (
                <Box
                  component="img"
                  src={paymentMethodLogo[ccType]}
                  alt="Credit card logo"
                  width={50}
                  height={40}
                />
              ) : (
                <Icon
                  name="smart-lock-card"
                  sx={(theme) => ({
                    marginRight: theme.spacing(1),
                    fontSize: '2rem',
                  })}
                />
              )}
            </>
          ) : null}
        </>
        {isAutomaticDebit ? (
          <Box
            component="img"
            src={paymentMethodLogo.automaticDebit}
            alt="Automatic Debit"
            width={70}
            height={70}
          />
        ) : null}
        {isPsePayment ? (
          <Box
            component="img"
            src={paymentMethodLogo.pse}
            alt="PSE Logo"
            width={30}
            height={30}
          />
        ) : null}
        <Box>
          <Typography variant="lead1">
            {subscriptionPaymentCategories[subscriptionData?.payment_category]
              ? subscriptionPaymentCategories[
                  subscriptionData?.payment_category
                ]
              : subscriptionData?.payment_category}
          </Typography>
          {showPaymentMethodTitle ? (
            <Typography>{currentPaymentMethodTitle}</Typography>
          ) : null}
          {accountNumber || ccLastNumbers ? (
            <Typography variant="body1" color="black.dark">
              Terminación{' '}
              {isAutomaticDebit ? accountNumber : `...${ccLastNumbers}`}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Button onClick={openPaymentModal} fullWidth>
        Editar método de pago
      </Button>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: theme.spacing(2),
        })}
      >
        <Typography variant="h6">Próximo cobro</Typography>
        <Typography variant="h6">
          <b>{formatDisplayDateString(subscriptionData?.active_until)}</b>
        </Typography>
      </Box>
    </Card>
  )
}

export default PaymentMethodCard
