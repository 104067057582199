import { Link } from 'react-router-dom'

import { Box, Button, Card, Typography, useTheme } from '@mui/material'

import AleluyaPerson from 'components/UI/AleluyaPerson'
import Icon from 'components/UI/Icon'

import { isArusDist } from 'utils/auth'

import getDescription from './helpers'

const DescriptionCard = ({
  data,
  redirectTo,
  actionOnClick,
  textColor = 'black.main',
  distributor,
  imageComponent: ImageComponent = null,
}) => {
  const themeInstance = useTheme()
  const {
    color = 'primary',
    title,
    content,
  } = getDescription(data, distributor)
  const colorKeys = color.split('.')
  const backgroundColor = color?.includes('.')
    ? themeInstance.palette[colorKeys[0]][colorKeys[1]]
    : themeInstance.palette[colorKeys[0]]?.main

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: isArusDist()
          ? theme.palette.primary.light
          : backgroundColor,
        padding: theme.spacing(3.5, 2.5),
        display: 'grid',
        gridTemplateColumns: '1fr',
        height: '100%',
        borderRadius: '1rem',
        gap: theme.spacing(4),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: '20rem 1fr',
          padding: theme.spacing(3, 3.5),
        },
        [theme.breakpoints.up('laptop')]: {
          padding: theme.spacing(3, 4),
        },
      })}
    >
      <Box
        sx={{
          display: {
            mobile: 'none',
            tablet: 'initial',
          },
        }}
      >
        {!ImageComponent ? (
          <AleluyaPerson
            color="white"
            width={330}
            height={405}
            viewBox="0 0 340 400"
          />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ImageComponent />
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        })}
      >
        <Typography variant="h4" color={textColor}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          color={textColor}
          sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        >
          {content}
        </Typography>
        <Button
          component={redirectTo ? Link : undefined}
          to={redirectTo || undefined}
          variant="outlined"
          startIcon={
            <Icon name="arrow-right" sx={{ transform: 'rotate(180deg)' }} />
          }
          size="large"
          sx={{
            width: 'fit-content',
          }}
          onClick={actionOnClick}
        >
          Regresar
        </Button>
      </Box>
    </Card>
  )
}

export default DescriptionCard
