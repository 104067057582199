import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import BankAccountHolderIdentificationNumber from 'components/UI/Formik/CommonFields/BankAccountHolderIdentificationNumber'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'

import { documentTypesOptions } from 'utils/company'

const AccountHolderIdentificationFields = () => {
  const { values } = useFormikContext()
  const showVerificationDigit = values.document_type === 'ni'

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        columnGap: theme.spacing(4.5),
        rowGap: theme.spacing(2.5),
        [theme.breakpoints.up('tablet')]: {
          gridTemplateColumns: showVerificationDigit
            ? '2fr 3fr'
            : 'repeat(2, 1fr)',
        },
      })}
    >
      <DocumentTypeField options={documentTypesOptions} optional={false} />
      <BankAccountHolderIdentificationNumber />
    </Box>
  )
}

export default AccountHolderIdentificationFields
