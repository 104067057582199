import { isBefore, isToday, parseISO } from 'date-fns'
import { useMemo } from 'react'

import { Box, Card, Chip, Typography, useTheme } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import AdvancedSubscriptionConfirmationModal from 'components/Subscription/Index/Plan/AdvancedSubscriptionConfirmationModal'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import { formatDisplayDate } from 'utils/dateTime'

import { getItems, getSubscriptionStatusConfiguration } from './helpers'

const CurrentPlan = ({ subscriptionData, currentPlan }) => {
  const themeInstance = useTheme()
  const modals = useModals()
  const { openPremiumFeatureModal } = usePremiumFeature()

  const {
    active_until: activeUntil,
    plan,
    status,
    type: currentSubscriptionType,
    payrolls_size: currentWorkersNumber,
    payment_status: paymentStatus,
  } = subscriptionData || {}

  const currentDate = new Date()
  const activeUntilDate = parseISO(activeUntil)
  const isPremiumExpired = status === 'premium_expired'
  const isValidDate =
    isBefore(currentDate, activeUntilDate) || isToday(activeUntilDate)

  const isActive = [
    'active_premium',
    'exonerated_payment',
    'active_premium_hr',
    'saas_subscription',
    'saas_subscription_hr',
  ].includes(status)
  const isCanceled = ['premium_canceled'].includes(status)

  const isPendingPayment = paymentStatus === 'pending'

  const subscriptionStatusConfiguration = getSubscriptionStatusConfiguration({
    isPendingPayment,
    isActive,
    isCanceled,
    isValidDate,
    themeInstance,
  })

  const items = useMemo(
    () => getItems(currentPlan, subscriptionData),
    [currentPlan, subscriptionData]
  )

  const openSubscriptionView = () => {
    openPremiumFeatureModal()
  }

  const handleOpenPlanPaymentModal = () => {
    modals.openModal({
      id: 'paymentModal',
      content: (
        <AdvancedSubscriptionConfirmationModal
          selectedPlan={plan}
          selectedModality={currentSubscriptionType}
          isAnUpgrade={false}
          currentPlanPayment
          activeUntil={activeUntil}
          planUpgrade={false}
          modalityUpgrade={false}
          currentWorkersNumber={currentWorkersNumber}
          isChangingModality={false}
          isCurrentPlan
          isPremiumExpiredSubscription={isPremiumExpired}
        />
      ),
      modalProps: {
        header: isPremiumExpired
          ? `Recupera tu plan ${plan.name}`
          : '¿Estás seguro de realizar este pago?',
        hideFooter: true,
        adornment: [
          {
            variant: 'signature',
            color: 'accent4.light',
            width: 165,
            height: 182,
            sx: {
              bottom: 85,
              right: 75,
            },
          },
        ],
        paperSx: {
          maxWidth: '45rem',
          width: '100%',
        },
      },
    })
  }

  return (
    <Card
      sx={(theme) => ({
        padding: theme.spacing(3),
        borderRadius: '1rem',
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(2),
          justifyContent: 'space-between',
          marginBottom: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          })}
        >
          <Typography variant="h4">{currentPlan?.name}</Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
            })}
          >
            <Chip
              sx={{
                color: subscriptionStatusConfiguration.color,
                backgroundColor:
                  subscriptionStatusConfiguration.backgroundColor,
              }}
              label={
                <Typography
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                      marginLeft: theme.spacing(1),
                    },
                  })}
                  variant="lead2"
                >
                  {subscriptionStatusConfiguration.label}{' '}
                  {subscriptionStatusConfiguration.icon}
                </Typography>
              }
            />
            {isPendingPayment || isCanceled ? (
              <Tooltip
                title={
                  isPendingPayment
                    ? '¡Relax!'
                    : isCanceled && isValidDate
                      ? null
                      : '¡Tu plan ha finalizado!'
                }
                description={
                  isPendingPayment ? (
                    'Tus funcionalidades siguen activas.'
                  ) : isCanceled && isValidDate ? (
                    <>
                      Cancelaste el pago recurrente de tu plan, pero tus
                      funcionalidades estarán activas hasta el{' '}
                      <b>{formatDisplayDate(activeUntilDate)}</b>.
                    </>
                  ) : (
                    <>
                      Reactiva tu suscripción para acceder a todas las
                      funcionalidades que <b>Aleluya</b> tiene para ti.
                    </>
                  )
                }
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    name="info"
                    basic
                    color={themeInstance.palette.primary.dark}
                  />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        {!isPendingPayment ? (
          <Box
            sx={(theme) => ({
              border: '2px oslid red',
              display: 'flex',
              columnGap: theme.spacing(2),
            })}
          >
            <Tooltip
              title="Relax!!!"
              description={
                <Typography>
                  Tus pagos están al día y tu plan se encuentra activo. Si
                  quieres modificar tu plan actual, utiliza el botón{' '}
                  <b>Cambiar plan</b>, o si necesitas realizar otros pagos,
                  comunícate con <b>Soporte</b>.
                </Typography>
              }
              disableHoverListener={!isValidDate}
            >
              <span>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleOpenPlanPaymentModal}
                  disabled={isValidDate}
                >
                  {isPremiumExpired ? 'Renovar mi plan' : 'Pagar plan'}
                </Button>
              </span>
            </Tooltip>
            <Button size="small" onClick={openSubscriptionView}>
              Cambiar plan
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box>
        {items.map((item) => {
          const isTotalItem = item.id === 'total'

          return (
            <Box
              key={item.id}
              sx={(theme) => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                paddingBottom: theme.spacing(0.5),
                borderBottom:
                  !isTotalItem && `1px solid ${theme.palette.white.dark}`,
              })}
            >
              <Typography
                color={isTotalItem ? 'black.main' : 'black.dark'}
                variant={isTotalItem ? 'h5' : 'h6'}
              >
                {item.label}
              </Typography>
              <Typography variant="lead1">{item.value}</Typography>
            </Box>
          )
        })}
      </Box>
    </Card>
  )
}

export default CurrentPlan
