import { Form, Formik } from 'formik'
import { useState } from 'react'

import { Box } from '@mui/material'

import PaymentModalFields from 'components/Subscription/Index/PaymentMethod/PaymentModalFields'
import {
  getInitialValues,
  getPaymentFields,
  getSchemaValidation,
} from 'components/Subscription/SubscriptionFormFields/helpers'
import Button from 'components/UI/Button/Button'

import usePayment from '../usePayment'
import useSubscription from '../useSubscription'

const PaymentModal = ({
  handleClose,
  currentPlan,
  extraWorkersEdit,
  handlePreviousModal,
  subscriptionChange = false,
  newSubscriptionType,
  isFromExtraWorkersModal = false,
  subscriptionTypeValidators,
  isEditingPaymentMethod = false,
  currentPlanPayment = false,
  isFromUpdateMethodInfo,
  openUpdatePaymentMethodInfoModal,
}) => {
  const { subscription } = useSubscription()
  const { payment_methods: paymentMethods } = subscription || {}
  const onlyOnePaymentMethod = Object.keys(paymentMethods || {}).length <= 1

  // Selects the available method or credit card when no payment methods are available
  const availablePaymentMethod = onlyOnePaymentMethod
    ? Object.keys(paymentMethods || {})[0] || 'credit_card'
    : ''

  const [activePayment, setActivePayment] = useState(
    availablePaymentMethod || subscription?.payment_category || 'credit_card'
  )

  const [isCurrentMethodPayment, setIsCurrentMethodPayment] = useState(
    subscription?.payment_category === activePayment
  )

  const { tabsData, onSubmit, updatePaymentMethod, formRef, isLoading } =
    usePayment(
      handleClose,
      currentPlan,
      extraWorkersEdit,
      subscriptionChange,
      newSubscriptionType,
      isFromExtraWorkersModal,
      subscriptionTypeValidators,
      isEditingPaymentMethod,
      currentPlanPayment,
      isFromUpdateMethodInfo,
      openUpdatePaymentMethodInfoModal
    )

  const handleSubmit = (values) =>
    onSubmit(values, activePayment, isCurrentMethodPayment)

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={getInitialValues(isCurrentMethodPayment)}
      validationSchema={getSchemaValidation(
        activePayment,
        isCurrentMethodPayment
      )}
      validateOnChange={false}
      enableReinitialize
    >
      {() => {
        return (
          <Form>
            <PaymentModalFields
              tabsData={tabsData}
              activePayment={activePayment}
              paymentFields={getPaymentFields(
                activePayment,
                isEditingPaymentMethod
              )}
              setActivePayment={setActivePayment}
              setIsCurrentMethodPayment={setIsCurrentMethodPayment}
              isEditingPaymentMethod={isEditingPaymentMethod}
              currentPlanPayment={currentPlanPayment}
              extraWorkersEdit={extraWorkersEdit}
            />
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(2),
                marginTop: theme.spacing(6),
                [theme.breakpoints.down('tablet')]: {
                  flexDirection: 'column',
                },
              })}
            >
              <Button
                onClick={updatePaymentMethod}
                loading={isLoading}
                disabled={isEditingPaymentMethod && isCurrentMethodPayment}
              >
                {isEditingPaymentMethod
                  ? 'Actualizar método de pago'
                  : 'Procesar pago'}
              </Button>
              <Button
                variant="outlined"
                onClick={
                  extraWorkersEdit || subscriptionChange
                    ? handlePreviousModal
                    : handleClose
                }
              >
                {extraWorkersEdit || subscriptionChange ? 'Atras' : 'Cancelar'}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PaymentModal
