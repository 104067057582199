import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Emoji from 'components/UI/Emoji'

import { partyPopperEmoji } from 'utils/emojis'

import {
  ONBOARDING_FIRST_STEPS,
  SUBSCRIPTION_END_FREE,
  WORKER_AFFILIATIONS_INDEX,
} from 'config/routes'

import useOnboardingMutation from '../useOnboardingMutation'

const OnboardingCongratulations = () => {
  const navigate = useNavigate()
  const { company, refreshCompany } = useUser()
  const { subscription } = useSubscription()
  const { handleUpdateOnboarding } = useOnboardingMutation()
  const valuesToSend = { onboarding_step: 'finished' }
  const userName = company.onboarding?.user_name
  const isAleluya10KCampaign = subscription?.utm_campaign === 'Aleluya10mil'

  const handleDiscoverAleluya = () => {
    handleUpdateOnboarding({
      data: { ...valuesToSend, selected_view: 'discover_nominapp' },
      callback: async () => {
        await refreshCompany()

        navigate(ONBOARDING_FIRST_STEPS(), {
          replace: true,
        })
      },
    })
  }

  const handleSSAffiliation = () => {
    handleUpdateOnboarding({
      data: { ...valuesToSend, selected_view: 'workers_affiliations' },
      callback: async () => {
        await refreshCompany()

        navigate(WORKER_AFFILIATIONS_INDEX(), {
          replace: true,
        })
      },
    })
  }

  const handleImmediatePayment = () => {
    handleUpdateOnboarding({
      data: valuesToSend,
      callback: async () => {
        await refreshCompany()

        navigate(SUBSCRIPTION_END_FREE(), {
          replace: true,
        })
      },
    })
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(14),
        },
      })}
    >
      <Typography variant="h3">
        ¡Aleluya, {userName}! <br />
        Has configurado tu cuenta. <Emoji code={partyPopperEmoji} />
      </Typography>
      {isAleluya10KCampaign ? (
        <Button
          onClick={handleImmediatePayment}
          sx={(theme) => ({ width: '100%', marginTop: theme.spacing(15) })}
        >
          Ir a pagar 10K
        </Button>
      ) : (
        <>
          <Typography
            variant="body1"
            sx={(theme) => ({
              margin: theme.spacing(7.5, 0),
            })}
          >
            ¿Qué deseas hacer ahora?{' '}
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Button onClick={handleDiscoverAleluya}>Descubrir Aleluya</Button>
            <Button variant="outlined" onClick={handleSSAffiliation}>
              Afiliar a Seguridad Social
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default OnboardingCongratulations
