import { useUser } from 'components/App/UserContext/useUser'

const useSubscription = () => {
  const { subscription } = useUser()
  const additionalWorkersInfo = subscription?.additional_workers_info
  const paidWorkers = subscription?.paid_workers

  return {
    additionalWorkersInfo,
    paidWorkers,
    subscription,
  }
}

export default useSubscription
