import { lastDayOfMonth, parseISO, subDays } from 'date-fns'

import { Box, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { formatDate } from 'utils/dateTime'
import useDashboardService from 'utils/hooks/dashboardService'

import * as routes from 'config/routes'

import { columnsData, getSocialBenefitName } from './helpers'

const Pendings = () => {
  const companyId = getCompanyId()
  const { dashboardQuery: dashboardPendingsQuery } = useDashboardService({
    serviceParams: {
      queryKey: ['dashboardByMetrics', 'pendings', companyId],
      metrics: 'pendings',
    },
  })
  const { dashboardQuery: dashboardNextPaymentsQuery } = useDashboardService({
    serviceParams: {
      queryKey: ['dashboardByMetrics', 'next_payments', companyId],
      metrics: 'next_payments',
    },
  })

  const {
    electronic_payroll_to_issue: electronicPayrollToIssue,
    electronic_payroll_limit_date: electronicPayrollLimitDate,
    electronic_payroll_authorized: electronicPayrollAuthorized,
    electronic_payroll_period_id: electronicPayrollPeriodId,
    next_payroll: nextPayroll,
    wages_update: wagesUpdate,
    social_security_integration: socialSecurityIntegration,
    social_benefits_provision: socialBenefitsProvision,
    novelty_request: noveltyRequest = {},
  } = dashboardPendingsQuery.data?.pendings || {}

  const { social_security: socialSecurity, social_benefits: socialBenefits } =
    dashboardNextPaymentsQuery?.data?.next_payments || {}

  const socialSecurityPaymentDate = socialSecurity?.limit_date
    ? parseISO(socialSecurity?.limit_date)
    : null
  const socialSecurityIntegrationDate = socialSecurityPaymentDate
    ? formatDate(subDays(socialSecurityPaymentDate, 1))
    : null

  const getTableData = () => {
    const data = []

    if (wagesUpdate) {
      data.push({
        name: 'Actualizar salarios',
        date: lastDayOfMonth(new Date()),
        status: false,
        pathTo: routes.WORKER_WAGES_UPDATE(),
      })
    }

    data.push({
      name: 'Liquidar nómina',
      date: nextPayroll?.end_day,
      status: nextPayroll?.payroll_paid,
      pathTo: routes.PERIOD_PAY_PAYROLL(),
    })

    if (electronicPayrollAuthorized) {
      data.push({
        name: 'Emitir nómina electrónica',
        date: electronicPayrollLimitDate,
        status: electronicPayrollToIssue === false,
        pathTo: routes.PERIOD_PAY_ELECTRONIC_PAYROLL(electronicPayrollPeriodId),
      })
    }

    if (!socialSecurityIntegration) {
      data.push({
        name: 'Integrar tu operador PILA',
        date: socialSecurityIntegrationDate,
        status: socialSecurityIntegration,
        pathTo: routes.SETTINGS_INTEGRATION_INDEX(),
      })
    }

    if (socialSecurity?.company_id_number) {
      data.push({
        name: 'Agregar número de identificación de la empresa',
        date: null,
        status: false,
        pathTo: {
          pathname: routes.COMPANY_EDIT(companyId),
          state: {
            initialStep: 0,
          },
        },
      })
    } else {
      data.push({
        name: 'Pagar seguridad social',
        date: socialSecurity?.limit_date,
        status: false,
        pathTo: routes.PERIOD_PAY_PREVIOUS_PAYROLL(socialSecurity?.period_id),
      })
    }

    if (socialBenefits?.show) {
      data.push({
        name: `Pago de ${getSocialBenefitName(socialBenefits?.category)}`,
        date: socialBenefits?.limit_date,
        status: socialBenefits?.status,
        pathTo: routes.PERIOD_SOCIAL_BENEFIT_PERIODS(
          socialBenefitsProvision?.period_id,
          socialBenefitsProvision?.social_benefits_period_id
        ),
      })
    }

    if (noveltyRequest?.pending_request) {
      data.push({
        name: 'Solicitud de tiempo fuera',
        date: noveltyRequest?.upcoming_request_date,
        status: false,
        pathTo: routes.NOVELTIES_REQUEST_HISTORY(),
      })
    }

    return data
  }

  return (
    <Box
      sx={(theme) => ({
        gridColumn: '1 / -1',
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / 10',
          gridRow: '3',
        },
      })}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
      >
        Recordatorios
      </Typography>
      {dashboardPendingsQuery.isLoading ||
      dashboardNextPaymentsQuery.isLoading ? (
        <LoadingBox />
      ) : (
        <Table
          columns={columnsData}
          data={getTableData()}
          options={{ toolbar: false, pagination: false, sorting: false }}
        />
      )}
    </Box>
  )
}

export default Pendings
