import { useFormikContext } from 'formik'
import { useContext, useEffect } from 'react'
import { useIsMutating } from 'react-query'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Collapse,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import ModalitySwitch from 'components/Subscription/Atoms/ModalitySwitch'
import TermsAcceptanceField from 'components/Subscription/SubscriptionFormFields/TermsAcceptanceField'
import Button from 'components/UI/Button/Button'
import SummaryItem from 'components/UI/Summary/SummaryItem'
import SummaryTotal from 'components/UI/Summary/SummaryTotal'

import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_END_FREE } from 'config/routes'

import { SubscriptionViewContext } from '../Index'
import { getSummaryItems } from './helpers'

const SubscriptionSummary = () => {
  const location = useLocation()
  const isSubscriptionPromoPath = location.pathname === SUBSCRIPTION_END_FREE()
  const { company } = useUser()
  const { errors, setFieldValue, handleSubmit, values } = useFormikContext()
  const { showErrorMessage } = useNotifications()
  const currentPaymentMethod = values?.payment_method || {}
  const showRecurrentPaymentCheckbox = currentPaymentMethod !== 'pse'

  const {
    subscription,
    setModality,
    modality,
    selectedPlan,
    loadingPayment,
    expandedAccordion,
  } = useContext(SubscriptionViewContext)

  const downLaptop = useMediaQuery((theme) =>
    theme.breakpoints.between('md', 'laptop')
  )

  const isCalculatingPrice = useIsMutating({
    mutationKey: 'calculateSubscriptionPrice',
    fetching: true,
  })

  const isPartner = subscription?.partner
  const isClaraPayment = subscription?.payment_category === 'clara_payment'
  const isAnnually = modality === 'year'
  const isPlanSelect = !isObjectEmpty(selectedPlan)

  const summaryItems = getSummaryItems(
    { ...selectedPlan, payment_type: subscription?.type },
    subscription,
    isAnnually,
    company?.name,
    isSubscriptionPromoPath
  )

  const yearlyDiscount =
    selectedPlan?.subscription_value?.discounts?.yearly_percentage * 100

  const totalItem = summaryItems.find(
    (summaryItem) => summaryItem.id === 'total'
  )

  const totalItems = summaryItems.filter((item) =>
    [
      'subtotal_item',
      'extra_workers_subtotal',
      'subscription_discount',
    ].includes(item.id)
  )

  const handleContinueAction = () => {
    if (errors?.workers_number && !subscription?.partner) {
      showErrorMessage('Debes ingresar la cantidad de personas de tu plan')
      return
    }

    if (!values?.payment_method) {
      showErrorMessage('Por favor, selecciona el método de pago')
      return
    }

    handleSubmit()
  }

  const onChange = (value) => {
    setModality(value)
  }

  useEffect(() => {
    setFieldValue('modality', modality)
  }, [modality, expandedAccordion, setFieldValue])

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundColor: theme.palette.white.main,
        borderRadius: '1rem',
        padding: theme.spacing(8, 1, 2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(8, 4, 2),
        },
        [theme.breakpoints.up('md')]: {
          height: '100%',
          borderRadius: 0,
          width: '25rem',
          marginBottom: 0,
        },
        [theme.breakpoints.up('laptop')]: {
          width: '30rem',
        },
      })}
      data-cy="subscription_payment_summary"
    >
      {!isPartner && !isClaraPayment ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ModalitySwitch
            onChange={onChange}
            modality={modality}
            discount={yearlyDiscount}
          />
        </Box>
      ) : null}
      <Typography
        variant={downLaptop ? 'h5' : 'h4'}
        sx={(theme) => ({
          margin: theme.spacing(4, 0, 6),
          [theme.breakpoints.between('md', 'laptop')]: {
            marginBottom: theme.spacing(2),
          },
        })}
      >
        Resumen de tu suscripción
      </Typography>
      {summaryItems.map((summaryItem) => {
        if (
          ![
            'total',
            'extra_workers_subtotal',
            'subtotal_item',
            'subscription_discount',
          ].includes(summaryItem.id)
        ) {
          return (
            <SummaryItem
              key={summaryItem.id}
              label={summaryItem.label}
              value={summaryItem.value}
              center={['extra_workers', 'plan'].includes(summaryItem.id)}
            />
          )
        }
        return null
      })}
      <Box>
        {!isCalculatingPrice ? (
          <SummaryTotal
            label="Total a pagar"
            value={totalItem?.value}
            items={totalItems}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Skeleton
              animation="wave"
              width="20%"
              sx={{ borderRadius: '0.625rem' }}
            />
            <Skeleton
              animation="wave"
              width="50%"
              sx={{ borderRadius: '0.625rem' }}
            />
            <Skeleton
              animation="wave"
              width="40%"
              sx={{ borderRadius: '0.625rem' }}
            />
            {isAnnually ? (
              <Skeleton
                animation="wave"
                width="30%"
                sx={{ borderRadius: '0.625rem' }}
              />
            ) : null}
            <Skeleton
              animation="wave"
              width={294}
              height={70}
              sx={{ borderRadius: '1.87rem' }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: theme.spacing(1),
        })}
      >
        <Collapse
          in={showRecurrentPaymentCheckbox}
          transition={300}
          sx={(theme) => ({
            boxShadow: theme.shadows[4],
            borderRadius: '1rem',
          })}
        >
          <Box
            sx={(theme) => ({
              padding: theme.spacing(2, 0, 2, 2),
            })}
          >
            <TermsAcceptanceField variant="first_payment" />
          </Box>
        </Collapse>
        <Button
          fullWidth
          disabled={!isPlanSelect}
          loading={loadingPayment}
          onClick={handleContinueAction}
          data-cy="subscription_payment_button_ok"
          sx={(theme) => ({
            maxWidth: '21.87rem',
            marginTop: theme.spacing(2),
          })}
        >
          Pagar suscripción
        </Button>
      </Box>
    </Box>
  )
}

export default SubscriptionSummary
