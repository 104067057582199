import { alpha, useTheme } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { getColumnsData } from './columnsData'
import { tableTitles } from './helpers'

const PeriodDetailsBodyDataTable = ({
  name,
  data,
  isTerminationTab,
  tabletDown,
}) => {
  const theme = useTheme()
  const columnsData = getColumnsData(name, tabletDown)
  const needsRoundedBorder = name === 'base_salary' || name === 'uvt_range'
  const isOldSectionId = Object.keys(tableTitles)
    .filter((value) => value !== 'base_salary')
    .includes(name)

  return (
    <Table
      columns={columnsData}
      data={data}
      sx={{
        tableContainerSx: (theme) => ({
          borderBottomLeftRadius:
            isOldSectionId || !needsRoundedBorder ? 0 : '1rem',
          borderBottomRightRadius:
            isOldSectionId || !needsRoundedBorder ? 0 : '1rem',
          paddingBottom: name === 'uvt_range' && theme.spacing(3),
        }),
      }}
      options={{
        flatStyle: true,
        pagination: false,
        search: false,
        sorting: false,
        toolbar: false,
        rowStyle: (rowData) => {
          if (rowData.symbol === '=') {
            if (isTerminationTab)
              return {
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                height: '5rem',
              }

            if (rowData.name)
              return {
                fontWeight: 'bold',
                fontSize: 'xLarge',
              }
          }
        },
      }}
    />
  )
}

export default PeriodDetailsBodyDataTable
