import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import { useSupportMenuContext } from 'components/App/SupportMenuContext'
import usePaymentModal from 'components/Subscription/Atoms/PaymentModal/usePaymentModal'
import AleluyaPerson from 'components/UI/AleluyaPerson'
import Button from 'components/UI/Button/Button'
import LinkButton from 'components/UI/Button/LinkButton'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import HelloWoman from 'assets/images/views/common/hello_woman.svg'

const getInfoMessages = (actionMessage, openSupportMenu, activePayment) => {
  const paymentType =
    activePayment === 'automatic_debit'
      ? 'cuenta bancaria'
      : 'tarjeta débito/crédito'

  const configMessages = {
    invitation_to_update: {
      title: `¡Yisus! Aún falta tu método de pago`,
      buttonLabel: 'Actualizar método de pago',
      description: (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            En este momento, no tienes un método de pago asociado a tu cuenta.
            Actualiza tus datos en menos de lo que canta un gallo.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Si tienes dudas, puedes contactarnos dando{' '}
            <LinkButton onClick={openSupportMenu}>clic aquí.</LinkButton>
          </Typography>
        </>
      ),
      picture: (
        <Box
          component="img"
          alt="Relájate mientras tienes Aleluya Premium"
          src={HelloWoman}
          width={252}
          height={280}
          sx={{
            objectFit: 'contain',
          }}
        />
      ),
    },
    successful_update: {
      title: `¡Método de pago actualizado!`,
      buttonLabel: 'Cerrar',
      description: (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
          >
            Tu débito automático a través de una{' '}
            <Typography variant="lead1">{paymentType}</Typography> ha sido
            agregado. Ahora haremos un par de validaciones para verificar que
            hayas ingresado tus datos de forma correcta.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Te estaremos notificando cuando todo este OK!.
          </Typography>
        </>
      ),
      picture: (
        <AleluyaPerson
          color="primary"
          width={330}
          height={280}
          viewBox="0 0 340 400"
        />
      ),
    },
  }

  return configMessages[actionMessage]
}

const UpdatePaymentMethodInfoModal = ({
  state,
  handleClose,
  openUpdatePaymentMethodInfoModal,
}) => {
  const { open, actionMessage, activePayment } = state

  const paymentModal = usePaymentModal()
  const { openSupportMenuFromOutside } = useSupportMenuContext()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleCloseModal = () => {
    localStorage.setItem('one_by_session_info_modal', 'modalViewed')
    handleClose()
  }

  const handleOpenSupportMenu = () => {
    openSupportMenuFromOutside()
    handleCloseModal()
  }

  const { title, buttonLabel, description, picture } = getInfoMessages(
    actionMessage,
    handleOpenSupportMenu,
    activePayment
  )

  const openPaymentModal = () => {
    handleCloseModal()

    paymentModal.openModal({
      isEditingPaymentMethod: true,
      isFromUpdateMethodInfo: true,
      openUpdatePaymentMethodInfoModal,
    })
  }

  const handleClick = () => {
    if (actionMessage === 'invitation_to_update') {
      openPaymentModal()
    } else {
      handleCloseModal()
    }
  }

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      hideFooter
      patternBackgroundColor="complementary2.main"
      paperSx={{
        maxWidth: '52rem',
        position: 'relative',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
        minHeight: '22.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
        }}
      >
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {picture}
            </Box>
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h2"
                sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
              >
                {title}
              </Typography>
              <ButtonBase
                onClick={handleCloseModal}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="close" sx={{ fontSize: '1.7rem' }} />
              </ButtonBase>
            </Box>
            {description}
          </Box>

          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <Button onClick={handleClick}>{buttonLabel}</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdatePaymentMethodInfoModal
