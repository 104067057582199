import { useFormikContext } from 'formik'

import { Box, FormHelperText, Typography } from '@mui/material'

import DeleteItemButton from 'components/UI/Button/DeleteIconButton'
import Dropzone from 'components/UI/Dropzone/Dropzone'

const LogoFormField = () => {
  const form = useFormikContext()

  const onAddLogo = (file) => {
    if (file instanceof Blob) {
      form.setFieldValue('logo', file)

      const reader = new FileReader()

      reader.onload = (e) =>
        document
          .getElementById('company_logo_preview')
          .setAttribute('src', e.target.result)

      reader.readAsDataURL(file)
    }
  }

  const onRemoveLogo = () => form.setFieldValue('logo', null)

  const { values: { logo } = {}, errors = {} } = form

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(7),
        '& > p': {
          margin: theme.spacing(2, 0),
        },
      })}
    >
      <Typography variant="h6" color="primary">
        Logo de la empresa
      </Typography>
      <Typography variant="body2">
        Agrega el logo de tu empresa para que aparezca en las colillas de pago y
        liquidaciones, Para que tu logo se vea bien, debe tener 110 píxeles de
        ancho y 37 píxeles de alto. Su tamaño no debe superar los 200KB.
      </Typography>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(4.25),
          gap: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '8.125rem',
              height: '5rem',
              padding: '1.5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {logo ? (
              <Box
                component="img"
                id="company_logo_preview"
                src={logo}
                width={110}
                height={37}
                sx={{
                  objectFit: 'contain',
                }}
                alt="Logo de la empresa"
              />
            ) : (
              <p>Sin Logo</p>
            )}
          </Box>

          {logo ? (
            <DeleteItemButton
              onClick={onRemoveLogo}
              style={{ marginLeft: 8 }}
            />
          ) : null}
        </Box>
        <Box>
          <Dropzone
            value={logo?.name}
            type="inline"
            accept="image/png, image/jpeg, image/x-icon"
            handleAdd={onAddLogo}
          />
          {errors?.logo ? (
            <FormHelperText error>{errors?.logo}</FormHelperText>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default LogoFormField
