import { isOrganizer } from 'utils/auth'
import { isDev } from 'utils/general'

import { integrationEventsPayload } from './integrations/events/eventsHandler'
import appcues from './integrations/scripts/appcues'
import { flowxoScript } from './integrations/scripts/flowxo'
import { googleOptimizeScript } from './integrations/scripts/googleOptimize'
import { googleTagManagerScript } from './integrations/scripts/googleTagManager'
import headwayScript from './integrations/scripts/headway'
import hubspotScript from './integrations/scripts/hubspot'
import zohoScript from './integrations/scripts/zoho'
import trackDataLayer from './integrations/trackDataLayer'

// Function to load integration data
export default async function loadIntegrationsData(company, subscription) {
  if (isOrganizer() || isDev) return

  const userId = localStorage.getItem('user_id')

  // Appcues
  await appcues.initialConfiguration(userId, company, subscription)

  // to verify, reload page
  window.Appcues?.page()
}

// Function to handle eventTrigger
export function trackEvent(...args) {
  if (isOrganizer() || isDev) return

  const eventPayload = integrationEventsPayload(args)

  // Appcues
  appcues.trackEvent(JSON.stringify(eventPayload.appcues))

  // trackDataLayer
  trackDataLayer.trackEvent(JSON.stringify(eventPayload.trackDataLayer))
}

export const chatScripts = {
  flowxo: flowxoScript,
  hubspot: hubspotScript,
  zoho: zohoScript,
}

export const commonScripts = {
  // Analitycs
  googleOptimize: googleOptimizeScript,
  googleTagManager: googleTagManagerScript,
  // Tracking Events
  appcues: appcues.script,
  trackDataLayer,
  headway: headwayScript,
}
