import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'

const Tabs = ({
  value,
  onChange,
  tabs,
  wrapped,
  backgroundColor,
  sx,
  ...rest
}) => {
  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      {...rest}
      sx={[
        (theme) => ({
          borderTopRightRadius: theme.spacing(1),
          borderTopLeftRadius: theme.spacing(1),
          ...(backgroundColor === 'white' && {
            backgroundColor: theme.palette.white.main,
          }),
        }),
        sx,
      ]}
    >
      {tabs.map((tab, index) =>
        tab.hidden ? null : (
          <MuiTab
            key={tab.key || index}
            label={tab.label}
            value={tab.key}
            icon={tab.icon}
            wrapped={wrapped}
            data-cy={tab.cyData || ''}
          />
        )
      )}
    </MuiTabs>
  )
}

export default Tabs
