import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Grow, Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import { getCompanyId } from 'utils/company'
import { partyPopperEmoji, rocket } from 'utils/emojis'

import usePremiumFeature from '../Premium/usePremiumFeature'
import SupportActionButton from './SupportActionButton'

const CustomerSupportMenu = () => {
  const { showPremiumFeatures } = usePremiumFeature()
  const location = useLocation()
  const companyId = getCompanyId()

  const isCompaniesRoute =
    location.pathname === '/companies' ||
    location.pathname.includes('/organizer/companies')

  const { HubSpotConversations } = window || {}

  const launchHubSpotWidgetActions =
    showPremiumFeatures && !isCompaniesRoute && Boolean(HubSpotConversations)

  const { widget: chatWidget, on: onHubSpotEvent } = HubSpotConversations || {}
  const isChatWidgetLoaded = chatWidget?.status()?.loaded
  const isChatWidgetLoading = chatWidget?.status()?.pending

  const [showChat, setShowChat] = useState(
    isChatWidgetLoaded && showPremiumFeatures
  )

  const [showChatTransition, setShowChatTransition] = useState(false)

  useEffect(() => {
    setShowChatTransition(isChatWidgetLoading)

    if (!isChatWidgetLoaded) {
      setShowChat(false)
    }

    if (launchHubSpotWidgetActions) {
      if (!isChatWidgetLoaded) {
        chatWidget?.load()
      }

      onHubSpotEvent('widgetLoaded', () => {
        setShowChat(true)
      })
    }
  }, [
    onHubSpotEvent,
    chatWidget,
    isChatWidgetLoaded,
    launchHubSpotWidgetActions,
    isChatWidgetLoading,
  ])

  const showZohoChat = [
    'e50515f4-aceb-48f8-a140-adf8499cfe81',
    '56280701-8155-4f2b-8593-d42dbc252bfd',
  ].includes(companyId)

  return (
    <>
      <Typography
        variant="h5"
        sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}
      >
        Tranqui, te ayudamos!
      </Typography>
      <Typography variant="body2">
        Aclaramos tus dudas más rápido que Flash! <Emoji code={rocket} />
      </Typography>
      <Typography
        variant="body2"
        sx={(theme) => ({ marginTop: theme.spacing(0.5) })}
      >
        Tómate un tintico con uno de nuestros expertos y grita: Aleluya!{' '}
        <Emoji code={partyPopperEmoji} />
      </Typography>

      <Box sx={(theme) => ({ margin: theme.spacing(1, 0, 0.5) })}>
        <Typography variant="lead2">
          Estamos disponibles para ti en este horario:
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({ marginTop: theme.spacing(1) })}
        >
          Lunes a viernes 8:30am a 5pm
        </Typography>
        <Typography variant="body2">Sábados 8:30am a 12pm</Typography>
      </Box>

      <SupportActionButton variant="mail" />
      {showZohoChat ? <SupportActionButton variant="zohoChat" /> : null}

      {!showPremiumFeatures ? null : (
        <>
          {/* WhatsApp chat is temporarily hidden */}
          {/* <SupportActionButton variant="whatsapp" /> */}
          {showChat ? (
            <Grow in timeout={showChatTransition ? 300 : 0}>
              <Box>
                <SupportActionButton variant="chat" />
              </Box>
            </Grow>
          ) : null}
        </>
      )}
    </>
  )
}

export default CustomerSupportMenu
