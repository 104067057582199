import { Button } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'

import useElectronicPayrollFileService from 'utils/hooks/ElectronicPayroll/fileService'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

const Resume = ({ electronicPayrollPeriodId }) => {
  const { downloadFileMutation } = useElectronicPayrollFileService()
  const downloadFile = useEnqueueDownload()

  const { openPremiumFeatureModal, showPremiumFeatures } = usePremiumFeature()

  const handleDownload = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    downloadFileMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'electronicPayrollSummary',
        electronicPayrollPeriodId,
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'Resumen de nómina electrónica',
            fileCode: 'summary_electronic_payroll',
            pathname: `/electronic_payroll/${electronicPayrollPeriodId}/electronic_payroll_summary`,
          })
        },
      }
    )
  }

  return (
    <Button
      onClick={handleDownload}
      endIcon={<PremiumFeatureIcon />}
      style={{ marginRight: 8 }}
    >
      Descargar resumen
    </Button>
  )
}

export default Resume
