import { Form, Formik } from 'formik'
import * as yup from 'yup'

import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'

import FormField from '../Formik/FormField/Index'

yup.setLocale(yupLocaleES)

const initialValues = { name: '' }

const validationSchema = yup.object({
  name: yup.string().trim().required(),
})

const SingleFieldModal = ({
  handleSubmit,
  handleClose,
  formikProps,
  modalProps,
  fieldProps,
  fieldValidation = {},
}) => {
  const handleChangeValue = ({ target: { value } }, form) => {
    const { max, min } = fieldValidation
    let cantWorkers = value

    if (max !== null) {
      if (value > max) cantWorkers = max
    }

    if (min !== null) {
      if (value < min) cantWorkers = min
    }

    form.setValues({
      [fieldProps.name]: cantWorkers,
    })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      {...formikProps}
    >
      {(form) => {
        const { handleSubmit: handleSubmitForm, isSubmitting } = form

        return (
          <Modal
            open
            onOk={handleSubmitForm}
            onCancel={handleClose}
            okText="Guardar"
            isLoading={isSubmitting}
            dialogProps={{
              maxWidth: 'xs',
              fullWidth: true,
            }}
            {...modalProps}
          >
            <Form>
              <FormField
                label="Nombre"
                disabled={isSubmitting}
                onChange={(e) => handleChangeValue(e, form)}
                autoFocus
                {...fieldProps}
              />
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default SingleFieldModal
