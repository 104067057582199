import useLocalStorage from '@rehooks/local-storage'
import { useEffect, useRef, useState } from 'react'

import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Fab,
  Fade,
  Popper,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import { isTest } from 'utils/general'

import { useSupportMenuContext } from '../SupportMenuContext'
import { useUser } from '../UserContext/useUser'
import CustomerSupportMenu from './CustomerSupportMenu'

const SupportMenuButton = () => {
  const themeInstance = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showChatAlert, setShowChatAlert] = useLocalStorage('show_chat_alert')
  const { distributor } = useUser()
  const supportButtonRef = useRef(null)
  const {
    closeSupportMenuFromOutside,
    supportMenuState: { supportMenuOpenedFromOutside },
    showChat,
    emailOpen,
    showSupportActionGroup,
    setShowChat,
    setEmailOpen,
    setShowSupportActionGroup,
  } = useSupportMenuContext()

  const isArusDistributor = distributor === 'arus'

  const [showAlert, setShowAlert] = useState(() => {
    if ((showChatAlert !== undefined && showChatAlert === false) || isTest)
      return false

    return true
  })

  const handleCloseAlert = () => {
    setShowAlert(false)
    setShowChatAlert(false)
  }

  const openActionGroup = (event) => {
    if (showAlert) {
      handleCloseAlert()
    }

    setShowSupportActionGroup((previousState) =>
      !showChat || !window.HubSpotConversations ? !previousState : false
    )
    closeSupportMenuFromOutside()
    setAnchorEl(event.currentTarget)
    setShowChat(false)
  }

  useEffect(() => {
    const showOverlay = () => {
      setEmailOpen(false)
    }

    window.addEventListener('focus', showOverlay)

    return () => {
      window.removeEventListener('focus', showOverlay)
    }
  }, [setEmailOpen])

  useEffect(() => {
    if (supportMenuOpenedFromOutside && supportButtonRef) {
      setTimeout(() => {
        supportButtonRef.current.click()
      })
    }
  }, [supportMenuOpenedFromOutside])

  const id =
    showSupportActionGroup && Boolean(anchorEl)
      ? 'customer-support-popper'
      : undefined

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      setShowSupportActionGroup(false)
      setShowChat(false)
      closeSupportMenuFromOutside()
    }
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          position: emailOpen ? 'fixed' : 'static',
          top: 0,
          bottom: 0,
          backgroundColor: alpha(theme.palette.black.main, 0.7),
          right: 0,
          left: 0,
          zIndex: 2,
          opacity: 0,
          visibility: 'hidden',
          transition: 'opacity 0.3s, visibility 0.3s',
          ...(emailOpen && {
            opacity: 1,
            visibility: 'visible',
            zIndex: 1101,
          }),
        })}
      />
      <ClickAwayListener
        onClickAway={() => {
          setShowSupportActionGroup(false)
          setShowChat(false)
          closeSupportMenuFromOutside()
        }}
      >
        <Box>
          <Box id="app-floating-action-group">
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '0.5rem',
                    padding: theme.spacing(2),
                    boxShadow: theme.shadows[2],
                    position: 'relative',
                  }),
                },
                popper: { sx: { zIndex: 1299 } },
              }}
              open={showAlert}
              title={
                <Box>
                  <ButtonBase
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                    }}
                    onClick={handleCloseAlert}
                  >
                    <Icon
                      name="close-2"
                      fontSize="small"
                      basic
                      color={themeInstance.palette.black.light}
                    />
                  </ButtonBase>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.black.light })}
                  >
                    ¿Necesitas un experto Aleluya? Escríbenos...
                  </Typography>
                </Box>
              }
              placement="top-end"
            >
              <Fab
                ref={supportButtonRef}
                aria-label="Helpdesk"
                sx={(theme) => ({
                  position: 'absolute',
                  right: 8,
                  bottom: 96,
                  transition: 'bottom 0.3s ease-in-out',
                  pointerEvents: 'auto',
                  boxShadow: theme.shadows[3],
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                  zIndex: 1110,
                  [theme.breakpoints.up('tablet')]: {
                    right: 24,
                  },
                  ...(((showChat && window.HubSpotConversations) ||
                    showSupportActionGroup) && {
                    bottom: 24,
                    transition: 'bottom 0.3s ease-in-out',
                  }),
                })}
                onClick={openActionGroup}
                onKeyDown={onKeyDown}
              >
                {!showSupportActionGroup &&
                (!showChat || !window.HubSpotConversations) ? (
                  <Icon
                    name="customer-service"
                    basic
                    sx={{
                      fontSize: '3.125rem',
                    }}
                    color={
                      isArusDistributor
                        ? themeInstance.palette.white.main
                        : themeInstance.palette.black.main
                    }
                  />
                ) : (
                  <Icon
                    name="close-2"
                    basic
                    sx={{
                      fontSize: '2.5rem',
                    }}
                    color={
                      isArusDistributor
                        ? themeInstance.palette.white.main
                        : themeInstance.palette.black.main
                    }
                  />
                )}
              </Fab>
            </Tooltip>
          </Box>
          <Popper
            id={id}
            open={showSupportActionGroup || showChat}
            anchorEl={anchorEl}
            placement="top-end"
            transition={!showChat}
            sx={(theme) => ({ zIndex: theme.zIndex.appBar + 100 })}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={300}>
                <Box
                  sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    bottom: -50,
                    transition: 'bottom 0.3s ease-in-out',
                    backgroundColor: theme.palette.accent2.main,
                    borderRadius: '1rem',
                    width: '20rem',
                    padding: theme.spacing(3),
                    boxShadow: theme.shadows[2],
                    [theme.breakpoints.up('tablet')]: {
                      width: '23rem',
                      maxHeight: '30rem',
                    },
                    ...(!showSupportActionGroup && { display: 'none' }),
                  })}
                >
                  {!showChat ? <CustomerSupportMenu /> : null}
                </Box>
              </Fade>
            )}
          </Popper>
          <Box
            sx={(theme) => ({
              position: 'absolute',
              right: 20,
              bottom: 4,
              zIndex: 1101,
              maxHeight: '31.25rem',
              height: '100%',
              maxWidth: '20rem',
              width: '100%',
              '& #hubspot-conversations-inline-iframe': {
                width: '100%',
                maxHeight: '31.25rem',
                height: '100%',
                borderRadius: '1rem',
                border: 'none',
                margin: theme.spacing(0.5),
                boxShadow: theme.shadows[4],
              },
              '& #hubspot-conversations-inline-parent': {
                maxHeight: '25rem',
                height: '100%',
              },
              [theme.breakpoints.up('tablet')]: {
                right: 36,
                maxWidth: '25rem',
                width: '100%',
              },
              [theme.breakpoints.up('desktop')]: {
                bottom: 100,
                maxWidth: '25rem',
                width: '100%',
                '& #hubspot-conversations-inline-iframe': {
                  width: '100%',
                },
                '& #hubspot-conversations-inline-parent': {
                  maxHeight: '40rem !important',
                  height: '100%',
                },
              },
              ...(!showChat && { display: 'none' }),
            })}
            id="hubspot-chat_inline-embed"
          />
        </Box>
      </ClickAwayListener>
    </>
  )
}

export default SupportMenuButton
