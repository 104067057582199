import { Box, ButtonBase, IconButton, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { capitalize } from 'utils/general'

const CalendarHeader = ({
  title,
  prevButtonProps,
  nextButtonProps,
  setShowYearGrid,
  showYearGrid,
  hasAvailableYears,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <ButtonBase
        onClick={() => setShowYearGrid((prevValue) => !prevValue)}
        disabled={!hasAvailableYears}
        sx={(theme) => ({
          maxWidth: '10.6rem',
          justifyContent: 'flex-start',
          color: theme.palette.black.main,
          '&:focus': {
            color: `${theme.palette.primary.main} !important`,
          },
        })}
      >
        <Typography variant="lead1" color="inherit">
          {capitalize(title?.replace(/de/i, ''))}
        </Typography>
        {hasAvailableYears ? (
          <Icon
            name="arrow-down"
            basic
            sx={(theme) => ({
              marginLeft: theme.spacing(0.5),
              fontSize: '1rem',
              transform: 'rotate(0deg)',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              ...(showYearGrid && {
                transform: 'rotate(180deg)',
              }),
            })}
          />
        ) : null}
      </ButtonBase>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(4),
        })}
      >
        <IconButton
          aria-label={prevButtonProps['aria-label']}
          onFocus={prevButtonProps.onFocus}
          onBlur={prevButtonProps.onBlur}
          onClick={prevButtonProps.onPress}
          disabled={prevButtonProps.isDisabled}
          sx={(theme) => ({
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s linear 0s, opacity 300ms',
            borderRadius: '0.5rem',
            '&::before': {
              borderRadius: 'inherit',
            },
            ...(showYearGrid && {
              visibility: 'hidden',
              opacity: 0,
              transition: 'visibility 0s linear 300ms, opacity 300ms',
            }),
            ...(prevButtonProps.isDisabled && {
              '& > svg': {
                color: theme.palette.white.main,
              },
            }),
          })}
        >
          <Icon
            name="arrow-right-2"
            basic
            fontSize="small"
            sx={{
              transform: 'rotate(180deg)',
            }}
          />
        </IconButton>
        <IconButton
          aria-label={nextButtonProps['aria-label']}
          onFocus={nextButtonProps.onFocus}
          onBlur={nextButtonProps.onBlur}
          onClick={nextButtonProps.onPress}
          disabled={nextButtonProps.isDisabled}
          sx={(theme) => ({
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s linear 0s, opacity 300ms',
            borderRadius: '0.5rem',
            '&::before': {
              borderRadius: 'inherit',
            },
            ...(showYearGrid && {
              visibility: 'hidden',
              opacity: 0,
              transition: 'visibility 0s linear 300ms, opacity 300ms',
            }),
            ...(nextButtonProps.isDisabled && {
              '& > svg': {
                color: theme.palette.white.main,
              },
            }),
          })}
        >
          <Icon name="arrow-right-2" basic fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CalendarHeader
