import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import { getTabsData } from 'components/Subscription/SubscriptionFormFields/helpers'

import { getCompanyId } from 'utils/company'
import { getWordSingular } from 'utils/format'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION_TRANSACTION_RESPONSE } from 'config/routes'

import { getPaymentData } from '../Index/helpers'
import useTransactionResponseModal from './TransactionResponseModal/useTransactionResponseModal'
import useSubscription from './useSubscription'

const usePayment = (
  onCancel,
  plan = {},
  extraWorkers = 0,
  subscriptionChange,
  newSubscriptionType,
  isFromExtraWorkersModal,
  subscriptionTypeValidators,
  isEditingPaymentMethod,
  currentPlanPayment,
  isFromUpdateMethodInfo,
  openUpdatePaymentMethodInfoModal
) => {
  // Access to subscription from context for payroll and workers view
  const { refreshCompany } = useUser()
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const formRef = useRef()
  const { showInfoMessage } = useNotifications()
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()
  const companyId = getCompanyId()

  const navigateToTransactionResponse = (paymentData) => {
    navigate(
      SUBSCRIPTION_TRANSACTION_RESPONSE(
        `?status=${paymentData.payment_status}`
      ),
      {
        state: {
          payment_status: paymentData.payment_status,
          from_extraworkers_modal: isFromExtraWorkersModal,
        },
      }
    )
  }

  const subscriptionType = subscription?.type

  const paidWorkers = subscriptionChange
    ? subscription.paid_workers
    : extraWorkers || subscription?.payrolls_size

  const { openTransactionResponseModal } = useTransactionResponseModal()

  const { paymentMethodMutation } = usePaymentMethodService({
    queryOptions: {
      enabled: false,
    },
  })

  const tabsData = getTabsData(
    subscription,
    isEditingPaymentMethod,
    currentPlanPayment
  )

  const handleForm = (formHandler) => {
    if (formRef.current) {
      formRef.current?.[formHandler]()
    }
  }

  const callbackSuccess = (response, activePayment) => {
    queryClient.invalidateQueries(['getSubscription', companyId])
    queryClient.invalidateQueries(['getAllPayments', companyId])

    if (activePayment === 'pse') window.location.href = response.bank_url

    if (activePayment === 'automatic_debit') {
      if (response.payment_status === 'PENDING') {
        navigateToTransactionResponse(response)
      } else {
        showInfoMessage(response.message)
      }
      onCancel()
    }

    if (activePayment === 'credit_card') {
      if (response.payment_status === 'APPROVED' && subscriptionChange) {
        refreshCompany()
        queryClient.invalidateQueries(['getPlans', companyId])
        onCancel()
        openTransactionResponseModal(subscriptionTypeValidators)

        return
      }

      if (response.payment_status === 'PENDING')
        navigateToTransactionResponse(response)

      if (extraWorkers && response.paid_workers) {
        refreshCompany()
        onCancel(true)
        showInfoMessage(
          `La transacción fue exitosa. Ahora puedes tener ${
            response?.paid_workers
          } ${getWordSingular(
            'personas',
            response?.paid_workers <= 1
          )} más en tu nómina.`,
          {
            preventDuplicate: true,
          }
        )
      } else if (response.payment_status) {
        onCancel(true)
        navigateToTransactionResponse(response)
      } else {
        showInfoMessage(response.message)
        onCancel()
      }
    }

    if (isFromUpdateMethodInfo) {
      openUpdatePaymentMethodInfoModal('successful_update', activePayment)
    }
  }

  const callbackError = (error) => {
    onCancel()
    if (
      (error.errors?.[0].code.startsWith('24') ||
        error.errors?.[0].code === '1101') &&
      !extraWorkers
    ) {
      navigate(SUBSCRIPTION_TRANSACTION_RESPONSE('?status=DECLINED'), {
        state: {
          payment_status: 'DECLINED',
        },
      })
    } else {
      handleError(error, null, {
        notistackOptions: { preventDuplicate: true },
      })
    }
  }

  const createPayment = (activePayment, paymentData) => {
    paymentMethodMutation.mutate(
      {
        paymentMethod: activePayment,
        paymentMethodData: paymentData,
      },
      {
        onSuccess: (response) => callbackSuccess(response, activePayment),
        onError: (error) => callbackError(error),
      }
    )
  }

  const onSubmit = (values, activePayment, isCurrentMethodPayment) => {
    const paymentData = getPaymentData(
      values,
      activePayment,
      plan?.id,
      subscriptionType,
      paidWorkers,
      subscription?.partner,
      extraWorkers,
      subscriptionChange,
      newSubscriptionType,
      subscriptionTypeValidators?.currentPlanPayment,
      subscriptionTypeValidators?.isPremiumExpiredSubscription,
      subscription.payrolls_size,
      isEditingPaymentMethod,
      isCurrentMethodPayment
    )

    createPayment(activePayment, paymentData)
  }

  const updatePaymentMethod = () => handleForm('handleSubmit')
  const isLoading = paymentMethodMutation?.isLoading

  return {
    tabsData,
    onSubmit,
    updatePaymentMethod,
    formRef,
    isLoading,
  }
}

export default usePayment
