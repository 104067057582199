import { useTheme } from '@emotion/react'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useUser } from 'components/App/UserContext/useUser'
import PaymentModal from 'components/Subscription/Atoms/PaymentModal/PaymentModal'
import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Adornment from 'components/UI/Adornment'

import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import ExtraWorkersCounter from '../ExtraWorkersCounter'
import { getCardsData, getMainText } from './helpers'

const AdvancedSubscriptionConfirmationModal = ({
  planUpgrade,
  selectedModality,
  isAnUpgrade,
  modalityUpgrade,
  selectedPlan,
  activeUntil,
  currentWorkersNumber,
  isChangingModality,
  currentPlanPayment,
  isCurrentPlan,
  isPremiumExpiredSubscription,
}) => {
  const [totalPayment, setTotalPayment] = useState(null)
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const { refreshCompany } = useUser()
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const [extraWorkers, setExtraWorkers] = useState(0)
  const modals = useModals()
  const themeInstance = useTheme()
  const adornment = 'asterisk'

  const {
    type: subscriptionType,
    paid_workers: paidWorkers,
    payrolls_size: currentWorkers,
    month_value: monthValue,
    partner: isPartner,
  } = subscription || {}

  const isPaymentRequired =
    subscription?.additional_workers_info?.payment_required
  const getImmediatePayment =
    subscriptionType === 'year' && planUpgrade && !isPremiumExpiredSubscription
  const modality = selectedModality === 'year' ? 'yearly' : 'monthly'

  const queryKey = [
    getImmediatePayment
      ? 'getImmediatePaymentValue'
      : 'getNextCyclePaymentValue',
    getCompanyId(),
  ]

  const subscriptionTypeValidators = {
    isCurrentPlan,
    isChangingModality,
    currentPlanPayment,
    isPremiumExpiredSubscription,
  }

  const { subscriptionMutation } = useSubscriptionService({
    serviceParams: {
      queryKey,
      newPlanId: selectedPlan?.id,
      subscriptionType: selectedModality,
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        if (isPartner) {
          setTotalPayment(monthValue)
          return
        }

        setTotalPayment(
          getImmediatePayment
            ? data?.additional_workers_info?.extra_workers_value
            : data?.subscription_value?.[modality]?.total
        )
      },
    },
  })

  const buttonText = () => {
    if (isAnUpgrade) return 'Mejorar mi plan'
    if (currentPlanPayment) return 'Pagar mi plan'
    return 'Cambiar mi plan'
  }

  const mainText = getMainText(
    currentPlanPayment,
    modalityUpgrade,
    planUpgrade,
    subscription,
    isChangingModality,
    isPremiumExpiredSubscription,
    isPartner
  )

  const cardsData = getCardsData(
    totalPayment,
    activeUntil,
    themeInstance,
    currentWorkersNumber,
    planUpgrade,
    paidWorkers,
    selectedModality,
    isPremiumExpiredSubscription
  )

  const showPaymentModal =
    (getImmediatePayment && isPaymentRequired) ||
    currentPlanPayment ||
    isPremiumExpiredSubscription

  const handleContinueUpdateSubscription = () => {
    if (showPaymentModal) {
      modals.openModal({
        id: 'paymentModal',
        content: (
          <PaymentModal
            handleClose={() => modals.closeAll()}
            currentPlan={selectedPlan}
            extraWorkersEdit={extraWorkers || 0}
            handlePreviousModal={() => modals.closeModal('paymentModal')}
            subscriptionChange
            newSubscriptionType={selectedModality}
            subscriptionTypeValidators={subscriptionTypeValidators}
            currentPlanPayment={currentPlanPayment}
          />
        ),
        modalProps: {
          header: 'Método de pago',
          hideFooter: true,
          dialogProps: {
            fullWidth: true,
            maxWidth: 'sm',
          },
          paperSx: (theme) => ({
            maxWidth: '40rem',
            [theme.breakpoints.down('tablet')]: { padding: theme.spacing(1) },
          }),
        },
      })
    } else {
      subscriptionMutation.mutate(
        {
          mutationMethod: 'PUT',
          mutationKey: 'updateSubscription',
          newPlanId: selectedPlan?.id,
          subscriptionType: selectedModality,
        },
        {
          onSuccess: async () => {
            await refreshCompany()
            queryClient.invalidateQueries(['getPlans', getCompanyId()])
            modals.closeModal('advancedSubscriptionConfirmationModal')
            openTransactionResponseModal(subscriptionTypeValidators)
          },
          onError: () => {
            modals.closeModal('advancedSubscriptionConfirmationModal')
          },
        }
      )
    }
  }

  const showExtraWorkersCounter =
    (planUpgrade && !modalityUpgrade) ||
    currentPlanPayment ||
    isPremiumExpiredSubscription

  return (
    <>
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        {mainText}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: theme.spacing(2),
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        {cardsData?.map((data) => {
          const { id, label, value, adornmentColor, baseColor, footnote } = data
          return (
            <Box
              key={id}
              sx={(theme) => ({
                position: 'relative',
                borderRadius: '0.5rem',
                padding: theme.spacing(1.5, 2, 2),
                overflow: 'hidden',
                boxShadow: theme.shadows[3],
                height: '8.5rem',
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  marginBottom: theme.spacing(1),
                  alignItems: 'center',
                  columnGap: theme.spacing(0.5),
                })}
              >
                <Typography variant="h5" sx={{ zIndex: 1 }}>
                  {label}
                </Typography>
              </Box>
              {value || typeof value === 'number' ? (
                <Typography
                  variant="h2"
                  sx={(theme) => ({
                    marginBottom: theme.spacing(1),
                  })}
                >
                  {value}
                </Typography>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={40} color="primary" />
                </Box>
              )}
              <Adornment
                variant={adornment}
                height="5rem"
                width="6rem"
                color={adornmentColor}
                sx={{
                  top: '-1.5rem',
                  right: '-1rem',
                }}
              />
              {footnote && value ? (
                <Typography color="black.light" variant="body2">
                  {footnote}
                </Typography>
              ) : null}
              <Divider
                absolute
                sx={{
                  borderBottomWidth: 5,
                  borderColor: baseColor,
                }}
              />
            </Box>
          )
        })}
      </Box>
      {!isPartner && showExtraWorkersCounter ? (
        <Box>
          <Typography
            variant="h3"
            sx={(theme) => ({ margin: theme.spacing(3, 0, 2, 0) })}
          >
            ¿Deseas agregar más personas?
          </Typography>
          <ExtraWorkersCounter
            extraWorkers={extraWorkers}
            setExtraWorkers={setExtraWorkers}
            setTotalPayment={setTotalPayment}
            selectedPlan={selectedPlan}
            selectedModality={selectedModality}
            modalityUpgrade={modalityUpgrade}
            getImmediatePayment={getImmediatePayment}
            paymentModality={modality}
            currentWorkers={currentWorkers}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          size="large"
          onClick={() => handleContinueUpdateSubscription()}
          sx={(theme) => ({ padding: theme.spacing(0, 5) })}
        >
          {buttonText()}
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={() => modals.closeAll()}
          sx={(theme) => ({ padding: theme.spacing(0, 9) })}
        >
          Cancelar
        </Button>
      </Box>
    </>
  )
}

export default AdvancedSubscriptionConfirmationModal
