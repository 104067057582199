import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Paper,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import LordIcon from 'components/UI/LordIcon'
import Page from 'components/UI/Page/Page'

import Sally from 'assets/images/views/holisticPayroll/sally.svg'

import { CoachigSectionCardsInfo } from './helpers'

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Box
      className="calendly-inline-widget"
      data-url="https://calendly.com/lu-aleluya/30min"
      sx={(theme) => ({
        minWidth: '20rem',
        height: '43.75rem',
        marginY: theme.spacing(-5),
      })}
    />
  )
}

const FinancialCoaching = () => {
  const [step, setStep] = useState(1)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  return (
    <Page
      documentTitle="Coaching financiero"
      header={
        <Box sx={{ gridColumn: '1 / -1' }}>
          <Typography
            variant="h1"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            Coaching financiero
          </Typography>
          <Typography>
            Aquí podrás solicitar una cita con un experto, para que juntos
            puedan recalcular tu ruta financiera.
          </Typography>
        </Box>
      }
      rootSx={(theme) => ({
        backgroundColor:
          step === 1 ? alpha(theme.palette.accent2.light, 0.6) : null,
      })}
      grid
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        {step === 1 ? (
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateRows: 'repeat(3, auto)',
              gap: theme.spacing(6),
            })}
          >
            <Paper
              elevation={2}
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(3),
                borderRadius: '1rem',
                backgroundColor: 'complementary2.light',
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h1">
                  Un experto para acompañarte a mejorar tu salud financiera
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    marginTop: theme.spacing(3),
                  })}
                >
                  <Button onClick={() => setStep(2)} size="large">
                    Agendar mi cita
                  </Button>
                </Box>
              </Box>
              {!isMobile ? (
                <Box
                  component="img"
                  alt="Relájate mientras tienes Aleluya Premium"
                  src={Sally}
                  width={417}
                  height={355}
                  sx={(theme) => ({
                    zIndex: 1,
                    objectFit: 'contain',
                    margin: theme.spacing(-11, -2, -11, -5),
                  })}
                />
              ) : null}
            </Paper>

            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: theme.spacing(6),
              })}
            >
              <Typography variant="h2">
                Tu bienestar financiero en 3 pasos
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: theme.spacing(4),
                  width: '100%',
                  [theme.breakpoints.up('md')]: {
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    columnGap: theme.spacing(4),
                    rowGap: 0,
                  },
                })}
              >
                {CoachigSectionCardsInfo.map((info) => (
                  <Paper
                    key={info.id}
                    className={info.id}
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: theme.spacing(3),
                      borderRadius: '1rem',
                      position: 'relative',
                    })}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: info.numberBackgroundColor,
                        width: '3.5rem',
                        height: '3.5rem',
                        borderRadius: '100%',
                        top: '-10%',
                        left: '8%',
                      }}
                    >
                      <Typography color="white.main" variant="h3">
                        {info.number}
                      </Typography>
                    </Box>
                    <LordIcon name={info.icon} target={info.id} />
                    <Typography
                      textAlign="center"
                      variant="h3"
                      sx={(theme) => ({ marginTop: theme.spacing(2) })}
                    >
                      {info.title}
                    </Typography>
                  </Paper>
                ))}
              </Box>
              <Typography
                sx={(theme) => ({
                  fontSize: '1.4rem',
                  textAlign: 'center',
                  lineHeight: '1.75rem',
                  width: '90%',
                  [theme.breakpoints.up('md')]: {
                    width: '80%',
                  },
                })}
              >
                Podrás continuar hablando con tu Coach cada mes, para que juntos
                puedan revisar los avances y recalcular tu plan.
              </Typography>
            </Box>

            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                rowGap: theme.spacing(6.5),
              })}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                }}
              >
                Mejora tu <b>flujo de caja</b> y diseña la
                <br />
                <b>tranquilidad financiera</b> que siempre has soñado.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setStep(2)}
                  endIcon={<Icon name="arrow-right" />}
                  size="large"
                >
                  Agendar mi cita
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>

      {step === 2 ? (
        <Box
          sx={(theme) => ({
            gridColumn: '1 / -1',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
            [theme.breakpoints.up('tablet')]: {
              marginTop: theme.spacing(-5),
            },
          })}
        >
          <CalendlyWidget />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              startIcon={
                <Icon sx={{ transform: 'rotate(180deg)' }} name="arrow-right" />
              }
              onClick={() => setStep(1)}
              size="large"
            >
              Volver a la vista principal
            </Button>
          </Box>
        </Box>
      ) : null}
    </Page>
  )
}

export default FinancialCoaching
