import { Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import { getCompanyId } from 'utils/company'
import useExogenaReportService from 'utils/hooks/periodHistory/exogenaReport'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

const ExogenaReportModal = ({ openModal, handleClose }) => {
  const lastYear = new Date().getFullYear() - 1
  const { exogenaReportMutation } = useExogenaReportService()
  const downloadFile = useEnqueueDownload()

  const handleSubmit = () => {
    exogenaReportMutation.mutate(
      {
        mutationMethod: 'GET',
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'reporte para exógena',
            fileCode: 'exogena_report',
            pathname: `companies/${getCompanyId()}/exogenous_information_file`,
          })
          handleClose()
        },
      }
    )
  }

  return (
    <Modal
      okText="Generar archivo"
      header="Reporte Anual para reportar información de Exógena"
      loadingText="Generando archivo..."
      open={openModal}
      onOk={handleSubmit}
      onCancel={handleClose}
      isLoading={exogenaReportMutation.isLoading}
      adornment="spring"
    >
      <Typography color="black.dark">
        Con esta opción puedes descargar un reporte consolidado con la
        información que necesitas para construir el reporte de medios
        magneticos: Exógena.
      </Typography>
      <Typography
        color="black.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(0.5),
        })}
      >
        <b>Recuerda:</b> Se va generar el archivo correspondiente al año{' '}
        <b>{lastYear}</b>
      </Typography>
    </Modal>
  )
}

export default ExogenaReportModal
