import { useNavigate } from 'react-router-dom'

import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Button from 'components/UI/Button/Button'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import { cryEmoji } from 'utils/emojis'

import HelloWoman from 'assets/images/views/common/hello_woman_left.svg'

import { SUBSCRIPTION } from 'config/routes'

import { getSubscriptionAlertCopies } from './helpers'

const SubscriptionAlertModal = ({ state, handleClose }) => {
  const { openPremiumFeatureModal } = usePremiumFeature()
  const navigate = useNavigate()
  const { open, subscriptionStatus } = state

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const handleCloseModal = () => {
    localStorage.setItem('one_by_session_info_modal', 'modalViewed')
    handleClose()
  }

  const { title, buttonLabel } = getSubscriptionAlertCopies(subscriptionStatus)

  const handleClick = () => {
    if (subscriptionStatus === 'premium_expired') {
      navigate(SUBSCRIPTION())
    } else {
      openPremiumFeatureModal()
    }

    handleCloseModal()
  }

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      hideFooter
      patternBackgroundColor="complementary2.main"
      paperSx={{
        maxWidth: '52rem',
        position: 'relative',
      }}
      contentWrapperSx={{
        overflow: 'hidden',
        minHeight: '22.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
        }}
      >
        {!isMobile ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent1.main,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <Box
              component="img"
              alt="Relájate mientras tienes Aleluya Premium"
              src={HelloWoman}
              width={200}
              height={280}
              sx={{
                zIndex: 1,
                objectFit: 'contain',
              }}
            />
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('tablet')]: {
              paddingLeft: theme.spacing(3),
            },
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h2"
                sx={(theme) => ({ margin: theme.spacing(0, 1.5, 2.5) })}
              >
                {title}
              </Typography>
              <ButtonBase
                onClick={handleCloseModal}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="close" sx={{ fontSize: '1.7rem' }} />
              </ButtonBase>
            </Box>
            <Typography
              variant="body1"
              color="black.dark"
              sx={(theme) => ({
                flexDirection: 'column',
                display: 'flex',
                gap: theme.spacing(2),
                marginBottom: theme.spacing(1),
              })}
            >
              {subscriptionStatus !== 'premium_due' ? (
                <span>
                  No puede ser! Ya no tienes acceso a los superpoderes de tu
                  cuenta. <Emoji code={cryEmoji} />
                </span>
              ) : null}
              Pero si estás feliz con Aleluya, es un gran momento para que
              renueves tu suscripción y sigas estando tranqui!
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <Button onClick={handleClick}>{buttonLabel}</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SubscriptionAlertModal
