import { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Box, Button, Slide, Typography } from '@mui/material'

import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { validPeriodsHistoryRoute } from 'utils/regex'

import { PERIOD_PAY_PAYROLL, PERIOD_PAY_PREVIOUS_PAYROLL } from 'config/routes'

import { BulkNoveltiesAlertContext } from './BulkNoveltiesAlertWrapper'

const messageType = {
  error: {
    description: {
      label:
        'Hay un problema con en el archivo de carga masiva de novedades que intentaste subir. No pudimos cargar las novedades. ¡Relax! Haz clic en ver detalle del error y te contamos qué pasó.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver detalle del error',
      backgroundColor: 'accent3.light',
    },
  },
  success_without_errors: {
    description: {
      label:
        'El archivo de carga masiva de novedades se subió exitosamente. Haz clic en ver resumen de novedades para conocer más detalles.',
      backgroundColor: 'accent2.light',
    },
    button: {
      label: 'Ver resumen de novedades',
      backgroundColor: 'accent1.main',
    },
  },
  success_with_errors: {
    description: {
      label:
        'El archivo de carga masiva de novedades se subió exitosamente. Sin embargo, algunas novedades no se cargaron por errores en el archivo. Haz clic en ver resumen de novedades para conocer más detalles.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver resumen de novedades',
      backgroundColor: 'accent3.light',
    },
  },
  success_with_error: {
    description: {
      label:
        'El archivo de carga masiva de novedades se subió exitosamente. Sin embargo, una de las novedades no se cargó por un error en el archivo. Haz clic en ver resumen de novedades para conocer más detalles.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver resumen de novedades',
      backgroundColor: 'accent3.light',
    },
  },
  not_success_cases_single_error: {
    description: {
      label:
        'Hay un error en el archivo que subiste y la novedad no pudo ser cargada. ¡Relax! Haz clic en ver detalle del error y te contamos qué pasó.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver detalle del error',
      backgroundColor: 'accent3.light',
    },
  },
  not_success_cases: {
    description: {
      label:
        'Hay errores en el archivo que subiste y las novedades no pudieron ser cargadas. ¡Relax! Haz clic en ver detalle de errores y te contamos qué pasó.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver detalle de errores',
      backgroundColor: 'accent3.light',
    },
  },
}

const BulkNoveltiesAlert = ({ state, handleClose }) => {
  const { openBulkNoveltiesSummaryModal, openBulkNoveltiesCommonInfoModal } =
    useContext(BulkNoveltiesAlertContext)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { '*': currentRoute } = useParams()

  const { showErrorModal, messageResponse, showSummaryModal, websocketResult } =
    state

  const {
    current_period: isCurrentPeriodBulkUpload,
    period_id: bulkUploadPeriodId,
  } = websocketResult?.success || {}

  const { error_type: errorType } = websocketResult?.errors || {}
  const isPayPayrollRoute = pathname === '/pay_payroll'
  const isValidPeriodsHistoryRoute = validPeriodsHistoryRoute.test(currentRoute)

  const isBulkUploadPeriodsHistoryRoute =
    currentRoute === `periods_history/${bulkUploadPeriodId}`

  const isSuccessfulBulkUpload = Boolean(bulkUploadPeriodId)

  const handleOnClick = () => {
    if (
      (!isPayPayrollRoute &&
        isCurrentPeriodBulkUpload &&
        isSuccessfulBulkUpload &&
        !isBulkUploadPeriodsHistoryRoute) ||
      (!isSuccessfulBulkUpload &&
        !isPayPayrollRoute &&
        !isValidPeriodsHistoryRoute)
    ) {
      navigate(PERIOD_PAY_PAYROLL())
    }

    if (
      isSuccessfulBulkUpload &&
      !isCurrentPeriodBulkUpload &&
      !isBulkUploadPeriodsHistoryRoute
    ) {
      navigate(PERIOD_PAY_PREVIOUS_PAYROLL(bulkUploadPeriodId))
    }

    if (showSummaryModal) openBulkNoveltiesSummaryModal(websocketResult)
    if (showErrorModal) openBulkNoveltiesCommonInfoModal(errorType)
    handleClose()
  }

  return (
    <Slide direction="down" timeout={500} in>
      <Box>
        <Alert
          icon={false}
          fullWidth
          sx={(theme) => ({
            padding: 0,
            boxShadow: theme.shadows[4],
            backgroundColor: 'transparent',
            '.MuiAlert-message': {
              padding: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '73% auto',
              minHeight: '4rem',
              [theme.breakpoints.down('tablet')]: {
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr, 1fr',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(1, 2),
                backgroundColor:
                  messageType[messageResponse].description.backgroundColor,
              })}
            >
              <Typography variant="h5" color="black.main">
                {messageType[messageResponse].description.label}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor:
                  messageType[messageResponse].button.backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="outlined"
                onClick={handleOnClick}
                sx={(theme) => ({
                  maxWidth: '15.625rem',
                  padding: theme.spacing(0, 1),
                  [theme.breakpoints.down('tablet')]: {
                    margin: theme.spacing(2, 0),
                  },
                })}
              >
                {messageType[messageResponse].button.label}
              </Button>
            </Box>
          </Box>
        </Alert>
      </Box>
    </Slide>
  )
}

export default BulkNoveltiesAlert
