import { Box, Link, Typography } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import useWorkHoursConfigurationService from 'utils/hooks/company/workHoursConfigurationService.js'

import SectionCard from '../SectionCard'
import {
  LINK_REF,
  WORK_HOURS_INFO,
  columnData,
  getTableActions,
  useTableActions,
} from './helpers'

const WorkHoursConfiguration = () => {
  const companyId = getCompanyId()

  const workHoursConfigurationQueryKey = [
    'getWorkHoursConfiguration',
    companyId,
  ]
  const { workHoursConfigurationQuery } = useWorkHoursConfigurationService({
    serviceParams: {
      queryKey: workHoursConfigurationQueryKey,
    },
  })

  const {
    handleCreateWorkHoursConfiguration,
    handleEditWorkHoursConfiguration,
    handleDeleteWorkHoursConfiguration,
  } = useTableActions()

  const tableActions = getTableActions(
    handleCreateWorkHoursConfiguration,
    handleEditWorkHoursConfiguration,
    handleDeleteWorkHoursConfiguration
  )

  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(6) })}>
      <SectionCard subtitle="Ajuste de fechas en cambio de jornada laboral">
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Ajusta la jornada laboral de tu empresa de acuerdo con los plazos
          estipulados por la{' '}
          <Link
            href={LINK_REF}
            target="_blank"
            fontWeight={600}
            color="accent4.main"
          >
            Ley 2101 de julio 15 de 2021
          </Link>
          . Para obtener más información sobre cómo esta norma afecta tu nómina,
          haz clic{' '}
          <Link
            href={WORK_HOURS_INFO}
            target="_blank"
            fontWeight={600}
            color="accent4.main"
          >
            aquí
          </Link>
          . Recuerda que este cambio puede modificar nóminas anteriores
          dependiendo del rango de fechas que ajustes, así que valida
          cuidadosamente tu ajuste antes de guardarlo.
        </Typography>

        <Table
          data={workHoursConfigurationQuery.data || []}
          columns={columnData}
          actions={tableActions}
          options={{
            alignActionsCell: 'center',
            pagination: false,
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
        />
      </SectionCard>
    </Box>
  )
}

export default WorkHoursConfiguration
