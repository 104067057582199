import { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'

import { getCompanyId } from 'utils/company'
import useSocialBenefitsService from 'utils/hooks/payroll/socialBenefits'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { formatSocialBenefits } from 'services/payroll/socialBenefitsService'

import {
  SocialBenefitsPeriodAPIContext,
  SocialBenefitsPeriodContext,
  getMessage,
  getMessagesAlerts,
  socialBenefitAction,
} from './helpers'

const WrapperContent = ({ children }) => {
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState(false)

  const { showInfoMessage, showSuccessMessage } = useNotifications()
  const { periodId, socialBenefitPeriodId } = useParams()
  const { handleError } = useErrorHandler()
  const { listenInBackground } = useUser()
  const downloadFile = useEnqueueDownload()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const navigate = useNavigate()
  const confirm = useConfirm()
  const { socialBenefitsMutation } = useSocialBenefitsService({
    queryOptions: { enabled: false },
  })

  const socialBenefitsQueryKey = useMemo(
    () => ['getSocialBenefits', companyId, periodId, socialBenefitPeriodId],
    [companyId, periodId, socialBenefitPeriodId]
  )
  const socialBenefitPeriodQueryKey = useMemo(
    () => ['getSocialBenefitsPeriod', periodId, companyId],
    [companyId, periodId]
  )
  const socialBenefitsDetailsQueryCache = queryClient.getQueryData(
    socialBenefitPeriodQueryKey
  )

  const formattedSocialBenefits = useMemo(
    () => formatSocialBenefits(socialBenefitsDetailsQueryCache.data || {}),
    [socialBenefitsDetailsQueryCache.data]
  )

  const socialBenefitCategory =
    formattedSocialBenefits?.social_benefit_period.category

  const socialBenefitsPeriodContextValue = useMemo(
    () => ({ formattedSocialBenefits }),
    [formattedSocialBenefits]
  )

  const backgroundSocialBenefitsSocketHandler = useCallback(
    async (websocketResult, connectionRef, label, category, action) => {
      if (websocketResult) {
        if (websocketResult.errors) {
          handleError(websocketResult.errors)
          connectionRef.off('value')
        }

        if (websocketResult.complement) {
          connectionRef.off('value')

          if (action !== 'recalculate') {
            await queryClient.invalidateQueries(socialBenefitPeriodQueryKey)
          }
          await queryClient.invalidateQueries(socialBenefitsQueryKey)

          showSuccessMessage(getMessagesAlerts(label, category, action).success)
        }
      }
    },
    [
      handleError,
      queryClient,
      showSuccessMessage,
      socialBenefitPeriodQueryKey,
      socialBenefitsQueryKey,
    ]
  )

  const handleDownloadFile = useCallback(() => {
    setIsLoadingDownloadFile(true)
    socialBenefitsMutation.mutate(
      {
        mutationMethod: 'GET',
        periodId,
        socialBenefitPeriodId,
      },
      {
        onSuccess: () => {
          setIsLoadingDownloadFile(false)
          downloadFile({
            name: `Resumen de ${getMessage(socialBenefitCategory)}`,
            fileCode: 'social_benefits_file',
            pathname: `periods/${periodId}/social_benefits_file`,
          })
        },
        onError: (error) => {
          setIsLoadingDownloadFile(true)
          handleError(error)
        },
      }
    )
  }, [
    downloadFile,
    handleError,
    socialBenefitCategory,
    periodId,
    socialBenefitPeriodId,
    socialBenefitsMutation,
  ])

  const handleSaveAllSocialBenefits = useCallback(
    (label, category) => {
      confirm({
        title: `Estás a punto de Liquidar ${getMessage(
          category
        )} de todas las personas.`,
        description: ` Al realizar esta liquidación se incluirá el valor de ${getMessage(
          category
        )} ${
          category === 'severance'
            ? 'para el archivo de pago en el fondo de cesantías.'
            : 'en la colillas de pago y en el archivo de pago en bancos.'
        }`,
        okText: `Liquidar ${socialBenefitAction[category]}`,
        onOk: async () => {
          showInfoMessage(
            getMessagesAlerts(label, category, 'paidBenefits').inprogress
          )

          await socialBenefitsMutation.mutateAsync(
            {
              mutationMethod: 'POST',
              mutationKey: 'createSocialBenefitsWorker',
              periodId,
              socialBenefitPeriodId,
              socialBenefits: {
                bulk_create: true,
              },
            },
            {
              onSuccess: () => {
                listenInBackground(
                  `companies/${companyId}/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/action_bulk_create`,
                  (websocketResult, connectionRef) =>
                    backgroundSocialBenefitsSocketHandler(
                      websocketResult,
                      connectionRef,
                      label,
                      category,
                      'paidBenefits'
                    )
                )
                navigate(-1)
              },
            }
          )
        },
        adornment: {
          variant: 'signature',
          color: 'accent2.light',
          width: 155,
          height: 171,
          sx: { bottom: '0.94rem', right: '0.94rem', zIndex: 1 },
        },
      })
    },
    [
      backgroundSocialBenefitsSocketHandler,
      companyId,
      confirm,
      listenInBackground,
      navigate,
      periodId,
      showInfoMessage,
      socialBenefitPeriodId,
      socialBenefitsMutation,
    ]
  )

  const handleDeleteAllSocialBenefits = useCallback(
    (label, category) => {
      confirm({
        title: `Eliminar el pago de ${getMessage(category)}`,
        description: `¿Estás seguro de eliminar el pago de ${getMessage(
          category
        )} de este periodo? Luego podrás volver a liquidarlo si quieres.`,
        okText: 'Eliminar pago',
        onOk: async () => {
          showInfoMessage(
            getMessagesAlerts(label, category, 'deleteBenefits').inprogress
          )

          await socialBenefitsMutation.mutateAsync(
            {
              mutationMethod: 'DELETE',
              periodId,
              socialBenefitPeriodId,
            },
            {
              onSuccess: () => {
                listenInBackground(
                  `companies/${companyId}/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/action_bulk_destroy`,
                  (websocketResult, connectionRef) =>
                    backgroundSocialBenefitsSocketHandler(
                      websocketResult,
                      connectionRef,
                      label,
                      category,
                      'deleteBenefits'
                    )
                )
                navigate(-1)
              },
            }
          )
        },
      })
    },
    [
      backgroundSocialBenefitsSocketHandler,
      companyId,
      confirm,
      listenInBackground,
      navigate,
      periodId,
      showInfoMessage,
      socialBenefitPeriodId,
      socialBenefitsMutation,
    ]
  )

  const handleRecalculateAllSocialBenefits = useCallback(
    (label, category) => {
      confirm({
        title: `Recalcular el pago de ${getMessage(category)}`,
        description: `¿Estás seguro que deseas recalcular el pago de ${getMessage(
          category
        )} de este periodo?`,
        okText: 'Recalcular pago',
        onOk: async () => {
          showInfoMessage(
            `Está en proceso la Reinicialización de valores en ${getMessage(
              category
            )} de todas las personas.`
          )

          await socialBenefitsMutation.mutateAsync(
            {
              mutationMethod: 'PUT',
              mutationKey: 'recalculateAllSocialBenefits',
              periodId,
              socialBenefitPeriodId,
              socialBenefits: {
                bulk_update: true,
              },
            },
            {
              onSuccess: () => {
                listenInBackground(
                  `companies/${companyId}/periods/${periodId}/social_benefit_periods/${socialBenefitPeriodId}/social_benefits/action_bulk_update`,
                  (websocketResult, connectionRef) =>
                    backgroundSocialBenefitsSocketHandler(
                      websocketResult,
                      connectionRef,
                      label,
                      category,
                      'recalculate'
                    )
                )
                navigate(-1)
              },
              onError: (error) => {
                handleError(error)
              },
            }
          )
        },
      })
    },
    [
      backgroundSocialBenefitsSocketHandler,
      companyId,
      confirm,
      handleError,
      listenInBackground,
      navigate,
      periodId,
      showInfoMessage,
      socialBenefitPeriodId,
      socialBenefitsMutation,
    ]
  )

  const socialBenefitsPeriodAPIContextValue = useMemo(
    () => ({
      handleDownloadFile,
      handleSaveAllSocialBenefits,
      handleDeleteAllSocialBenefits,
      handleRecalculateAllSocialBenefits,
      isLoadingDownloadFile,
    }),
    [
      handleDownloadFile,
      handleSaveAllSocialBenefits,
      handleDeleteAllSocialBenefits,
      handleRecalculateAllSocialBenefits,
      isLoadingDownloadFile,
    ]
  )

  return (
    <SocialBenefitsPeriodContext.Provider
      value={socialBenefitsPeriodContextValue}
    >
      <SocialBenefitsPeriodAPIContext.Provider
        value={socialBenefitsPeriodAPIContextValue}
      >
        {children}
      </SocialBenefitsPeriodAPIContext.Provider>
    </SocialBenefitsPeriodContext.Provider>
  )
}

export default WrapperContent
