import { useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import Loading from 'components/UI/Loading/Loading'

import LoadingModal from '../Loading/LoadingModal'

const Page = ({
  header,
  documentTitle,
  isLoading,
  isLoadingWithModal,
  contentSx,
  children,
  grid,
  rootSx,
}) => {
  const isHeaderString = typeof header === 'string'

  useEffect(() => {
    if (documentTitle) document.title = `${documentTitle} - Aleluya!`
    else if (isHeaderString) document.title = `${header} - Aleluya!`
    else document.title = 'Aleluya!'
  }, [documentTitle, header, isHeaderString])

  return (
    <Box
      component="section"
      sx={[
        (theme) => ({
          flexGrow: 1,
          overflowY: 'auto',
          ':after': {
            // workarround to fix problem related to overflow with padding
            content: '""',
            display: 'block',
            height: '2rem',
          },
          ...(grid && {
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            columnGap: theme.spacing(3),
            padding: theme.spacing(4, 2, 0, 2),
            '::after': {
              gridColumn: '1 / -1',
            },
            [theme.breakpoints.up('tablet')]: {
              padding: theme.spacing(4, 4, 0, 4),
            },
          }),
          ...(!header && {
            paddingTop: '0 !important',
          }),
        }),
        rootSx,
      ]}
    >
      {header ? (
        <Box
          sx={[
            (theme) => ({
              ...(!grid && {
                marginBottom: theme.spacing(4),
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                marginTop: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                  marginLeft: theme.spacing(6),
                  marginRight: theme.spacing(6),
                  marginTop: theme.spacing(4),
                },
              }),
              ...(grid && {
                display: 'grid',
                gridColumn: '1 / -1',
                alignItems: 'center',
                columnGap: theme.spacing(3),
                gridTemplateColumns: 'repeat(4, 1fr)',
                [theme.breakpoints.up('tablet')]: {
                  gridTemplateColumns: 'repeat(6, 1fr)',
                  columnGap: theme.spacing(4),
                },
                [theme.breakpoints.up('laptop')]: {
                  gridTemplateColumns: 'repeat(12, 1fr)',
                },
              }),
            }),
          ]}
        >
          {isHeaderString ? (
            <Typography variant="h2" sx={{ gridColumn: '1 / -1' }}>
              {header}
            </Typography>
          ) : (
            header
          )}
        </Box>
      ) : null}
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={[
            (theme) => ({
              ...(!grid && {
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                  marginLeft: theme.spacing(6),
                  marginRight: theme.spacing(6),
                  marginBottom: theme.spacing(4),
                },
              }),
              ...(grid && {
                display: 'grid',
                gridColumn: '1 / -1',
                columnGap: theme.spacing(3),
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridAutoRows: 'max-content',
                marginTop: theme.spacing(7),
                [theme.breakpoints.up('tablet')]: {
                  gridTemplateColumns: 'repeat(6, 1fr)',
                  columnGap: theme.spacing(4),
                },
                [theme.breakpoints.up('laptop')]: {
                  gridTemplateColumns: 'repeat(12, 1fr)',
                },
              }),
            }),
            contentSx,
          ]}
        >
          {children}
        </Box>
      )}
      {isLoadingWithModal ? <LoadingModal /> : null}
    </Box>
  )
}

export default Page
