import { createCalendar, parseDate } from '@internationalized/date'
import { useCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useCalendarState } from '@react-stately/calendar'
import { useRef, useState } from 'react'

import { Box } from '@mui/material'

import { useDatePickerContext } from '.'
import CalendarActions from './CalendarActions'
import CalendarGrid from './CalendarGrid'
import CalendarHeader from './CalendarHeader'
import CalendarYearsGrid from './CalendarYearsGrid'
import { useCalendarYears } from './helpers'

const Calendar = ({
  onCloseCalendar,
  minValue,
  maxValue,
  focusedValue,
  ...props
}) => {
  const { locale } = useLocale()
  const { isDesktop } = useDatePickerContext()
  const currentFocusedValue = focusedValue ? parseDate(focusedValue) : undefined
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    minValue,
    maxValue,
    defaultFocusedValue: currentFocusedValue,
  })
  const ref = useRef()
  const {
    calendarProps,
    prevButtonProps,
    nextButtonProps,
    title,
  } = useCalendar(
    { ...props, minValue, maxValue, defaultFocusedValue: currentFocusedValue },
    state,
    ref
  )
  const [gridTransition, setGridTransition] = useState({
    key: 0,
    direction: null,
  })
  const [showYearGrid, setShowYearGrid] = useState(false)
  const { years, yearsRefs, hasAvailableYears } = useCalendarYears({
    state,
    minValue,
    maxValue,
  })

  const onCloseYearGrid = () => {
    setShowYearGrid(false)
  }

  return (
    <Box
      {...calendarProps}
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2, 3),
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
        })}
      >
        <CalendarHeader
          title={title}
          showYearGrid={showYearGrid}
          setShowYearGrid={setShowYearGrid}
          hasAvailableYears={hasAvailableYears}
          prevButtonProps={{
            ...prevButtonProps,
            onPress: (event) => {
              prevButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key - 1,
                direction: 'left',
              }))
            },
          }}
          nextButtonProps={{
            ...nextButtonProps,
            onPress: (event) => {
              nextButtonProps.onPress(event)
              setGridTransition((previousValue) => ({
                key: previousValue.key + 1,
                direction: 'right',
              }))
            },
          }}
        />
        <CalendarYearsGrid
          open={showYearGrid}
          state={state}
          onCloseYearGrid={onCloseYearGrid}
          years={years}
          yearsRefs={yearsRefs}
        />
        <CalendarGrid state={state} gridTransition={gridTransition} />
        {!isDesktop ? (
          <CalendarActions onCloseCalendar={onCloseCalendar} />
        ) : null}
      </Box>
    </Box>
  )
}

export default Calendar
