import DocumentSignatureFields from './DocumentSignature'
import EditPeriodHistory from './EditPeriodHistory'
import PaymentConfiguration from './PaymentConfiguration'
import UVTConfiguration from './UVTConfiguration'
import WorkHoursConfiguration from './WorkHoursConfiguration'

const AdvancedSetttingsFormFields = ({ options = {} }) => {
  return (
    <>
      <PaymentConfiguration options={options} />
      <EditPeriodHistory options={options.edit_periods} />
      <WorkHoursConfiguration />
      <UVTConfiguration options={options.uvt_configuration} />
      <DocumentSignatureFields options={options} />
    </>
  )
}

export default AdvancedSetttingsFormFields
