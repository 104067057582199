import { subYears } from 'date-fns'
import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import Emoji from 'components/UI/Emoji'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import { isAlegraClaroDist } from 'utils/auth'
import { formatDate } from 'utils/dateTime'
import { chronometer } from 'utils/emojis'

import * as routes from 'config/routes'

import messages from 'messages/worker'

const BasicDataSection = () => {
  const { isWorker } = useUser()
  const { values } = useFormikContext()
  const maxDateBirthDay = formatDate(subYears(new Date(), 15))
  const { showPremiumFeatures, showHRFeatures } = usePremiumFeature()

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        position: 'relative',
      })}
    >
      {isWorker ? (
        <Typography color="black.dark">
          Acá podrás editar tu número celular y dirección. Para modificar tu
          correo y contraseña, ve a{' '}
          <Link
            to={routes.USER_PROFILE()}
            fontWeight={600}
            color="accent4.main"
          >
            editar mi cuenta.
          </Link>
        </Typography>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(3),
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <FormField
          name="name"
          label="Nombre"
          optional={false}
          disabled={isWorker}
        />
        <FormField
          name="last_name"
          label="Apellidos"
          optional={false}
          disabled={isWorker}
        />
        <FormField
          name="email"
          type="email"
          label="Correo electrónico"
          optional={!values?.active_user}
          disabled={isWorker}
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
        <FormField name="phone" label="Número celular" optional={false} />
      </Box>
      <FormField name="address" label="Dirección Hogar" optional />
      {!isWorker ? (
        <>
          <DocumentTypeField optional={false} />
          <IdentificationNumberField optional={false} />
          <FormField
            name="birthday"
            label="Fecha de nacimiento"
            variant="datepicker"
            maxDate={maxDateBirthDay}
          />
        </>
      ) : null}
      {!isAlegraClaroDist() && !isWorker ? (
        <FormField
          name="active_user"
          label="¿Permitir que esta Persona tenga acceso web?"
          variant="radio-group"
          tooltipContent={
            <Typography>
              {messages.allow_web_access({
                showPremiumFeatures,
                showHRFeatures,
              })}
              {showPremiumFeatures || showHRFeatures ? (
                <Emoji code={chronometer} />
              ) : null}
            </Typography>
          }
          data-cy="active_user_radio_group"
          row
          options={[
            {
              value: true,
              label: 'Sí',
            },
            { value: false, label: 'No' },
          ]}
        />
      ) : null}
    </Box>
  )
}

export default BasicDataSection
