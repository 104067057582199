import { Box } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { isArusDist, isFreeCompany } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import useDashboardService from 'utils/hooks/dashboardService'

import NextPaymentCard from './NextPaymentCard'
import { getNextPaymentType } from './helpers'

const DashboardNextPayments = () => {
  const { subscription } = useSubscription()
  const isArusDistributor = isArusDist()
  const queryKey = [
    'dashboardByMetrics',
    'next_payments,period_information',
    getCompanyId(),
  ]

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const { dashboardQuery } = useDashboardService({
    serviceParams: {
      queryKey,
      metrics: 'next_payments,period_information',
    },
  })

  if (dashboardQuery.isLoading) {
    return (
      <Box
        sx={(theme) => ({
          gridColumn: '1 / -1',
          [theme.breakpoints.up('laptop')]: {
            gridColumn: '1 / 10',
          },
        })}
      >
        <LoadingBox />
      </Box>
    )
  }

  const {
    next_payments: nextPayments = {},
    period_information: periodInformation = {},
  } = dashboardQuery.data || {}
  const nextPaymentType = getNextPaymentType(periodInformation, nextPayments)

  return (
    <Box
      sx={(theme) => ({
        gridColumn: '1 / -1',
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / 10',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          columnGap: theme.spacing(3.5),
          rowGap: theme.spacing(2),
          gridTemplateColumns: '1fr',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        })}
      >
        <>
          {nextPayments &&
            Object.keys(nextPayments).map((paymentItem) => (
              <NextPaymentCard
                key={paymentItem}
                title={nextPaymentType[paymentItem]?.title}
                value={formatCurrency(
                  typeof nextPayments[paymentItem]?.payment === 'number'
                    ? nextPayments[paymentItem]?.payment
                    : nextPayments[paymentItem]
                )}
                backgroundColor={nextPaymentType[paymentItem]?.backgroundColor}
                isWhiteText={
                  isArusDistributor &&
                  paymentItem === 'payroll_payments' &&
                  !isAFreeCompany
                }
                icon={nextPaymentType[paymentItem]?.icon}
                tooltipTitle={nextPaymentType[paymentItem]?.tooltipTitle}
                tooltipDescription={
                  nextPaymentType[paymentItem]?.tooltipDescription
                }
                descriptionDate={nextPaymentType[paymentItem]?.descriptionDate}
              />
            ))}
        </>
      </Box>
    </Box>
  )
}

export default DashboardNextPayments
