import { Box, Card, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { capitalize } from 'utils/general'

import { paymentMethodLogo } from '../Show/ActionGroup/helpers'
import useSubscription from '../useSubscription'

const CurrentPaymentMethodSelector = () => {
  const { subscription } = useSubscription()
  const isAutomaticDebit = subscription?.payment_category === 'automatic_debit'
  const isCreditCardPayment = subscription?.payment_category === 'credit_card'
  const ccType = subscription?.payment_method_info?.card_brand?.toLowerCase()
  const ccLastNumbers = subscription?.payment_method_info?.card_last_numbers
  const accountNumber = subscription?.account_number

  const currentPaymentMethodTitle = isCreditCardPayment
    ? `Tarjeta ${capitalize(subscription?.payment_method_info?.card_brand)}`
    : `Cuenta ${capitalize(subscription?.payment_method_info)}`

  return (
    <Card
      sx={(theme) => ({
        width: '100%',
        padding: theme.spacing(1, 3),
        '& p': {
          color: theme.palette.black.light,
        },
        '& b': {
          color: theme.palette.black.main,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          '& img': {
            marginRight: theme.spacing(1.5),
          },
        })}
      >
        {isCreditCardPayment ? (
          <>
            {paymentMethodLogo[ccType] ? (
              <Box
                component="img"
                src={paymentMethodLogo[ccType]}
                alt="Credit card logo"
                width={50}
                height={40}
              />
            ) : (
              <Icon
                name="smart-lock-card"
                sx={(theme) => ({
                  marginRight: theme.spacing(1),
                  fontSize: '2rem',
                })}
              />
            )}
          </>
        ) : null}
        {isAutomaticDebit ? (
          <Box
            component="img"
            src={paymentMethodLogo.automaticDebit}
            alt="Automatic Debit"
            width={70}
            height={70}
          />
        ) : null}
        <Box>
          <Typography variant="lead1">{currentPaymentMethodTitle}</Typography>
          {accountNumber || ccLastNumbers ? (
            <Typography variant="body1" color="black.dark">
              Terminación{' '}
              {isAutomaticDebit ? accountNumber : `...${ccLastNumbers}`}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

export default CurrentPaymentMethodSelector
