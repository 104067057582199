import { useState } from 'react'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import Page from 'components/UI/Page/Page'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useImportWorkersFileService from 'utils/hooks/worker/importWorkersFile'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import { downloadFileWebSocket } from 'services/httpService'

import WorkerImportDone from './Done'
import BoxDownload from './FileActions/Download'
import BoxUpload from './FileActions/Upload'
import WorkerImportModal from './Modal/WorkerImportModal'

const WorkerImport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [workers, setWorkers] = useState([])
  const { handleError } = useErrorHandler()
  const { enqueueDownload } = useDownloadManager()
  const { importWorkersFileMutation } = useImportWorkersFileService()

  const handleSubmit = (file) => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    importWorkersFileMutation.mutate(
      {
        mutationMethod: 'POST',
        fileData: formData,
      },
      {
        onSuccess: async () => {
          try {
            const uploadedFile = await downloadFileWebSocket('import_workers')

            setWorkers(uploadedFile?.workers)
            trackEvent(
              integrationEvent.EMPLOYEE_REGISTER,
              uploadedFile?.workers?.length,
              'upload'
            )
            setIsLoading(false)
          } catch (error) {
            if (error.errors) {
              setErrors(error.errors)
            } else {
              handleError(error)
            }
            setIsLoading(false)
          }
        },
        onError: (error) => {
          handleError(error)
          setIsLoading(false)
        },
      }
    )
  }

  const handleDownloadFile = async () => {
    const serviceCall = async () => {
      return importWorkersFileMutation.mutateAsync({
        mutationMethod: 'GET',
      })
    }

    const callback = () => {
      trackEvent(integrationEvent.DOWNLOAD_TEMPLATE, 'employee')
    }

    enqueueDownload({
      name: 'archivo de importación de empleados',
      fileCode: 'employees_import_file',
      serviceCall,
      callback,
      handleError,
    })
  }

  if (workers.length > 0) return <WorkerImportDone workers={workers} />

  return (
    <Page isLoading={isLoading} header="Agregar Personas">
      <Typography paragraph style={{ marginLeft: '1em' }}>
        Descarga el formato de excel que te da Aleluya para ingresar la
        información de tus empleados y luego cargarlo en nuestro sistema.
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          [theme.breakpoints.down('laptop')]: {
            flexDirection: 'column',
            gap: theme.spacing(2),
          },
        })}
      >
        <BoxDownload onClick={handleDownloadFile} />
        <BoxUpload onSubmitFile={handleSubmit} />
      </Box>
      {errors ? (
        <WorkerImportModal
          errors={errors}
          onSubmitFile={handleSubmit}
          onCancel={setErrors}
        />
      ) : null}
    </Page>
  )
}

export default WorkerImport
