import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import { Menu, MenuItem } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import { getCompanyId } from 'utils/company'
import { useCompanyService } from 'utils/hooks/company/companyService'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

import { WorkerIndexContext } from './Index'

const WorkerIndexToolbarWorkerActions = () => {
  const companyId = getCompanyId()
  const { companyMutation } = useCompanyService({
    queryOptions: { enabled: false },
  })
  const downloadFile = useEnqueueDownload()
  const {
    workerTotalData: totals,
    openDownloadIncomesCertificatesModal,
  } = useContext(WorkerIndexContext)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'pdfDownloadMenu',
  })

  const withoutWorkers = totals && totals?.length === 0

  const handleDownloadWorkersInfo = () => {
    popupState.close()
    companyMutation.mutate(
      {
        mutationMethod: 'GET',
      },
      {
        onSuccess: () => {
          downloadFile({
            name: 'archivo de trabajadores',
            fileCode: 'wokers_file',
            pathname: `companies/${companyId}/workers_file`,
          })
        },
      }
    )
  }

  const handleOpenDownloadIncomesCertificatesModal = () => {
    openDownloadIncomesCertificatesModal()
    popupState.close()
  }

  const endFreeWorkerModal = useEndFreeModals()

  const handleEndFreeWorkerModal = () => {
    endFreeWorkerModal.openEndFreeWorkerModal()
    popupState.close()
  }

  return (
    <>
      {!withoutWorkers ? (
        <>
          <Button
            variant="outlined"
            endIcon={<Icon name="arrow-down" basic />}
            {...bindTrigger(popupState)}
          >
            Reportes y certificados
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem
              onClick={
                endFreeWorkerModal.isNotValidCompany
                  ? handleEndFreeWorkerModal
                  : handleDownloadWorkersInfo
              }
            >
              Información de empleados
            </MenuItem>
            <MenuItem
              onClick={
                endFreeWorkerModal.isNotValidCompany
                  ? handleEndFreeWorkerModal
                  : handleOpenDownloadIncomesCertificatesModal
              }
            >
              Certificados de Ingresos y Retenciones
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </>
  )
}

export default WorkerIndexToolbarWorkerActions
