import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  cancelSubscription,
  getAllPayments,
  getExtraWorkersPlan,
  getImmediatePaymentValue,
  getNextCyclePaymentValue,
  getPlans,
  getSubscription,
  sendInvoice,
  updateSubscription,
} from 'services/subscription/subscriptionService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { queryKey, page, newPlanId, subscriptionType, workersNumber } =
    serviceParams

  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getSubscription')
    return getSubscription({ extraWorkersNumber: null })

  if (currentQueryKey === 'getAllPayments') return getAllPayments({ page })

  if (currentQueryKey === 'getPlans') return getPlans()

  if (currentQueryKey === 'getNextCyclePaymentValue')
    return getNextCyclePaymentValue(newPlanId, workersNumber)

  if (currentQueryKey === 'getImmediatePaymentValue')
    return getImmediatePaymentValue(newPlanId, subscriptionType)

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    planId,
    paymentId,
    extraWorkers,
    crmEvent,
    mutationKey,
    cancellationData,
    subscriptionType,
    newPlanId,
    workersNumber,
    isAPartnerChild = false,
  } = mutationData

  if (mutationMethod === 'GET' && mutationKey === 'getImmediatePaymentValue')
    return getImmediatePaymentValue(newPlanId, subscriptionType, extraWorkers)

  if (mutationMethod === 'GET' && mutationKey === 'getNextCyclePaymentValue')
    return getNextCyclePaymentValue(newPlanId, workersNumber)

  if (mutationMethod === 'GET' && !planId && !crmEvent)
    return getSubscription({ extraWorkersNumber: extraWorkers })

  if (mutationMethod === 'GET' && crmEvent) return getSubscription({ crmEvent })

  if (mutationMethod === 'PUT') {
    if (mutationKey === 'updateSubscription')
      return updateSubscription(newPlanId, subscriptionType, isAPartnerChild)

    if (mutationKey === 'cancelSubscription')
      return cancelSubscription(cancellationData)
  }

  if (mutationMethod === 'GET') {
    return getExtraWorkersPlan(planId, extraWorkers)
  }

  if (mutationMethod === 'POST') return sendInvoice(paymentId)

  return null
}

const useSubscriptionService = ({
  serviceParams = { queryKey: 'getSubscription' },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restSubscriptionResponse } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const subscriptionMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    subscriptionQuery: {
      data,
      ...restSubscriptionResponse,
    },
    subscriptionMutation,
  }
}

export default useSubscriptionService
