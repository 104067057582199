import { Box, Typography, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import StatsCounter from './Counter'

const TeamStats = ({ teamCount, workersOffDuty, isLoading }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        gridColumn: '1 / -1',
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '1 / 10',
          gridRow: '2',
        },
      })}
    >
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box
          sx={(theme) => ({
            display: 'grid',
            columnGap: theme.spacing(4),
            rowGap: theme.spacing(3),
            gridTemplateColumns: ' 1fr',
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
          })}
        >
          <Box
            sx={{
              display: 'flex',
              gridColumn: 'span 1',
              flexDirection: 'column',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
              })}
            >
              Tu equipo
            </Typography>
            <Box
              sx={(theme) => ({
                backgroundColor: 'complementary1.light',
                borderRadius: '1rem',
                minHeight: '5.5rem',
                display: 'flex',
                gridColumn: 'span 1',
                padding: theme.spacing(2),
                alignItems: 'center',
                gap: theme.spacing(2),
                [theme.breakpoints.up('tablet')]: {
                  justifyContent: 'space-between',
                  gap: theme.spacing(1),
                  padding: theme.spacing(1.25),
                },
                [theme.breakpoints.up('desktop')]: {
                  padding: theme.spacing(2),
                },
              })}
            >
              <StatsCounter
                label="Activos"
                icon={
                  <Icon name="avatar-approved" sx={{ fontSize: '1.7rem' }} />
                }
                total={teamCount?.active_workers}
              />
              <StatsCounter
                label="En contratación"
                icon={<Icon name="avatar-search" sx={{ fontSize: '1.7rem' }} />}
                total={teamCount?.onboarding_workers}
              />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gridColumn: 'span 1',
              flexDirection: 'column',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: 'span 2',
              },
            })}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                marginBottom: theme.spacing(1),
              })}
            >
              Personas tiempo fuera
            </Typography>
            <Box
              sx={(theme) => ({
                backgroundColor: 'accent3.light',
                borderRadius: '1rem',
                minHeight: '5.5rem',
                display: 'flex',
                gridColumn: 'span 1',
                padding: theme.spacing(2),
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: theme.spacing(1),
              })}
            >
              <StatsCounter
                label={isMobile ? 'Vacaciones' : 'En vacaciones'}
                icon={<Icon name="island" sx={{ fontSize: '1.7rem' }} />}
                total={workersOffDuty?.on_vacation}
              />
              <StatsCounter
                label={isMobile ? 'Licencia' : 'De licencia'}
                icon={<Icon name="calendar-2" sx={{ fontSize: '1.7rem' }} />}
                total={workersOffDuty?.on_leave}
              />
              <StatsCounter
                label="Incapacidades"
                icon={
                  <Icon name="medical-insurance" sx={{ fontSize: '1.7rem' }} />
                }
                total={workersOffDuty?.on_sick_leave}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default TeamStats
