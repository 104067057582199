import BankField from 'components/UI/Formik/CommonFields/BankField'

import ClientTypeField from './ClientType'
import ContainerFields from './ContainerFields'

const SubscriptionPSEFields = () => {
  return (
    <>
      <ContainerFields>
        <ClientTypeField />
        <BankField />
      </ContainerFields>
    </>
  )
}

export default SubscriptionPSEFields
