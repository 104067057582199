import cardValidator from 'card-validator'
import * as yup from 'yup'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

import SubscriptionAutomaticDebitFields from './AutomaticDebitFields'
import SubscriptionCreditCardFields from './CreditCardFields'
import SubscriptionPSEFields from './PSEFields'

yup.setLocale(yupLocaleES)

export const getPaymentFields = (
  activePayment,
  isEditingPaymentMethod = false
) => ({
  automatic_debit: {
    fields: (
      <SubscriptionAutomaticDebitFields
        isEditingPaymentMethod={isEditingPaymentMethod}
        activePayment={activePayment}
      />
    ),
  },
  credit_card: {
    fields: (
      <SubscriptionCreditCardFields
        isEditingPaymentMethod={isEditingPaymentMethod}
      />
    ),
  },
  pse: {
    fields: <SubscriptionPSEFields />,
  },
  bank_transfer: {
    fields: <SubscriptionPSEFields />,
  },
})

export const dirtyPaymentValues = (values, paymentMethod, plan) => {
  let dirtyValues

  if (paymentMethod === 'credit_card') {
    dirtyValues = {
      card_number: values.card_number,
      security_code: values.security_code,
      payer_name: values.payer_name,
      payment_type: plan.type,
      terms_accepted: values.terms_accepted,
    }

    const { expiration_date: expirationDate } = values

    dirtyValues.expiration_date =
      // MMYYYY => YYYY/MM
      `${expirationDate.substring(2)}/${expirationDate.substring(0, 2)}`
  }

  if (paymentMethod === 'pse') {
    dirtyValues = {
      account_type: values.account_type,
      account_number: values.account_number,
      bank: values.bank?.id,
      client_type: values.client_type,
      id_number: values.id_number,
      payment_type: plan.type,
    }
  }

  if (paymentMethod === 'automatic_debit') {
    dirtyValues = {
      document_type: values.document_type,
      id_number: values.id_number,
      account_type: values.account_type,
      account_number: values.account_number,
      bank: values.bank?.id,
      terms_accepted: values.terms_accepted,
    }
  }

  dirtyValues.plan_id = plan.id
  dirtyValues.payment_type = plan.payment_type

  if (plan.paid_extra_workers)
    dirtyValues.paid_extra_workers = plan.paid_extra_workers

  return dirtyValues
}

export const getSchemaValidation = (paymentMethod, isCurrentMethodPayment) => {
  let schemaValidation

  if (!isCurrentMethodPayment) {
    if (paymentMethod === 'credit_card') {
      schemaValidation = yup.object({
        payer_name: yup.string().trim().required(),
        card_number: yup
          .number()
          .required()
          .test(
            'test-number',
            'Número de tarjeta de crédito inválido',
            (value) => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .required()
          .test(
            'test-expiration-year',
            'Debe ser una fecha válida',
            (value) => {
              return cardValidator.expirationDate(value).isValid
            }
          ),
        security_code: yup
          .string()
          .required()
          // For some reason is necessary to declare thdi function
          // eslint-disable-next-line func-names
          .test(
            'test-number',
            'Código de seguridad inválido',
            function (value) {
              const type = cardValidator.number(this.parent.card_number).card
                ?.type

              return cardValidator.cvv(
                `${value}`,
                type === 'american-express' ? 4 : 3
              ).isValid
            }
          ),
      })
    } else if (paymentMethod === 'pse') {
      schemaValidation = yup.object({
        client_type: yup.string().required(),
        bank: yup.object().nullable().required(),
      })
    } else {
      schemaValidation = yup.object({
        document_type: yup.string().nullable().required(),
        id_number: yup.string().nullable().required(),
        account_type: yup.string().nullable().required(),
        account_number: yup.number().nullable().required(),
        bank: yup.object().nullable().required(),
      })
    }

    if (paymentMethod !== 'pse') {
      schemaValidation = schemaValidation.concat(
        yup.object({
          terms_accepted: yup
            .boolean()
            .nullable()
            .test(
              'test-recurrent-payment-acceptance',
              'Debes aceptar el cobro recurrente para usar este método de pago',
              (value) => value
            ),
        })
      )
    }
  }

  return schemaValidation
}

export const getInitialValues = (isCurrentMethodPayment = false) => ({
  client_type: '',
  terms_accepted: false,
  bank: null,
  is_current_payment_method: isCurrentMethodPayment,
})

export const getTabsData = (
  subscription,
  isEditingPaymentMethod,
  currentPlanPayment
) => {
  const {
    payment_methods: availablePaymentMethods,
    payment_category: paymentCategory,
  } = subscription

  const {
    credit_card: showCreditCardMethod,
    automatic_debit: automaticDebitMethodAvailable,
    pse: showPseMethod,
  } = availablePaymentMethods || {}

  // In case of immediate payment required, shows automatic debit only when the bank account is already connected or is a current plan payment
  const showAutomaticDebitMethod =
    (paymentCategory === 'automatic_debit' ||
      isEditingPaymentMethod ||
      currentPlanPayment) &&
    automaticDebitMethodAvailable

  const availablePaymentMethodsNumber = Object.keys(
    availablePaymentMethods || {}
  ).length

  const tabs = []

  // Show credit card when available or when no other payment methods are available
  if (showCreditCardMethod || availablePaymentMethodsNumber === 0)
    tabs.push({
      key: 'credit_card',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="smart-lock-card"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Tarjeta débito/crédito
        </Box>
      ),
    })

  if (showAutomaticDebitMethod)
    tabs.push({
      key: 'automatic_debit',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refresh"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Débito automático
        </Box>
      ),
    })

  // Hide pse when editing current payment method
  if (showPseMethod && !isEditingPaymentMethod)
    tabs.push({
      key: 'pse',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refund"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          PSE
        </Box>
      ),
    })

  return tabs
}
