import { Form, Formik } from 'formik'
import queryString from 'query-string'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Box, Divider, Link as MuiLink, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import GoogleLoginButton from 'components/Auth/common/GoogleAuth'
import Button from 'components/UI/Button/Button'
import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import Link from 'components/UI/MaterialUI/Link'

import { isArusDist } from 'utils/auth'
import { generateRandomNumber, isBuildForCI, isProduction } from 'utils/general'
import useRegistryService from 'utils/hooks/auth/registryService'
import useSessionService from 'utils/hooks/auth/session'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { LOGIN, ONBOARDING_ROLE } from 'config/routes'

import AuthLogo from '../common/AuthLogo'
import MainContainer from '../common/MainContainer'
import LandingCustomField from './LandingCustomField'
import { getInitialValues, getValidationSchema } from './helpers'

const SignUp = () => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { logIn, isAuthenticated } = useUser()
  const [signUpOk, setSignUpOk] = useState(false)
  const { handleError } = useErrorHandler()
  const { registryMutation } = useRegistryService()
  const { sessionMutation } = useSessionService()
  const hasAleluya10K = location.search.includes('Aleluya10mil')

  if (signUpOk || isAuthenticated()) {
    return <Navigate to={ONBOARDING_ROLE()} />
  }

  const handleSubmit = async (values, form) => {
    try {
      const randomNumber = generateRandomNumber()
      const filledValues = {
        ...values,
        company_name: `Empresa Aleluya_${randomNumber}`,
        name: `Usuario Aleluya_${randomNumber}`,
      }

      if (isArusDist()) filledValues.distributor = 'arus'

      await registryMutation.mutateAsync({
        mutationMethod: 'POST',
        registry: {
          ...queryParams,
          ...filledValues,
        },
      })
      try {
        await sessionMutation.mutateAsync(
          {
            mutationMethod: 'POST',
            credentials: {
              ...queryParams,
              ...filledValues,
            },
          },
          {
            onSuccess: ({ data }) => {
              logIn(data)

              if (isProduction && !isBuildForCI) {
                window?.dataLayer?.push?.({
                  event: 'Sign Up',
                })
              }
              setSignUpOk(true)
            },
          }
        )
      } catch (error) {
        handleError(error)
      }
    } catch (error) {
      handleError(error, form, {
        errorFieldMap: { id_number: 'company_id_number' },
      })
    }
  }

  const initialValues = getInitialValues(queryParams)

  const validationSchema = getValidationSchema()

  return (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          overflow: 'auto',
          maxHeight: '100vh',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: theme.spacing(8, 2, 6, 2),
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(8, 8, 6, 8),
            },
            [theme.breakpoints.between('laptop', 'lg')]: {
              maxWidth: 'none',
              padding: theme.spacing(4, 6, 6, 6),
            },
            [theme.breakpoints.up('lg')]: {
              padding: theme.spacing(4, 13.5, 6, 13.5),
              maxWidth: 'none',
            },
            [theme.breakpoints.up('desktop')]: {
              padding: theme.spacing(4, 2, 6, 2),
              maxWidth: '30rem',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              marginBottom: theme.spacing(7.75),
            })}
          >
            <AuthLogo />
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              marginBottom: theme.spacing(6.75),
            })}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: 'left',
                width: '100%',
              }}
              gutterBottom
            >
              Crea tu cuenta
            </Typography>
            <Typography variant="body1">
              Ingresa tus datos y comienza a disfrutar de la mejor experiencia
              liquidando nómina {hasAleluya10K ? '¡Aleluya!' : '¡Gratis!'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GoogleLoginButton action="signup" />
          </Box>
          <Box
            sx={(theme) => ({
              width: '100%',
              marginTop: theme.spacing(3),
            })}
          >
            <Divider
              sx={(theme) => ({
                '&::before, ::after': {
                  borderColor: theme.palette.black.light,
                },
              })}
            >
              <Typography variant="h3" color="black.dark">
                O
              </Typography>
            </Divider>
          </Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => {
              return (
                <>
                  <Form name="signup_form">
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: theme.spacing(1, 0),
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                          rowGap: theme.spacing(3),
                          marginBottom: theme.spacing(4),
                        })}
                      >
                        <LandingCustomField
                          type="email"
                          name="email"
                          label="Tu correo electrónico"
                          placeholder="Ingresa tu correo electrónico"
                          queryParams={queryParams}
                        />
                        <PasswordField
                          name="password"
                          label="Tu contraseña"
                          placeholder="Ingresa tu contraseña"
                          autoComplete="password"
                          tooltipContent="Tu contraseña debe contener mínimo 8 caracteres e incluir letras mayúsculas, letras minúsculas, números y símbolos."
                        />
                        <PasswordField
                          name="password_confirmation"
                          label="Repetir contraseña"
                          placeholder="Confirma tu contraseña"
                          autoComplete="password"
                        />
                      </Box>
                      <Button
                        type="submit"
                        color="primary"
                        loading={isSubmitting}
                        sx={(theme) => ({
                          width: '100%',
                          marginBottom: theme.spacing(4),
                        })}
                        data-cy="sign_up_button"
                      >
                        Crear cuenta
                      </Button>
                    </Box>
                  </Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" color="black.main">
                      ¿Ya tienes una cuenta?{' '}
                      <Link to={LOGIN} fontWeight={600} color="accent4.main">
                        Ingresa
                      </Link>
                    </Typography>
                  </Box>
                </>
              )
            }}
          </Formik>
          <Box
            component="footer"
            sx={(theme) => ({
              margin: theme.spacing(3),
              textAlign: 'center',
            })}
          >
            <Typography variant="body2">
              Al crear tu cuenta aceptas nuestros{' '}
              <MuiLink
                href="https://aleluya.com/terminos-y-condiciones"
                target="_blank"
                color="accent4.main"
                sx={{
                  fontWeight: 600,
                }}
              >
                términos y condiciones y política de tratamiento de datos
              </MuiLink>
              .
            </Typography>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  )
}

export default SignUp
