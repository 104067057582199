import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Radio,
  Typography,
  accordionClasses,
  useMediaQuery,
} from '@mui/material'

const MethodAccordion = ({
  id,
  logos = [],
  children,
  title,
  expanded,
  onChange,
  accordionSummarySx,
  hideAccordionBorders = false,
  disableSelector = false,
}) => {
  const hideLogos = useMediaQuery((theme) => theme.breakpoints.down(768))
  const isExpanded = expanded === id
  return (
    <Accordion
      expanded={isExpanded}
      sx={(theme) => ({
        ...(!hideAccordionBorders && {
          border: `0.125rem solid ${theme.palette.primary.dark}`,
          borderRadius: '1',
          overflow: 'hidden',
          minHeight: '5.625rem',
          [`&.${accordionClasses.root}`]: {
            borderTop: `0.125rem solid ${theme.palette.primary.dark}`,
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
          },
        }),
      })}
      onChange={() => onChange(id)}
    >
      <AccordionSummary
        disabled={disableSelector}
        sx={[
          (theme) => ({
            padding: theme.spacing(2, 3),
            minHeight: '5.625rem',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& > .MuiAccordionSummary-content.Mui-expanded': {
              margin: theme.spacing(1.5, 0),
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '5.625rem',
            },
            '&.MuiAccordionSummary-root.Mui-disabled': {
              color: theme.palette.black.light,
              opacity: 0.8,
              '.MuiRadio-root.Mui-checked': {
                color: theme.palette.black.light,
              },
            },
          }),
          accordionSummarySx,
        ]}
        data-cy={`${id}_selector`}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap',
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disableRipple
              disableFocusRipple
              disableTouchRipple
              sx={(theme) => ({
                backgroundColor: 'transparent',
                marginRight: theme.spacing(1.5),
                width: '1.25rem',
                height: '1.25rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.MuiRadio-colorPrimary.Mui-checked:hover': {
                  backgroundColor: 'transparent',
                },
                [theme.breakpoints.down('sm')]: {
                  marginRight: theme.spacing(2),
                },
              })}
              checked={isExpanded}
            />
            <Typography variant="body1">{title}</Typography>
          </Box>
          {!hideLogos ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                '& > img': {
                  marginRight: theme.spacing(2),
                },
                '& > img:last-child': {
                  marginRight: 0,
                },
              })}
            >
              {logos.map((logo) => (
                <Box
                  component="img"
                  key={logo.id}
                  alt={`Logo ${logo.id}`}
                  src={logo.icon}
                  sx={{
                    width: logo.width,
                    height: logo.height,
                  }}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          padding: theme.spacing(0, 4, 3.5, 4),
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2.5, 3, 2.5),
          },
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default MethodAccordion
