import { useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { getFileNameFromUrl, isValidUrl } from 'utils/general'
import useAutoScroll from 'utils/hooks/useAutoScroll'

import SectionCard from '../SectionCard'
import { documentOptions } from './helpers'

const DocumentSignatureFields = ({ options }) => {
  const location = useLocation()
  const [elementId] = useAutoScroll({
    elementId: 'certificates-signature-configuration',
    auto: location.hash === '#certificates-signature-configuration',
    options: {
      block: 'center',
    },
  })
  const { values } = useFormikContext()

  const { signature_in_labor_certificate, signature_in_payslip } = options
  const pictureUrl = isValidUrl(values.labor_certificate_signature)
  const tempPictureName = getFileNameFromUrl(
    pictureUrl ? pictureUrl.search : undefined
  )
  const pictureName = tempPictureName?.replace(/\.[^.]+$/, '')

  return (
    <Box sx={(theme) => ({ marginTop: theme.spacing(6) })}>
      <SectionCard
        subtitle="Firma para certificados laborales y colillas de pago"
        titleId={elementId}
      >
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginTop: theme.spacing(-2) })}
        >
          La firma que registres aquí, será la que aparecerá por defecto en
          todas las descargas de certificados laborales.
        </Typography>

        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            flexDirection: 'column',
            marginTop: theme.spacing(4),
            rowGap: theme.spacing(2),
          })}
        >
          <FormField
            name="signature_image"
            label="Imagen de la firma"
            variant="picture"
            fileType={['image/jpeg', 'image/png']}
            accept={['image/jpeg', 'image/png']}
            fileName={pictureName}
            optional={false}
          />
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              [theme.breakpoints.up('tablet')]: {
                columnGap: theme.spacing(1.75),
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              [theme.breakpoints.up('laptop')]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            })}
          >
            <FormField
              name="signee_name"
              label="Nombre"
              variant="text"
              placeholder="Nombre completo"
              optional={false}
            />
            <DocumentTypeField
              idNumberKey="signee_id_number"
              name="signee_document_type"
              options={documentOptions}
              optional={!values.signee_id_number}
            />
            <IdentificationNumberField
              name="signee_id_number"
              placeholder="Documento de identidad"
              documentTypeKey={values.signee_document_type}
              disableValidate
              optional
            />
            <FormField
              name="signee_position"
              label="Cargo"
              variant="text"
              placeholder="Cargo dentro de la empresa"
              optional={false}
            />
            <FormField
              name="signee_email"
              label="Correo electrónico"
              variant="text"
              placeholder="Dirección de correo electrónico"
              optional={false}
            />
            <FormField
              name="signee_phone"
              label="Celular"
              variant="number"
              placeholder="Número de celular"
              format="### ### ####"
            />
          </Box>
          <FormField
            name="signature_in_labor_certificate"
            label="¿Desea ver la firma en certificados laborales?"
            variant="select"
            optional={false}
            options={signature_in_labor_certificate}
          />
          <FormField
            name="signature_in_payslip"
            label="¿Desea ver la firma en colillas de pago?"
            variant="select"
            optional={false}
            options={signature_in_payslip}
          />
        </Box>
      </SectionCard>
    </Box>
  )
}

export default DocumentSignatureFields
