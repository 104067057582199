import { Box, Typography } from '@mui/material'

import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'

const SectionCard = ({ subtitle, children, tooltipContent, titleId }) => {
  return (
    <Box
      sx={(theme) => ({
        marginBottom: theme.spacing(4),
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        id={titleId}
      >
        {subtitle}
        {tooltipContent ? (
          <TooltipInfoIcon
            title={tooltipContent}
            interactive={typeof tooltipContent !== 'string'}
            tooltipProps={{ placement: 'right' }}
          />
        ) : null}
      </Typography>
      {children}
    </Box>
  )
}

export default SectionCard
