import { formatDisplayDate, getColombianCurrentDate } from 'utils/dateTime'
import { formatCurrency, getWordSingular } from 'utils/format'

export const getMainText = (
  currentPlanPayment,
  modalityUpgrade,
  planUpgrade,
  subscription,
  isChangingModality,
  isPremiumExpiredSubscription,
  isPartner
) => {
  const getChangeType = () => {
    if (isPremiumExpiredSubscription) return 'isPremiumExpiredSubscription'
    if (modalityUpgrade && planUpgrade) return 'modality&PlanUpgrade'
    if (modalityUpgrade && !planUpgrade) return 'modalityUpgrade'
    if (subscription.type === 'year' && isChangingModality && planUpgrade)
      return 'modalityDowngrade&PlanUpgrade'
    if (currentPlanPayment) return 'currentPlanPayment'
    if (subscription.type === 'year' && !planUpgrade)
      return 'annualPlanDowngrade'
    return 'annualPlanUpgrade'
  }

  const mainText = {
    isPremiumExpiredSubscription: (
      <>
        Con este pago, podrás seguir disfrutando de todas las funcionalidades
        que Aleluya te ofrece.
        <br />
        <br />
        Recuerda que puedes agregar más personas en tu suscripción, para que no
        se quede ninguna por fuera.
      </>
    ),
    currentPlanPayment: (
      <>
        Realizarás el pago de tu plan actual, para continuar disfrutando de
        todas las funcionalidades que Aleluya te ofrece.
        <br />
        <br />
        {!isPartner
          ? ' Recuerda que puedes agregar más personas en tu suscripción, para que no se quede ninguna por fuera.'
          : null}
      </>
    ),
    'modality&PlanUpgrade': (
      <>
        Vas a aumentar los superpoderes con los que cuentas en tu plan actual.
        Además, la frecuencia de pago de tu suscripción cambiará de mensual a
        anual.
      </>
    ),
    modalityUpgrade: (
      <>
        Estás a punto de cambiar la frecuencia de pago de tu suscripción de
        mensual a anual. Este cambio se hará efectivo al finalizar tu periodo
        actual de facturación.
      </>
    ),
    'modalityDowngrade&PlanUpgrade': (
      <>
        Vas a aumentar los superpoderes con los que cuentas en tu plan. Para
        activar estas funcionalidades, debes realizar un pago. El cobro se hará
        proporcional al tiempo restante de tu suscripción y a la cantidad de
        personas pagadas que tienes en tu plan. El cambio en la frecuencia de
        pago de anual a mensual se hará efectivo una vez que culmine tu periodo
        actual de facturación.
        <br />
        <br />
        Recuerda que puedes agregar más personas en tu suscripción anual para
        que no se quede ninguna por fuera.
      </>
    ),
    annualPlanDowngrade: (
      <>
        Vas a disminuir los superpoderes con los que cuentas en tu plan.
        Recuerda que este cambio se hará efectivo al finalizar tu periodo actual
        de facturación.
      </>
    ),
    annualPlanUpgrade: (
      <>
        Vas a aumentar los superpoderes con los que cuentas en tu plan anual.
        Para activar estas funcionalidades, debes realizar un pago. El cobro se
        hará proporcional al tiempo restante de tu suscripción y a la cantidad
        de personas pagadas que tienes en tu plan.
        <br />
        <br />
        Recuerda que puedes agregar más personas en tu suscripción anual para
        que no se quede ninguna por fuera.
      </>
    ),
  }
  return mainText[getChangeType()]
}

export const getCardsData = (
  totalPayment,
  activeUntil,
  themeInstance,
  currentWorkersNumber,
  planUpgrade,
  paidWorkers,
  selectedModality,
  isPremiumExpiredSubscription
) => {
  const date =
    planUpgrade || isPremiumExpiredSubscription
      ? getColombianCurrentDate()
      : activeUntil

  const data = [
    {
      id: 'yearly_payment',
      label: `Valor total pagar ${
        selectedModality === 'year' ? 'anualidad' : 'mensualidad'
      }`,
      value:
        typeof totalPayment === 'number' ? formatCurrency(totalPayment) : null,
      adornmentColor: 'accent3.light',
      baseColor: themeInstance.palette.accent3.main,
      footnote: '*Incluye descuento de suscripción anual',
    },
    {
      id: 'employees_number',
      label: (
        <>
          Total personas{' '}
          {planUpgrade || isPremiumExpiredSubscription ? 'pagadas' : 'activas'}{' '}
        </>
      ),
      value: (
        <>
          {planUpgrade ? paidWorkers : currentWorkersNumber}{' '}
          {getWordSingular('personas', currentWorkersNumber === 1)}
        </>
      ),
      adornmentColor: 'primary.light',
      baseColor: themeInstance.palette.primary.dark,
    },
    {
      id: 'plan_initial_date',
      label:
        planUpgrade || isPremiumExpiredSubscription
          ? 'Fecha inicio plan'
          : 'Próxima fecha de pago',
      value: formatDisplayDate(date),
      adornmentColor: 'complementary1.light',
      baseColor: themeInstance.palette.complementary1.dark,
    },
  ]

  return data
}
