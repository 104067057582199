import useModals from 'components/App/ModalsManager/useModals'

import TransactionResponseModal from './TransactionResponseModal'

const useTransactionResponseModal = () => {
  const modals = useModals()

  const handleClose = () => {
    modals.closeModal()
  }

  const openTransactionResponseModal = (subscriptionTypeValidators) => {
    const { isPremiumExpiredSubscription } = subscriptionTypeValidators || {}

    modals.openModal({
      id: 'TransactionResponse',
      content: (
        <TransactionResponseModal
          subscriptionTypeValidators={subscriptionTypeValidators}
          handleClose={handleClose}
        />
      ),
      modalProps: {
        hideFooter: true,
        header: isPremiumExpiredSubscription
          ? '¡Recibimos el pago de tu plan!'
          : '¡Acabas de actualizar tu plan!',
        onCloseModal: handleClose,
        paperSx: {
          maxWidth: '46rem',
        },
      },
    })
  }

  return { openTransactionResponseModal }
}

export default useTransactionResponseModal
