import { useParams } from 'react-router-dom'

import { Box, Button, Typography, alpha, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { formatCurrency } from 'utils/format'
import usePayrollService from 'utils/hooks/payroll/payroll'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

import DataTable from './DataTable'
import Formula from './Formula'
import { calculations, tableSubtitles } from './helpers'

const PeriodDetailsBodyNewSection = ({ activeDetail, details }) => {
  const tabletDown = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const isTerminationTab = activeDetail === 'termination'
  const sectionDetails = details[activeDetail]
  const { payrollId } = useParams()
  const downloadFile = useEnqueueDownload()

  const { payrollMutation } = usePayrollService({
    queryOptions: {
      enabled: false,
    },
  })

  if (!sectionDetails) return <LoadingBox />

  const sectionDetailsKeys = Object.keys(sectionDetails)

  const handleDownloadPayrollRetentionSummary = () => {
    payrollMutation.mutate(
      {
        mutationMethod: 'GET_DETAILS',
        mutationKey: 'downloadPayrollRetentionFile',
        payrollId,
        searchParams: {
          payroll_retention_file: true,
          async: true,
        },
      },
      {
        onSuccess: () => {
          return downloadFile({
            name: 'detalle de Retefuente',
            fileCode: 'payroll_retention_file',
            pathname: `payroll_retentions/${payrollId}/view_details`,
          })
        },
      }
    )
  }

  return sectionDetailsKeys.map((category) => {
    // in some categories there are many elements in an array of objects
    // and in other categories a single element.
    // We make sure it is always in an array.
    const sectionDetailsArray = [].concat(sectionDetails[category])

    return sectionDetailsArray.map((item, index) => {
      const { information, data, totals } = item

      return information ? (
        <Box
          sx={(theme) => ({ marginBottom: theme.spacing(7) })}
          key={`${category}_item${index.toString()}`}
        >
          <Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: theme.spacing(2),
                [theme.breakpoints.up('tablet')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: theme.spacing(4),
                },
              })}
            >
              <Typography
                variant="h4"
                color="primary.dark"
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                })}
                data-cy={`${category}-item-title`}
              >
                {information.title}
              </Typography>
              {category === 'summary' ? (
                <Button
                  variant="text"
                  endIcon={<Icon name="download" />}
                  onClick={handleDownloadPayrollRetentionSummary}
                >
                  Descargar resumen
                </Button>
              ) : null}
            </Box>
            <Typography
              variant="body1"
              sx={(theme) => ({
                margin: theme.spacing(1, 0),
              })}
            >
              {information.description}
            </Typography>
            {information.formulas?.map((formula, indexFormula) => (
              <Formula
                key={indexFormula.toString()}
                formula={calculations[formula]}
                information={information}
              />
            ))}
          </Box>
          <Box>
            <Typography
              variant="h4"
              color="primary.dark"
              sx={(theme) => ({ margin: theme.spacing(2.5, 1.5) })}
            >
              {tableSubtitles[category]}
            </Typography>
            <Box
              sx={(theme) => ({
                boxShadow: theme.shadows[5],
                borderRadius: '1rem',
              })}
            >
              <DataTable
                data={data}
                name={category}
                isTerminationTab={isTerminationTab}
                tabletDown={tabletDown}
              />
              {totals.total.label && !isTerminationTab ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                    borderBottomLeftRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                    padding: theme.spacing(2.75, 2),
                  })}
                >
                  <Typography variant={tabletDown ? 'h4' : 'h3'}>
                    {totals.total.label}
                  </Typography>
                  <Typography variant={tabletDown ? 'h4' : 'h3'}>
                    {formatCurrency(totals.total.value)}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box marginTop={6}>
              {totals.formulas.map((formula, totalIndex) => (
                <Box key={totalIndex.toString()}>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      marginTop: theme.spacing(3),
                    })}
                  >
                    <Typography variant="h4" color="primary.dark" paragraph>
                      Cálculo de {formula.label}
                    </Typography>
                  </Box>
                  <Formula
                    isTemplate={false}
                    formula={formula}
                    isTerminationTab={isTerminationTab}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ) : null
    })
  })
}

export default PeriodDetailsBodyNewSection
