import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import Loading from 'components/UI/Loading/Loading'
import Page from 'components/UI/Page/Page'

import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import * as routes from 'config/routes'

import useSubscription from '../useSubscription'
import PaymentSummary from './Summary/Summary'
import getFormattedResponse from './helpers'

const SubscriptionTransactionResponse = () => {
  const location = useLocation()
  const { user, refreshCompany } = useUser()
  const { subscription } = useSubscription()
  const queryParams = new URLSearchParams(location?.search)
  const isPsePayment = queryParams.get('pse_payment') === 'true'

  const { paymentMethodQuery } = usePaymentMethodService({
    serviceParams: { queryKey: 'getPaymentPSE' },
    queryOptions: {
      enabled: isPsePayment,
      onSuccess: () => {
        refreshCompany()
      },
    },
  })

  const trackPaymentEvent = location?.state?.payment_status === 'APPROVED'

  useEffect(() => {
    if (trackPaymentEvent) {
      trackEvent(integrationEvent.FIRST_PAYMENT)
    }
  }, [trackPaymentEvent])

  const showTransactionResponseView = Boolean(location?.state) || isPsePayment

  if (!showTransactionResponseView) {
    return <Navigate to={routes.DASHBOARD} />
  }

  const isGettingQuery = paymentMethodQuery?.isLoading

  const paymentData = location || paymentMethodQuery?.data

  const paymentResponseFormatted =
    getFormattedResponse(
      user,
      paymentData,
      subscription,
      paymentMethodQuery?.data,
      isPsePayment
    ) || {}

  return isGettingQuery ? (
    <Loading />
  ) : (
    <Page documentTitle="Respuesta de tu pago" isLoading={isGettingQuery}>
      <PaymentSummary summary={paymentResponseFormatted} />
    </Page>
  )
}

export default SubscriptionTransactionResponse
