import { Typography } from '@mui/material'

import BankField from 'components/UI/Formik/CommonFields/BankField'

import AccountHolderIdentificationFields from '../SubscriptionFormFields/AccountHolderIdentificationFields'
import ContainerFields from '../SubscriptionFormFields/ContainerFields'
import TermsAcceptanceField from '../SubscriptionFormFields/TermsAcceptanceField'
import AccountFields from './Account'

const AutomaticDebitFields = ({ isEditingPaymentMethod, activePayment }) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      >
        ¡Hey, ahora tu cobro se hará a través de DRUO, facilito y sin gallos!
      </Typography>
      <ContainerFields>
        <AccountHolderIdentificationFields />
        <BankField provider="druo" />
        <AccountFields />
        <TermsAcceptanceField
          paymentVariant={
            isEditingPaymentMethod ? 'payment_data_update' : 'new_payment'
          }
          activePaymentMethod={activePayment}
        />
      </ContainerFields>
    </>
  )
}

export default AutomaticDebitFields
