import amex from 'assets/images/views/subscription/amex.svg'
import codensa from 'assets/images/views/subscription/codensa.png'
import diners from 'assets/images/views/subscription/dinersclub.svg'
import automaticDebit from 'assets/images/views/subscription/druo.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import pse from 'assets/images/views/subscription/pse.svg'
import visa from 'assets/images/views/subscription/visa.svg'

export const creditCardOptions = [
  {
    id: 'mastercard',
    icon: mastercard,
    width: 20,
    height: 16,
  },
  {
    id: 'visa',
    icon: visa,
    width: 40,
    height: 16,
  },
  {
    id: 'amex',
    icon: amex,
    width: 18,
    height: 18,
  },
  {
    id: 'diners',
    icon: diners,
    width: 57,
    height: 16,
  },
  {
    id: 'codensa',
    icon: codensa,
    width: 32,
    height: 16,
  },
]

export const pseOptions = [
  {
    id: 'pse',
    icon: pse,
    width: 24,
    height: 24,
  },
]

export const automaticDebitOptions = [
  {
    id: 'automatic_debit',
    icon: automaticDebit,
    width: 50,
    height: 50,
  },
]

export const getPaymentMethodLogoOptions = (paymentMethod) => {
  if (paymentMethod === 'credit_card') return creditCardOptions
  if (paymentMethod === 'pse') return pseOptions
  if (paymentMethod === 'automatic_debit') return automaticDebitOptions
  return []
}

export const getHeaderTitle = (paymentMethod) => {
  if (paymentMethod === 'credit_card') return 'Tarjeta débito/crédito'

  if (paymentMethod === 'pse') return 'PSE'

  if (paymentMethod === 'automatic_debit') return 'Débito automático'

  return ''
}
