import { Link } from '@mui/material'

export default {
  general_incapacity_payment:
    'Configura cómo vas a pagar la incapacidad general.',
  pay_holidays_31: (
    <>
      Puedes escoger si pagas o no el día 31 en vacaciones disfrutadas. Puedes
      leer más al respecto{' '}
      <Link
        target="_blank"
        color="accent4.main"
        fontWeight={600}
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/configura-el-día-31-para-el-pago-de-vacaciones-de-tus-empleados-con-aleluya"
      >
        aquí
      </Link>
      .
    </>
  ),
  proportional_deductions:
    'Las deducciones (no retenciones) de un período que tenga vacaciones quieres que se paguen al final del periodo o proporcional en cada pago.',
  transport_subsidy_per_period:
    'Escoge si el subsidio de transporte se debe pagar cada quincena o una vez al mes',
  transport_subsidy_base: (
    <>
      Escoge si se toma como base para el subsidio de transporte solo el salario
      o salario más ingresos salariales adicionales (sin incluir horas extras o
      recargos). Puedes leer más al respecto{' '}
      <Link
        target="_blank"
        color="accent4.main"
        fontWeight={600}
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-por-qué-se-desactiva-el-auxilio-de-transporte-de-un-empleado"
      >
        aquí
      </Link>
      .
    </>
  ),
  worker_pension_unpaid_license:
    'El pago de pensión en licencias no remuneradas (Ausencia injustificada, suspensión o licencia no remunerada) se hace solo con el porcentaje que corresponde a la empresa o se incluye tambien el porcentaje del empleado',
  benefits_credits:
    'Se han añadido unos beneficios para los empleados con este van a lograr adquirir un crédito de libranza',
  electronic_payroll_social_benefit: (
    <>
      Elige si en la emisión de tu Nómina Electrónica quieres incluir las
      provisiones de las prestaciones sociales o no.{' '}
      <Link
        target="_blank"
        color="accent4.main"
        fontWeight={600}
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-bajo-que-conceptos-normativos-se-emite-la-nómina-electrónica-desde-aleluya-a-la-dian"
      >
        Puedes leer más aquí
      </Link>
    </>
  ),
  electronic_payroll_holidays: (
    <>
      Elige si en la emisión de tu Nómina Electrónica quieres incluir las
      vacaciones o no.{' '}
      <Link
        target="_blank"
        color="accent4.main"
        fontWeight={600}
        href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-las-tres-formas-de-emitir-vacaciones-en-tu-nómina-electrónica"
      >
        Puedes leer más aquí
      </Link>
    </>
  ),
  pay_february_extra_days_if_hired_in_month:
    'Si la persona empieza contrato en febrero escoge si se paga el mes de febrero como si fuera de 30 días o con los días reales',
}
