import { Formik } from 'formik'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useBeneficiariesService from 'utils/hooks/affiliations/beneficiaries'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import Actions from './Actions'
import {
  attachDocuments,
  getAttachedDocuments,
  kinshipLabel,
  remainKinshipOptions,
  validationSchema,
} from './helpers'

const EditBeneficiary = ({
  worker,
  beneficiaries,
  beneficiary,
  affiliationQueryKey,
}) => {
  const queryClient = useQueryClient()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()

  const [showEditBeneficiary, setShowEditBeneficiary] = useState(false)
  const [resetBeneficiaryFiles, setResetBeneficiaryFiles] = useState(false)

  const confirm = useConfirm()
  const downloadURI = useDownloadURI()

  const { beneficiariesMutation } = useBeneficiariesService()

  const beneficiaryManagement = (managementType, beneficiaryData) => {
    const data = new FormData()

    if (managementType === 'update') {
      const { oldData, newData } = beneficiaryData

      const dirtyValues = getDirtyValues(
        oldData,
        newData,
        validationSchema.fields
      )

      if (isObjectEmpty(dirtyValues)) {
        setShowEditBeneficiary(false)
        return {}
      }

      data.append('beneficiary_id', oldData.id)
      data.append('name', dirtyValues.name || oldData.name)
      data.append('kinship', dirtyValues.kinship || oldData.kinship)

      if (dirtyValues.files && Object.keys(dirtyValues.files).length > 0) {
        Object.entries(dirtyValues.files).forEach(([key, value]) => {
          if (value instanceof File) {
            data.append(key.slice(0, -5), value)
          }
          if (value === null) {
            data.append(key.slice(0, -5), '')
          }
        })
      }
    }

    if (managementType === 'delete') {
      const { id } = beneficiaryData
      data.append('beneficiary_id', id)
    }

    showLoadingModal()

    beneficiariesMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: worker.id,
        beneficiaryData: data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          setShowEditBeneficiary(false)
          setResetBeneficiaryFiles(false)
          hideLoadingModal()
        },
        onError: () => {
          hideLoadingModal()
        },
      }
    )
    return null
  }

  const onDeleteBeneficiary = (id) => {
    beneficiaryManagement('delete', { id })
  }

  const onUpdateBeneficiary = (currentBeneficiary) => {
    const oldData = beneficiary

    const newData = currentBeneficiary

    beneficiaryManagement('update', { oldData, newData })
  }

  const onSubmit = (values) => {
    onUpdateBeneficiary(values)
  }

  return (
    <Formik
      initialValues={beneficiary}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, submitForm, resetForm }) => {
        const handleChangeKinship = ({ target: { value } }) => {
          setFieldValue('kinship', value)
          setResetBeneficiaryFiles(true)
          setFieldValue('files', {})
        }

        return (
          <Box
            sx={(theme) => ({
              display: 'grid',
              columnGap: theme.spacing(3),
              marginTop: theme.spacing(2.5),
              gridTemplateColumns: '1fr 5rem',
            })}
          >
            {showEditBeneficiary ? (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  <FormField
                    name="name"
                    label="Nombre completo"
                    optional={false}
                  />
                  <FormField
                    name="kinship"
                    label="Tipo de beneficiario"
                    variant="select"
                    placeholder="Selecciona"
                    onChange={(e) => handleChangeKinship(e)}
                    options={remainKinshipOptions(
                      beneficiaries,
                      beneficiary.kinship
                    )}
                    optional={false}
                  />
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Confirmar',
                    icon: <Icon name="approve-checked" basic />,
                    onClick: submitForm,
                  }}
                  rightAction={{
                    tooltip: 'Cancelar',
                    icon: <Icon name="close-2" basic />,
                    onClick: () => {
                      setShowEditBeneficiary(false)
                      setResetBeneficiaryFiles(false)
                      resetForm()
                    },
                  }}
                />
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridColumn: '1 / -1',
                    gridTemplateColumns: '1fr',
                    columnGap: theme.spacing(3),
                    rowGap: theme.spacing(3),
                    marginTop: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                      paddingRight: '6.5rem',
                    },
                  })}
                >
                  {attachDocuments({
                    kinship: resetBeneficiaryFiles
                      ? values.kinship
                      : beneficiary.kinship,
                    files: beneficiary.files,
                  })}
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead1">Nombre completo</Typography>
                    <Typography variant="h6" color="black.dark">
                      {beneficiary.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography variant="lead1">
                      Tipo de beneficiario
                    </Typography>
                    <Typography variant="h6" color="black.dark">
                      {kinshipLabel[beneficiary.kinship]}
                    </Typography>
                  </Box>
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Editar',
                    icon: <Icon name="edit-pencil" basic />,
                    onClick: () => setShowEditBeneficiary(true),
                  }}
                  rightAction={{
                    tooltip: 'Eliminar',
                    icon: <Icon name="trash" basic />,
                    onClick: () =>
                      confirm({
                        okText: 'Sí',
                        cancelText: 'No',
                        type: 'warning',
                        title:
                          '¿Estás seguro de que deseas eliminar a este beneficiario?',
                        onOk: () => onDeleteBeneficiary(beneficiary.id),
                      }),
                  }}
                />
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridColumn: '1 / -1',
                    gridTemplateColumns: '1fr',
                    columnGap: theme.spacing(3),
                    rowGap: theme.spacing(3),
                    marginTop: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  {getAttachedDocuments({
                    kinship: beneficiary.kinship,
                    files: beneficiary.files,
                    downloadURI,
                  })}
                </Box>
              </>
            )}
          </Box>
        )
      }}
    </Formik>
  )
}

export default EditBeneficiary
