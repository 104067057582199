import { subYears } from 'date-fns'
import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import HealthProviderField from 'components/Worker/Contract/Fields/HealthProvider'
import PensionProviderField from 'components/Worker/Contract/Fields/PensionProvider'
import SeveranceProviderField from 'components/Worker/Contract/Fields/SeveranceProvider'

import { formatDate } from 'utils/dateTime'

const FormFieldsPersonal = () => {
  const {
    values,
    values: {
      contract_category: contractCategory,
      document_type: documentType,
    },
  } = useFormikContext()

  const maxDateBirthDay = formatDate(subYears(new Date(), 15))

  return (
    <>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        Datos personales
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          },
        })}
      >
        <FormField
          name="birthday"
          label="Fecha de nacimiento"
          variant="datepicker"
          maxDate={maxDateBirthDay}
          optional
        />
        <FormField name="address" label="Dirección del hogar" optional />
        <FormField name="phone" label="Número celular" optional={false} />
      </Box>

      {contractCategory &&
      contractCategory !== 'contractor' &&
      contractCategory !== 'student_decree_055' ? (
        <>
          <Typography
            variant="h6"
            color="primary.dark"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
            })}
          >
            Administradoras de seguridad social
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              columnGap: theme.spacing(3),
              [theme.breakpoints.up('tablet')]: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              },
            })}
          >
            <HealthProviderField
              contractCategory={values.contract_category}
              optional={false}
            />
            <PensionProviderField
              documentType={documentType}
              contractCategory={values.contract_category}
              contributorSubtype={values.contributor_subtype}
              optional={false}
            />
            <SeveranceProviderField
              wageCategory={values.wage_category}
              contractCategory={values.contract_category}
              optional={false}
            />
          </Box>
        </>
      ) : null}
    </>
  )
}

export default FormFieldsPersonal
