import { useFormikContext } from 'formik'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import { Box, Button, Menu, MenuItem, useTheme } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Icon from 'components/UI/Icon'
import ToolbarContainer from 'components/UI/Table/Toolbar/ToolbarContainer'
import ToolbarSearch from 'components/UI/Table/Toolbar/ToolbarSearch'

import useDecree376Service from 'utils/hooks/payroll/decree376Service'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

import { Decree376Context } from '.'

const Toolbar = (props) => {
  const themeInstance = useTheme()
  const { setGlobalFilter, globalFilter, rows } = props
  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()
  const downloadFile = useEnqueueDownload()
  const { decree376Mutation } = useDecree376Service({
    queryOptions: { enabled: false },
  })
  const { periodId, decree376Id } = useContext(Decree376Context)

  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext()
  let someSelected = false
  let somePaid = false

  rows?.map((row) => {
    if (!someSelected && row.isSelected) {
      someSelected = true
    }
    if (!somePaid && row.original.paid_in_period) {
      somePaid = true
    }
    return undefined
  })

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'massiveActionsMenu',
  })

  const disabledButtonOptions = values.decree376_payments.every(
    (payment) => payment.disableCheck
  )
  const setMassiveAction = (option) => {
    values.decree376_payments.forEach((payment, index) => {
      if (payment.payment_options.includes(option) && payment.selection)
        setFieldValue(`decree376_payments[${index}].paid_in_period`, option)
    })
    popupState.close()
  }

  const handleDownloadSummaryFile = async () => {
    if (showPremiumFeatures) {
      decree376Mutation.mutate(
        {
          mutationMethod: 'GET',
          mutationKey: 'getDecree376File',
          params: { periodId, decreePeriodId: decree376Id },
        },
        {
          onSuccess: () => {
            return downloadFile({
              name: 'resumen de pago decreto 376',
              fileCode: 'decree_summary',
              pathname: `periods/${periodId}/decree376/payments_file/${decree376Id}`,
            })
          },
        }
      )
    } else openPremiumFeatureModal()
  }

  return (
    <ToolbarContainer
      {...props}
      sx={{
        padding: `${themeInstance.spacing(0, 0, 2, 0)} !important`,
        flexWrap: 'nowrap',
        gap: themeInstance.spacing(2),
        width: '100%',
        '& > *': {
          margin: '0 !important',
        },
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: '100%',
          flexDirection: 'column',
          [theme.breakpoints.up('tablet')]: {
            width: 'auto',
            flexDirection: 'row',
            flexWrap: 'wrap',
          },
        })}
      >
        <ToolbarSearch
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          gap: theme.spacing(2),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            width: 'auto',
            flexDirection: 'row',
          },
          [theme.breakpoints.down('tablet')]: {
            '& > *': {
              width: '100%',
            },
          },
        })}
      >
        <Button
          onClick={handleDownloadSummaryFile}
          variant="outlined"
          startIcon={<Icon name="download" basic />}
          endIcon={<PremiumFeatureIcon />}
        >
          Descargar resumen
        </Button>
        <>
          <Button
            variant="outlined"
            endIcon={<Icon name="arrow-down" basic />}
            disabled={disabledButtonOptions}
            {...bindTrigger(popupState)}
          >
            Pago masivo
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => setMassiveAction('company_and_worker')}>
              Empresa y Empleado
            </MenuItem>
            <MenuItem onClick={() => setMassiveAction('company')}>
              Solo Empresa
            </MenuItem>
          </Menu>
        </>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || (!somePaid && !someSelected)}
          endIcon={<Icon name="pendrive" basic />}
        >
          Guardar
        </Button>
      </Box>
    </ToolbarContainer>
  )
}

const Decree376Toolbar = (props) => {
  return <Toolbar {...props} />
}

export default Decree376Toolbar
