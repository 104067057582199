import { forwardRef } from 'react'

import { CircularProgress, Button as MuiButton } from '@mui/material'

const Button = forwardRef(function ButtonForwardRef(
  { loading = false, disabled = false, children, ...rest },
  ref
) {
  return (
    <MuiButton
      ref={ref}
      disabled={disabled || loading}
      startIcon={
        loading ? <CircularProgress size={18} color="primary" /> : null
      }
      {...rest}
    >
      {children}
    </MuiButton>
  )
})

export default Button
