import { useState } from 'react'

const useUpdatePaymentMethodInfoModal = () => {
  const [state, setState] = useState({
    open: false,
    actionMessage: '',
    activePayment: '',
  })

  const openUpdatePaymentMethodInfoModal = (actionMessage, activePayment) => {
    setState({ open: true, actionMessage, activePayment })
  }

  const closeUpdatePaymentMethodInfoModal = () => {
    setState({ open: false })
  }

  return {
    updatePaymentMethodInfoModalState: state,
    openUpdatePaymentMethodInfoModal,
    closeUpdatePaymentMethodInfoModal,
  }
}

export default useUpdatePaymentMethodInfoModal
