import { useFormikContext } from 'formik'
import { useContext, useEffect, useState } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import AccountFields from 'components/Subscription/SubscriptionFormFields/Account'
import ClientTypeField from 'components/Subscription/SubscriptionFormFields/ClientType'
import CreditCardExpirationDate from 'components/Subscription/SubscriptionFormFields/CreditCardExpirationDate'
import CreditCardNumberField from 'components/Subscription/SubscriptionFormFields/CreditCardNumberField'
import BankAccountIdentificationNumber from 'components/UI/Formik/CommonFields/BankAccountHolderIdentificationNumber'
import BankField from 'components/UI/Formik/CommonFields/BankField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { documentTypesOptions } from 'utils/company'

import { SubscriptionViewContext } from '../Index'
import MethodAccordion from './MethodAccordion'
import {
  automaticDebitOptions,
  creditCardOptions,
  getHeaderTitle,
  getPaymentMethodLogoOptions,
  pseOptions,
} from './helpers'

const PaymentMethod = () => {
  const { subscription } = useSubscription()
  const { setFieldValue } = useFormikContext()

  const {
    activePayment,
    setActivePayment,
    setExpandedAccordion,
    expandedAccordion,
  } = useContext(SubscriptionViewContext)

  const { payment_methods: paymentMethods } = subscription || {}

  const {
    credit_card: showCreditCardMethod,
    automatic_debit: showAutomaticDebitMethod,
    pse: showPseMethod,
  } = paymentMethods || {}

  const [showCreditCard, setShowCreditCard] = useState(showCreditCardMethod)

  const noPaymentMethodsAvailable =
    Object.keys(paymentMethods || {}).length === 0

  const onlyOnePaymentMethod = Object.keys(paymentMethods || {}).length === 1

  const availablePaymentMethod = onlyOnePaymentMethod
    ? Object.keys(paymentMethods || {})[0] || 'credit_card'
    : ''

  const onExpandAccordion = (panel) => {
    setFieldValue('payment_method', panel)
    setExpandedAccordion(panel)
    setActivePayment(panel)
  }

  const paymentMethodLogos = getPaymentMethodLogoOptions(availablePaymentMethod)
  const headerTitle = getHeaderTitle(availablePaymentMethod)

  useEffect(() => {
    if (noPaymentMethodsAvailable) setShowCreditCard(true)

    if (onlyOnePaymentMethod && availablePaymentMethod !== 'credit_card') {
      setFieldValue('payment_method', availablePaymentMethod)
    }
  }, [
    availablePaymentMethod,
    noPaymentMethodsAvailable,
    onlyOnePaymentMethod,
    paymentMethods,
    setFieldValue,
  ])

  return (
    <Paper
      sx={(theme) => ({
        width: '100%',
        backgroundColor: 'white.main',
        padding: theme.spacing(3),
        borderRadius: '1rem',
      })}
    >
      {onlyOnePaymentMethod ? (
        <Box
          sx={(theme) => ({
            border: `0.125rem solid ${theme.palette.primary.main}`,
            borderRadius: '1rem',
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
          })}
        >
          <Box
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(2),
                gap: theme.spacing(2),
              })}
            >
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                })}
              >
                {headerTitle}
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'flex-end',

                  '& > img': {
                    marginRight: theme.spacing(2),
                  },
                  '& > img:last-child': {
                    marginRight: 0,
                  },
                })}
              >
                {paymentMethodLogos.map((logo) => (
                  <Box
                    component="img"
                    key={logo.id}
                    alt={`Logo ${logo.id}`}
                    src={logo.icon}
                    sx={{
                      width: logo.width,
                      height: logo.height,
                    }}
                  />
                ))}
              </Box>
            </Box>
            {showCreditCard ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <FormField
                  name="payer_name"
                  optional={false}
                  label="Nombre del titular"
                  inputProps={{ autoComplete: 'cc-name' }}
                />
                <CreditCardNumberField />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                    },
                  })}
                >
                  <FormField
                    name="security_code"
                    optional={false}
                    label="CVV"
                    variant="number"
                    placeholder="000"
                    format="####"
                    inputProps={{ autoComplete: 'cc-csc' }}
                  />
                  <CreditCardExpirationDate />
                </Box>
              </Box>
            ) : null}
            {showAutomaticDebitMethod ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <DocumentTypeField
                  options={documentTypesOptions}
                  optional={false}
                />
                <BankAccountIdentificationNumber />
                <BankField provider="druo" />
                <AccountFields />
              </Box>
            ) : null}
            {showPseMethod ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <ClientTypeField />
                <BankField />
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            marginBottom: theme.spacing(2),
            overflow: 'hidden',
          })}
        >
          {showCreditCard ? (
            <MethodAccordion
              id="credit_card"
              logos={creditCardOptions}
              title="Tarjeta débito/crédito"
              expanded={expandedAccordion}
              onChange={onExpandAccordion}
            >
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <FormField
                  name="payer_name"
                  optional={false}
                  label="Nombre del titular"
                  inputProps={{ autoComplete: 'cc-name' }}
                />
                <CreditCardNumberField />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                    },
                  })}
                >
                  <FormField
                    name="security_code"
                    optional={false}
                    label="CVV"
                    variant="number"
                    placeholder="000"
                    format="####"
                    inputProps={{ autoComplete: 'cc-csc' }}
                  />
                  <CreditCardExpirationDate />
                </Box>
              </Box>
            </MethodAccordion>
          ) : null}
          {showAutomaticDebitMethod ? (
            <MethodAccordion
              id="automatic_debit"
              title="Débito Automático"
              expanded={expandedAccordion}
              onChange={onExpandAccordion}
              logos={automaticDebitOptions}
            >
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <Typography variant="h6">
                  ¡Hey, ahora tu cobro se hará a través de DRUO, facilito y sin
                  gallos!
                </Typography>
                <DocumentTypeField
                  options={documentTypesOptions}
                  optional={false}
                />
                <BankAccountIdentificationNumber
                  disableValidate={activePayment !== 'automatic_debit'}
                />
                <BankField provider="druo" />
                <AccountFields />
              </Box>
            </MethodAccordion>
          ) : null}
          {showPseMethod ? (
            <MethodAccordion
              id="pse"
              title="PSE"
              expanded={expandedAccordion}
              onChange={onExpandAccordion}
              logos={pseOptions}
            >
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  width: '100%',
                  rowGap: theme.spacing(2.5),
                  gridTemplateColumns: '1fr',
                })}
              >
                <ClientTypeField />
                <BankField />
              </Box>
            </MethodAccordion>
          ) : null}
        </Box>
      )}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1.5rem auto',
          gridGap: theme.spacing(2),
          alignItems: 'center',
        })}
      >
        <Icon
          name="shield-security"
          basic
          sx={(theme) => ({
            fontSize: '2rem',
            color: theme.palette.primary.dark,
          })}
        />
        <Typography variant="body2">Pago 100% seguro</Typography>
      </Box>
    </Paper>
  )
}

export default PaymentMethod
