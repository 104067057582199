import { isValidElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Link, Paper, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'

import { DASHBOARD } from 'config/routes'

import ContainerDetail from './ContainerDetail'
import {
  getActionButtons,
  getContentResponse,
  getContentSummary,
} from './helpers'

const PaymentSummary = ({ summary }) => {
  const {
    response,
    paymentMethodInfo,
    activeUntil,
    value,
    type,
    name,
    plan,
    newPlan,
  } = summary

  const downLaptop = useMediaQuery((theme) => theme.breakpoints.down('laptop'))

  const { state, extraworkersPayment, extraworkersNumber, modalityChange } =
    response

  const { paymentStatus } = state

  const contentByStatus = getContentResponse({
    value,
    cardBrand: paymentMethodInfo?.card_brand,
    cardLastNumber: paymentMethodInfo?.card_last_numbers,
    paymentMethodInfo,
    subscriptionType: type,
    activeUntil,
    state: paymentStatus,
    extraworkersPayment,
  })

  const summaryByStatus = getContentSummary({
    state: paymentStatus,
    name,
    plan,
    extraworkersPayment,
    extraworkersNumber,
    modalityChange,
    downLaptop,
    newPlan,
  })

  const buttonsAction = getActionButtons(state, extraworkersPayment)
  return (
    <Paper
      sx={(theme) => ({
        marginTop: theme.spacing(3.75),
        padding: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(3),
        },
        [theme.breakpoints.down('lg')]: {
          marginTop: theme.spacing(4),
        },
        '& h3': {
          wordBreak: 'break-word',
        },
      })}
    >
      <Box>
        <Typography
          variant="h3"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          {summaryByStatus.heading}
        </Typography>
        <Typography variant="body1">{summaryByStatus.description}</Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '0.5fr 1fr',
          alignItems: 'center',
          justifyItems: 'center',
          [theme.breakpoints.down('laptop')]: {
            gridTemplateColumns: '1fr',
          },
          marginTop: theme.spacing(4),
        })}
      >
        {isValidElement(summaryByStatus.image) ? (
          summaryByStatus.image
        ) : (
          <Box
            sx={{
              ...summaryByStatus.imageConfig,
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={summaryByStatus.image}
              alt={`Summary response ${state.paymentStatus}`}
              loading="lazy"
            />
          </Box>
        )}
        <Box>
          {!extraworkersPayment ? (
            <ContainerDetail detail={contentByStatus.subscription} />
          ) : null}
          {contentByStatus.paymentDate && !extraworkersPayment ? (
            <ContainerDetail detail={contentByStatus.paymentDate} />
          ) : null}
          <ContainerDetail detail={contentByStatus.electronicReceipt} />
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(6.75),
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: theme.spacing(2),
            gridColumn: '1 / -1',
            '& a:last-child': {
              color: 'white.main',
              textDecoration: 'none',
            },
            [theme.breakpoints.down('laptop')]: {
              marginTop: theme.spacing(4.75),
            },
          })}
        >
          {paymentStatus !== 'DECLINED' ? (
            <Button component={RouterLink} to={DASHBOARD} variant="outlined">
              Ir a Aleluya
            </Button>
          ) : null}
          <Button
            component={paymentStatus === 'DECLINED' ? RouterLink : Link}
            to={buttonsAction.primary.to}
            href={buttonsAction.primary.href}
            target={buttonsAction.primary.target}
          >
            {buttonsAction.primary.text}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default PaymentSummary
