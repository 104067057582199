import { useLocation, useNavigate } from 'react-router-dom'

import useModals from 'components/App/ModalsManager/useModals'

import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import PaymentModal from './PaymentModal'

const usePaymentModal = () => {
  const modals = useModals()
  const navigate = useNavigate()
  const location = useLocation()
  const companyId = getCompanyId()

  const handleClose = (successPayment = false) => {
    modals.closeAll()
    if (location.pathname === '/workers/new' && !successPayment) {
      navigate(-1)
    }
  }

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
  })

  const currentPlan =
    plansQuery?.data?.plans?.find((plan) => plan.current_plan) || {}
  const handlePreviousModal = () => {
    modals.closeModal('paymentMethod')
  }

  const openModal = ({
    extraWorkers = null,
    isFromExtraWorkersModal = false,
    isEditingPaymentMethod = false,
    isFromUpdateMethodInfo = false,
    openUpdatePaymentMethodInfoModal,
  }) => {
    modals.openModal({
      id: 'paymentMethod',
      content: (
        <PaymentModal
          handleClose={handleClose}
          currentPlan={currentPlan}
          extraWorkersEdit={extraWorkers}
          handlePreviousModal={handlePreviousModal}
          isFromExtraWorkersModal={isFromExtraWorkersModal}
          isEditingPaymentMethod={isEditingPaymentMethod}
          isFromUpdateMethodInfo={isFromUpdateMethodInfo}
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      ),
      modalProps: {
        header: 'Método de pago',
        onCloseModal: handleClose,
        hideFooter: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  return { openModal }
}

export default usePaymentModal
