import { Box, Button, Card, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import EventItem from './EventItem'
import { getDataConfig } from './helpers'

const EventCard = ({ eventType, data, isLoading, handleShowDetail }) => {
  const sizeData = data?.length

  const { title, shortTitle, description, image, icon } = getDataConfig(
    eventType,
    sizeData
  )

  const handleShowMore = () => {
    handleShowDetail({
      title,
      description,
      items: data,
      eventType,
    })
  }

  return (
    <Card
      sx={(theme) => ({
        minHeight: '19rem',
        padding: theme.spacing(1.5, 0, 1, 0),
        borderRadius: '1rem',
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
            justifyContent: 'center',
            marginBottom: theme.spacing(1),
          })}
        >
          {icon}
          <Typography>{shortTitle}</Typography>
        </Box>
        <Typography
          variant="small"
          color="black.dark"
          sx={(theme) => ({
            padding: theme.spacing(0, 1.5),
            marginBottom: theme.spacing(0.5),
            ...(sizeData > 0 && {
              marginBottom: theme.spacing(1.5),
            }),
          })}
        >
          {description}
        </Typography>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Box
            sx={{
              height: '100%',
              ...(sizeData === 0 && {
                display: 'flex',
                alignItems: 'center',
              }),
            }}
          >
            {sizeData > 0 ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2.5),
                  width: '100%',
                  padding: theme.spacing(0, 1),
                })}
              >
                {data?.slice(0, 3)?.map((item, index) => {
                  const avatarId = (index % 5) + 1
                  const key = `${eventType}-${index}`

                  return (
                    <EventItem
                      key={key}
                      eventType={eventType}
                      name={item.worker_name}
                      date={item.date}
                      years={item.years}
                      workerId={item.workerId}
                      avatarId={avatarId}
                      picture={item.picture}
                    />
                  )
                })}
              </Box>
            ) : (
              <Box
                component="img"
                src={image}
                sx={(theme) => ({
                  background: theme.palette.white.main,
                  width: '100%',
                  objectFit: 'contain',
                })}
              />
            )}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="text"
            startIcon={<Icon name="plus" basic />}
            sx={{
              width: 'fit-content',
            }}
            onClick={handleShowMore}
          >
            Ver más
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default EventCard
