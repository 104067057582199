import cardValidator from 'card-validator'
import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import { useCallback, useContext, useMemo, useRef } from 'react'
import * as yup from 'yup'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  alpha,
} from '@mui/material'
import { Link } from '@mui/material'

import Icon from 'components/UI/Icon'

import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { SubscriptionViewContext } from './Index'

export const getDetailCard = (plansData, themeInstance) => {
  const description = {
    premium_per_worker_plan:
      'Liquida tu nómina y seguridad social en segundos de forma automática y sin errores.',
    premium_hr_per_worker_plan:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
    pro_per_worker_plan:
      'Nómina y Recursos Humanos en un solo lugar. Gestiona las personas de tu equipo de la forma más eficiente.',
  }

  const color = {
    premium_per_worker_plan: 'complementary1.dark',
    premium_hr_per_worker_plan: 'primary.dark',
    pro_per_worker_plan: 'accent4.dark',
  }

  const adminDescription = {
    premium_per_worker_plan: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    premium_hr_per_worker_plan: (
      <span>
        Ilimitados <br /> Soporte por chat en línea y en menos de una hora.
      </span>
    ),
    pro_per_worker_plan: (
      <span>
        Ilimitados <br />
        Ejecutivo de cuenta y soporte prioritario.
      </span>
    ),
  }

  const icon = {
    premium_per_worker_plan: (
      <Icon
        name="shop"
        basic
        color={themeInstance.palette.complementary1.dark}
        fontSize="large"
      />
    ),
    premium_hr_per_worker_plan: (
      <Icon name="building" twoTone fontSize="large" />
    ),
    pro_per_worker_plan: (
      <Icon
        name="two-buildings"
        basic
        color={themeInstance.palette.accent4.dark}
        fontSize="large"
      />
    ),
  }

  const wrapperColor = {
    premium_per_worker_plan: themeInstance.palette.complementary1.dark,
    premium_hr_per_worker_plan: themeInstance.palette.primary.dark,
    pro_per_worker_plan: themeInstance.palette.accent4.dark,
  }

  const chipBackgroundColor = {
    premium_per_worker_plan: alpha(
      themeInstance.palette.complementary1.main,
      0.2
    ),
    premium_hr_per_worker_plan: alpha(themeInstance.palette.primary.main, 0.2),
    pro_per_worker_plan: alpha(themeInstance.palette.accent4.main, 0.2),
  }

  const data = plansData?.map((planData) => {
    const codedName = removeSuffix(planData?.coded_name, -8)
    const cardsStyles = {
      adminDescription: adminDescription[codedName],
      chipBackgroundColor: chipBackgroundColor[codedName],
      color: color[codedName],
      description: description[codedName],
      icon: icon[codedName],
      wrapperColor: wrapperColor[codedName],
    }
    return {
      planData,
      cardsStyles,
      codedName,
    }
  })

  return data
}

export const getInitialValues = (planId) => {
  const values = {
    modality: null,
    plan: planId,
    workers_number: 1,
    isPartner: false,
    payment_method: 'credit_card',
    client_type: '',
    bank: null,
    account_type: null,
    account_number: null,
    payer_name: null,
    card_number: null,
    security_code: null,
    expiration_date: null,
    terms_accepted: false,
  }

  return values
}

export const getValidationSchema = () =>
  yup.object({
    modality: yup.string().nullable(),
    plan: yup.string().nullable(),
    workers_number: yup.number().when(['isPartner'], {
      is: (isPartner) => !isPartner,
      then: yup
        .number()
        .nullable()
        .required('Debes ingresar la cantidad de personas'),
      otherwise: yup.number().nullable(),
    }),
    payment_method: yup.string().nullable().required(),
    payer_name: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup.string().trim().required(),
        otherwise: yup.string(),
      }),
    card_number: yup
      .number()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .number()
          .test(
            'test-number',
            'Número de tarjeta de crédito inválido',
            (value) => cardValidator.number(value).isValid
          ),
        otherwise: yup.number(),
      }),
    expiration_date: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .string()
          .trim()
          .test(
            'test-expiration-year',
            'Debe ser una fecha válida',
            (value) => {
              return cardValidator.expirationDate(value).isValid
            }
          ),
        otherwise: yup.string(),
      }),
    security_code: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'credit_card',
        then: yup
          .string()
          .trim()
          .test(
            'test-number',
            'Código de seguridad inválido',
            // eslint-disable-next-line func-names
            function (value) {
              const type = cardValidator.number(this.parent.card_number).card
                ?.type

              return cardValidator.cvv(
                `${value}`,
                type === 'american-express' ? 4 : 3
              ).isValid
            }
          ),
        otherwise: yup.string(),
      }),
    client_type: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'pse',
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    document_type: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'automatic_debit',
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    id_number: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'automatic_debit',
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    bank: yup
      .object()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['pse', 'automatic_debit'].includes(paymentMethod),
        then: yup.object().required(),
        otherwise: yup.object(),
      }),
    account_type: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'automatic_debit',
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    account_number: yup
      .string()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) => paymentMethod === 'automatic_debit',
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    terms_accepted: yup
      .boolean()
      .nullable()
      .when(['payment_method'], {
        is: (paymentMethod) =>
          ['credit_card', 'automatic_debit'].includes(paymentMethod),
        then: yup
          .boolean()
          .test(
            'test-recurrent-payment-acceptance',
            'Debes aceptar el cobro recurrente para usar este método de pago',
            (value) => value
          ),
        otherwise: yup.boolean(),
      }),
  })

const debouncedHandler = debounce(
  (values, callback, handler) => handler(values, callback),
  800
)

export const useCalculateSubscription = () => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateSubscriptionPrice',
    },
  })
  const { setFieldValue } = useFormikContext()
  const { subscription } = useContext(SubscriptionViewContext)
  const isClaraPayment = subscription?.payment_category === 'clara_payment'

  const calculateSubscription = useCallback(
    (values, callback) => {
      if (isClaraPayment && Number(values?.extraWorkers) < 20) {
        setFieldValue('workers_number', 20)
        return
      }

      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          ...values,
        },
        {
          onSuccess: ({ data }) => {
            callback(data)
          },
        }
      )
    },
    [isClaraPayment, setFieldValue, subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) =>
      debouncedHandler(values, callback, calculateSubscription),
    [calculateSubscription]
  )

  return {
    calculateSubscription,
    calculateSubscriptionFromInput,
  }
}

export const useCalculateExtraWorkers = (getImmediatePayment) => {
  const { subscriptionMutation } = useSubscriptionService({
    mutationOptions: {
      mutationKey: 'calculateExtraWorkers',
    },
  })

  const calculateSubscription = useCallback(
    ({ values, newPlanId, subscriptionType, workersNumber }, callback) => {
      const getMutationKey = () => {
        if (getImmediatePayment === undefined) return undefined
        if (getImmediatePayment) return 'getImmediatePaymentValue'
        return 'getNextCyclePaymentValue'
      }
      if (!values && getImmediatePayment === undefined) return
      subscriptionMutation.mutate(
        {
          mutationMethod: 'GET',
          extraWorkers: values,
          newPlanId,
          subscriptionType,
          mutationKey: getMutationKey(),
          workersNumber,
        },
        {
          onSuccess: ({ data }) => {
            callback(data)
          },
        }
      )
    },
    [getImmediatePayment, subscriptionMutation]
  )

  const calculateSubscriptionFromInput = useMemo(
    () => (values, callback) => {
      debouncedHandler(values, callback, calculateSubscription)
    },
    [calculateSubscription]
  )

  return {
    calculateSubscriptionFromInput,
  }
}

export const useSelectPlan = () => {
  const { setFieldValue } = useFormikContext()
  const firstInteraction = useRef(false)
  const { selectedPlan, onSelectPlan, modality } = useContext(
    SubscriptionViewContext
  )
  const { calculateSubscriptionFromInput } = useCalculateSubscription()

  const selectBasePlan = useCallback(
    (plan) => {
      onSelectPlan({ ...plan, payment_type: modality })
    },
    [onSelectPlan, modality]
  )

  const triggerCalculateSubscription = (workersNumber) => {
    if (Number(workersNumber)) {
      calculateSubscriptionFromInput(
        {
          planId: selectedPlan?.id,
          extraWorkers: workersNumber,
        },
        selectBasePlan
      )
    }
  }

  const onChangeWorkersNumber = (value) => {
    const { target: { value: inputValue } = {} } = value
    const currentValue = ['string', 'number'].includes(typeof value)
      ? value
      : inputValue

    if (!firstInteraction.current) firstInteraction.current = true
    const positiveValue = Math.abs(currentValue)
    setFieldValue('workers_number', positiveValue)
    triggerCalculateSubscription(positiveValue)
  }

  return {
    onChangeWorkersNumber,
    firstInteraction: firstInteraction.current,
  }
}

export const getPaymentData = (
  values,
  paymentMethod,
  planId,
  subscriptionType,
  paidWorkers,
  isPartner,
  extraWorkers,
  subscriptionChange,
  newSubscriptionType,
  currentPlanPayment,
  isPremiumExpiredSubscription,
  activeWorkers,
  isEditingPaymentMethod,
  isCurrentMethodPayment
) => {
  const paymentData = {}

  const {
    expiration_date: expirationDate,
    terms_accepted: termsAccepted,
    card_number: cardNumber,
    security_code: securityCode,
    payer_name: payerName,
    client_type: clientType,
    document_type: documentType,
    id_number: idNumber,
    account_type: accountType,
    account_number: accountNumber,
    bank,
  } = values

  paymentData.terms_accepted = termsAccepted

  paymentData.payment_type = isPremiumExpiredSubscription
    ? newSubscriptionType
    : subscriptionType

  paymentData.paid_workers =
    currentPlanPayment || isPremiumExpiredSubscription
      ? activeWorkers + extraWorkers
      : paidWorkers

  if (isEditingPaymentMethod) paymentData.action = 'update'

  if (isCurrentMethodPayment) paymentData.action = 'reuse'

  if (paymentMethod === 'credit_card') {
    paymentData.card_number = cardNumber
    paymentData.security_code = securityCode
    paymentData.payer_name = payerName

    paymentData.expiration_date = expirationDate
      ? `${expirationDate?.substring(2)}/${expirationDate?.substring(0, 2)}`
      : undefined // MMYYYY => YYYY/MM
  }

  if (paymentMethod === 'pse') {
    paymentData.bank = bank?.id
    paymentData.client_type = clientType
  }

  if (paymentMethod === 'automatic_debit') {
    paymentData.document_type = documentType
    paymentData.id_number = idNumber
    paymentData.account_type = accountType
    paymentData.account_number = accountNumber
    paymentData.bank = bank?.id
  }

  if (
    !isPartner &&
    (!subscriptionChange || currentPlanPayment || isPremiumExpiredSubscription)
  ) {
    paymentData.plan_id = planId
  }

  if (isPartner) {
    paymentData.payment_type = 'month'
  }

  if (
    extraWorkers >= 0 &&
    !currentPlanPayment &&
    !isPremiumExpiredSubscription
  ) {
    paymentData.extra_workers_number = extraWorkers
  }

  if (
    subscriptionChange &&
    !currentPlanPayment &&
    !isPremiumExpiredSubscription
  ) {
    paymentData.subscription_to_pay = {
      subscription_type: newSubscriptionType,
      plan_id: planId,
    }
  }

  return paymentData
}

const questions = [
  {
    title: '¿Cómo funciona la prueba de 15 días?',
    description:
      'Durante 15 días podrás utilizar Aleluya con todas sus funciones completamente gratis, crear una cuenta solo toma minutos y transcurridos los 15 días puedes decidir si deseas continuar o no pagando.',
  },
  {
    title: '¿Debo firmar alguna cláusula de permanencia mínima?',
    description:
      '¡No! Con Aleluya puedes cancelar tu suscripción en el momento que desees.',
  },
  {
    title: '¿Requiero de alguna capacitación para comenzar?',
    description:
      'Hemos hecho de Aleluya algo tan simple, que no requieres capacitación. Desde que te registras, nuestro equipo se comunicara contigo para ayudarte en todo el proceso y en caso de cualquier inquietud o capacitación siempre podrás contar con nosotros sin ningún costo.',
  },
  {
    title: '¿Hay algún costo extra u oculto?',
    description:
      '¡No! En Aleluya no pagas por absolutamente nada más. Todas las funciones, el soporte y las actualizaciones están incluidas.',
  },
  {
    title: '¿Qué pasa si mi número de empleados cambia?',
    description:
      'El pago de tu suscripción a Aleluya dependerá del número de personas que hayas liquidado en tu nómina en el período anterior (en el mes anterior o en las 2 quincenas anteriores). En este número se tendrán en cuenta todos los empleados o contratistas activos y aquellos a quienes les hayas realizado una liquidación laboral.',
  },
  {
    title: '¿Cómo realiza Aleluya el cobro de mi suscripción?',
    description: (
      <>
        El pago de tu suscripción a Aleluya dependerá del número de personas que
        hayas liquidado en tu nómina en el mes anterior (o en las 2 quincenas
        anteriores). En estas personas se tendrán en cuenta todos los empleados
        o contratistas activos y aquellos a quienes les hayas realizado una
        liquidación laboral. No te preocupes por las personas que se encuentren
        inactivas, que sobre ellas nunca se realizará ningún cobro. Conoce más
        al respecto en{' '}
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-aleluya-realiza-el-cobro-de-tu-plan"
          target="_blank"
          underline="hover"
        >
          este artículo en nuestro centro de ayuda
        </Link>
        .
      </>
    ),
  },
]

export const FrequentQuestions = ({ forwardedRef, filterIndexes = [] }) => {
  return (
    <Box ref={forwardedRef}>
      <Typography
        variant="h4"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          color: 'black.main',
        })}
      >
        Preguntas frecuentes
      </Typography>

      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            '& > Box': {
              maxWidth: '100%',
            },
          },
        })}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: '1.5rem',
              overflow: 'hidden',
            }}
          >
            {questions
              ?.filter(
                (_, questionIndex) => !filterIndexes.includes(questionIndex)
              )
              ?.map((question) => (
                <Accordion key={question.title}>
                  <AccordionSummary
                    expandIcon={<Icon name="arrow-down" basic />}
                  >
                    <Typography>{question.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{question.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
