import * as yup from 'yup'

const MAX_FILE_SIZE = 1000000
const MIN_FILE_SIZE = 0
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg']

export const getCompanyConfigurationInitialValues = (companyConfigData) => ({
  // Payment configuration
  general_incapacity_payment: companyConfigData.general_incapacity_payment,
  proportional_deductions: companyConfigData.proportional_deductions,
  transport_subsidy_per_period: companyConfigData.transport_subsidy_per_period,
  transport_subsidy_base: companyConfigData.transport_subsidy_base,
  pay_holidays_31: companyConfigData.pay_holidays_31,
  worker_pension_unpaid_license:
    companyConfigData.worker_pension_unpaid_license,
  pay_february_extra_days_if_hired_in_month:
    companyConfigData.pay_february_extra_days_if_hired_in_month,
  // Edit period history
  edit_periods: companyConfigData.edit_periods,
  // UVT configuration
  uvt_configuration: companyConfigData.uvt_configuration,
  // Certificate signature
  signature_image:
    companyConfigData.labor_certificate_signature?.signature_image,
  signee_name: companyConfigData.labor_certificate_signature?.signee_name,
  signee_id_number:
    companyConfigData.labor_certificate_signature?.signee_id_number,
  signee_document_type:
    companyConfigData.labor_certificate_signature?.signee_document_type,
  signee_position:
    companyConfigData.labor_certificate_signature?.signee_position,
  signee_email: companyConfigData.labor_certificate_signature?.signee_email,
  signee_phone: companyConfigData.labor_certificate_signature?.signee_phone,
  signature_in_labor_certificate:
    companyConfigData.signature_in_labor_certificate,
  signature_in_payslip: companyConfigData.signature_in_payslip,
})

export const validationSchema = yup.object({
  general_incapacity_payment: yup.string().nullable().required(),
  pay_holidays_31: yup.string().nullable().required(),
  proportional_deductions: yup.string().nullable().required(),
  transport_subsidy_per_period: yup.string().nullable().required(),
  transport_subsidy_base: yup.string().nullable().required(),
  worker_pension_unpaid_license: yup.string().nullable().required(),
  pay_february_extra_days_if_hired_in_month: yup.string().nullable().required(),
  edit_periods: yup.boolean().required(),
  uvt_configuration: yup.string().required(),
  signature_image: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El tamaño del archivo no debe superar 1 MB.',
      (value) => {
        if (!value) return true

        if (value instanceof File) {
          return value.size <= MAX_FILE_SIZE
        }

        return true
      }
    )
    .test(
      'fileSize',
      '¡No pudimos cargar la imagen! El archivo que intentas subir no tiene contenido o se encuentra dañado. Intenta con uno nuevo.',
      (value) => {
        if (!value) return true

        if (value instanceof File) {
          return value.size > MIN_FILE_SIZE
        }

        return true
      }
    )
    .test('fileType', 'Formato de imagen no soportado.', (value) => {
      if (!value) return true

      if (value instanceof File) {
        return SUPPORTED_FORMATS.includes(value.type)
      }

      return true
    }),
  signee_name: yup
    .string()
    .nullable()
    .when('signature_image', {
      is: (signatureImage) => signatureImage,
      then: yup.string().nullable().required(),
    }),
  signee_id_number: yup.string().nullable(),
  signee_document_type: yup
    .string()
    .nullable()
    .when('signee_id_number', {
      is: (signeeIdNumber) => signeeIdNumber,
      then: yup.string().nullable().required(),
    }),
  signee_position: yup
    .string()
    .nullable()
    .when('signature_image', {
      is: (signatureImage) => signatureImage,
      then: yup.string().nullable().required(),
    }),
  signee_email: yup
    .string()
    .nullable()
    .email()
    .when('signature_image', {
      is: (signatureImage) => signatureImage,
      then: yup.string().nullable().email().required(),
    }),
  signee_phone: yup.string().nullable().min(10).max(10),
})
