import { parseDate } from '@internationalized/date'
import { useDatePicker } from '@react-aria/datepicker'
import { useDatePickerState } from '@react-stately/datepicker'
import { captureMessage } from '@sentry/react'
import { isAfter, parseISO } from 'date-fns'
import { useRef, useState } from 'react'

import { Box } from '@mui/material'

import Calendar from './Calendar'
import CalendarContainer from './CalendarContainer'
import DateField from './DateField'

const RangeField = ({
  name,
  placeholder,
  value,
  disabled,
  id,
  dataCy,
  tooltipTitle,
  tooltipDescription,
  position,
  minValue,
  maxValue,
  focusedValue,
  isDateUnavailable,
  ...props
}) => {
  const currentValue = value ? parseDate(value) : null

  if (minValue && maxValue && isAfter(parseISO(minValue), parseISO(maxValue))) {
    captureMessage(
      `[Payroll/DatePicker]Error: minValue: ${minValue} cannot be greater than maxValue: ${maxValue}`
    )
    // eslint-disable-next-line no-param-reassign
    minValue = null
    // eslint-disable-next-line no-param-reassign
    maxValue = null
  }

  const currentMinValue = minValue ? parseDate(minValue) : null
  const currentMaxValue = maxValue ? parseDate(maxValue) : null
  const [anchorEl, setAnchorEl] = useState(null)
  const state = useDatePickerState({
    ...props,
    isDisabled: disabled,
    value: currentValue,
    minValue: currentMinValue,
    maxValue: currentMaxValue,
    isDateUnavailable,
  })
  const ref = useRef()
  const { fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
    {
      ...props,
      'aria-label': `${name} range field`,
      isDisabled: disabled,
      value: currentValue,
      minValue: currentMinValue,
      maxValue: currentMaxValue,
      isDateUnavailable,
    },
    state,
    ref
  )

  const onClickInput = () => {
    if (disabled) return

    setAnchorEl(ref.current)
    buttonProps.onPress()
  }

  return (
    <>
      <Box ref={ref}>
        <DateField
          {...fieldProps}
          onClick={onClickInput}
          placeholder={placeholder}
          disabled={disabled}
          sx={{
            height: '2rem',
            width: '100%',
          }}
          id={id}
          dataCy={dataCy}
        />
      </Box>
      <CalendarContainer
        open={state.isOpen}
        onClose={() => state.setOpen(false)}
        anchorEl={anchorEl}
        dialogProps={dialogProps}
      >
        <Calendar
          {...calendarProps}
          onCloseCalendar={() => state.setOpen(false)}
          position={position}
          minValue={currentMinValue}
          maxValue={currentMaxValue}
          focusedValue={focusedValue}
        />
      </CalendarContainer>
    </>
  )
}

export default RangeField
