import { Box, CardContent, Typography } from '@mui/material'

const ContainerDetail = ({ detail }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '3.125rem 1fr',
        alignItems: 'center',
        justifyItems: 'flex-start',
        maxWidth: '48.438rem',
        marginTop: theme.spacing(5.25),
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('laptop')]: {
          marginTop: theme.spacing(1),
        },
      })}
    >
      <CardContent
        sx={{
          display: 'flex',
          backgroundColor: 'primary.light',
          borderRadius: '50%',
        }}
      >
        {detail.icon}
      </CardContent>
      <CardContent>
        <Typography variant="body1">{detail.subtitle}</Typography>
        <Typography variant="lead1">{detail.body}</Typography>
      </CardContent>
    </Box>
  )
}

export default ContainerDetail
