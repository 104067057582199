import FormField from 'components/UI/Formik/FormField/Index'

const ClientTypeField = () => {
  return (
    <FormField
      name="client_type"
      variant="select"
      label="Tipo de cliente"
      options={[
        { label: 'Persona Natural', value: 'N' },
        { label: 'Persona Jurídica', value: 'J' },
      ]}
      row
      data-cy="client_type_radio_group"
      optional={false}
    />
  )
}

export default ClientTypeField
