import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import Modal from 'components/UI/Modal/Modal'

import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

import StepperCertifications from './Commons/StepperCertifications'

const IsOkAffiliationModal = ({ state = {}, handleClose }) => {
  const { workerId } = state
  const affiliationQueryKey = ['getAffiliationById', workerId]

  const downloadURI = useDownloadURI()
  const downloadFile = useEnqueueDownload()
  const { affiliationsQuery } = useAffiliationsService({
    serviceParams: { queryKey: affiliationQueryKey, workerId },
    queryOptions: {
      enabled: Boolean(workerId) && state.open,
    },
  })
  const { affiliationsMutation } = useAffiliationsService({
    queryOptions: { enabled: false },
  })

  const downloadFiles = () => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'getAffiliationCertificates',
        workerId,
        async: true,
      },
      {
        onSuccess: ({ file, message }) => {
          if (file) {
            downloadURI(file)
          }

          if (message) {
            downloadFile({
              name: `Certificados de afiliación`,
              namePlural: true,
              fileCode: 'summary_files',
              pathname: `affiliations/${affiliationsQuery.data.id}/summary_files`,
            })
          }
        },
      }
    )
  }

  const handleClick = (file) => {
    downloadURI(file)
  }

  const certificatesCompleted =
    affiliationsQuery?.data?.certificates_data?.filter(
      ({ completed }) => completed
    ) || []

  return (
    <Modal
      open={state.open}
      header="Afiliación a Seguridad Social fue todo un éxito!"
      onCancel={handleClose}
      hideFooter
      adornment={[
        {
          variant: 'signature',
          color: 'complementary1.light',
          width: 177,
          height: 195,
          sx: { bottom: '-4rem', right: '2rem' },
        },
      ]}
      paperSx={{
        maxWidth: '45.5rem',
      }}
    >
      <Box>
        <Typography
          variant="body1"
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        >
          Aquí están los certificados de afiliación de cada una de las
          administradoras de la persona. También los podrás visualizar en los
          Documentos de su perfil.
        </Typography>
        {affiliationsQuery.isLoading ? (
          <LoadingBox />
        ) : (
          <Box>
            <StepperCertifications
              certificates={certificatesCompleted}
              handleClick={handleClick}
            />
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(7),
                display: 'flex',
                gap: theme.spacing(2),
              })}
            >
              <Button onClick={handleClose}>Regresar</Button>
              <Button
                variant="outlined"
                onClick={downloadFiles}
                loading={affiliationsMutation.isLoading}
              >
                Descargar certificados
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default IsOkAffiliationModal
