import { Typography } from '@mui/material'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { objectMap } from 'utils/general'
import { parseNumberToString } from 'utils/number'

import { tableTitles } from './helpers'

const commonColumnOptions = {
  emptyValue: '',
}

export const getColumnsData = (tableName, tabletDown) => {
  const columns = []
  // social_benefits, termination and payroll_retention sections table's have different columns
  if (
    ![
      // Social benefits tables
      'service_bonus',
      'severance',
      'severance_interests',
      // Payroll retention table
      'uvt_range',
      // Termination table
      'termination_base',
      'termination_holidays_base',
      'termination_severance_base',
      'termination_service_bonus_base',
    ].includes(tableName)
  ) {
    // all tables have 'name' as first column
    columns.push({
      Header: <Typography variant="h4">Concepto</Typography>,
      accessor: 'name',
    })

    if (tableName === 'base_salary') {
      columns.push({
        Header: 'Valor/Mensual',
        accessor: 'monthly_value',
        Cell: ({ row }) => formatCurrency(row.original.monthly_value),
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: 'Días trabajados',
        accessor: 'worked_days',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    if (tableName === 'overtime') {
      columns.push({
        Header: 'Valor sobre hora ordinaria',
        Cell: ({ row }) => parseNumberToString(row.original.constant_value),
        accessor: 'constant_value',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: '# de horas',
        Cell: ({ row }) => parseNumberToString(row.original.quantity),
        accessor: 'quantity',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    if (tableName === 'licenses' || tableName === 'incapacities') {
      columns.push({
        Header: 'Días',
        accessor: 'days',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: 'Salario Promedio',
        accessor: 'average_salary',
        Cell: ({ row }) => formatCurrency(row.original.average_salary),
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    if (tableName === 'social_security_retentions') {
      columns.push({
        Header: '%',
        accessor: 'percentage',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: 'IBC Seguridad Social',
        accessor: 'ibc_social_security',
        Cell: ({ row }) => formatCurrency(row.original.ibc_social_security),
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    if (
      tableName === 'social_security_company' ||
      tableName === 'parafiscals_company'
    ) {
      columns.push({
        Header: 'IBC',
        accessor: 'ibc_value',
        Cell: ({ row }) => formatCurrency(row.original.ibc_value),
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: '% Empresa',
        accessor: 'percentage',
        Cell: ({ row }) => `${row.original.percentage}%`,
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    if (tableName === 'social_benefits') {
      columns.push({
        Header: 'Base',
        accessor: 'base',
        Cell: ({ row }) => formatCurrency(row.original.base),
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })

      columns.push({
        Header: '%',
        accessor: 'percentage',
        Cell: ({ row }) => `${row.original.percentage}%`,
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }

    // all tables have 'value' as last column, except for 'worked_days' table
    if (tableName === 'worked_days') {
      columns.push({
        Header: 'Días',
        accessor: 'value',
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    } else {
      columns.push({
        Header: <Typography variant="h4">Valor</Typography>,
        accessor: 'value',
        Cell: ({ row: { original: rowData } }) => {
          let displayedValue = ''

          if (
            ['Número de UVTs', 'Base para retención por prima en UVT'].includes(
              rowData.name
            ) ||
            /(Días|retención en UVTs)/.test(rowData.name)
          ) {
            displayedValue = rowData.value.toString().replace('.', ',')
          } else if (rowData.name.includes('(%)')) {
            displayedValue = `${rowData.value}%`
          } else {
            displayedValue = formatCurrency(rowData.value)
          }

          return (
            <Typography
              variant="lead1"
              color={rowData.symbol === '=' ? 'primary.dark' : 'inherit'}
            >
              {typeof rowData.symbol === 'string' && rowData.symbol} {}
              {displayedValue}
            </Typography>
          )
        },
        alignHeader: 'right',
        alignCell: 'right',
        ...commonColumnOptions,
      })
    }
  } else if (tableName !== 'uvt_range') {
    columns.push(
      {
        Header: <Typography variant="h4">Periodo</Typography>,
        accessor: 'date',
        Cell: ({ row }) =>
          row.original.symbol === '=' ? (
            <Typography variant="h3">Suma</Typography>
          ) : (
            formatDisplayDateString(row.original.date)
          ),
      },
      {
        Header: <Typography variant="h4">Días</Typography>,
        accessor: 'worked_days',
        Cell: ({ row }) =>
          row.original.symbol === '=' ? (
            <Typography variant={tabletDown ? 'h4' : 'h3'}>
              {row.original.worked_days}
            </Typography>
          ) : (
            <Typography>{row.original.worked_days}</Typography>
          ),
      },
      {
        Header: <Typography variant="h4">Salario</Typography>,
        accessor: 'average_salary',
        Cell: ({ row }) =>
          row.original.symbol === '=' ? (
            <Typography variant={tabletDown ? 'h4' : 'h3'}>
              {formatCurrency(row.original.average_salary)}
            </Typography>
          ) : (
            <Typography>
              {formatCurrency(row.original.average_salary)}
            </Typography>
          ),
      },
      {
        Header: <Typography variant="h4">Ingresos adicionales</Typography>,
        accessor: 'salary_income',
        Cell: ({ row }) =>
          row.original.symbol === '=' ? (
            <Typography variant={tabletDown ? 'h4' : 'h3'}>
              {formatCurrency(
                row.original.salary_income +
                  (row.original.nightly_surcharge || 0)
              )}
            </Typography>
          ) : (
            <Typography>
              {formatCurrency(
                row.original.salary_income +
                  (row.original.nightly_surcharge || 0)
              )}
            </Typography>
          ),
      },
      {
        Header: <Typography variant="h4">Total</Typography>,
        accessor: 'total',
        Cell: ({ row }) =>
          row.original.symbol === '=' ? (
            <Typography variant="h3">
              {`${row.original.symbol} ${formatCurrency(row.original.total)}`}
            </Typography>
          ) : (
            <Typography variant="lead1">
              {`${row.original.symbol} ${formatCurrency(row.original.total)}`}
            </Typography>
          ),
        alignCell: 'right',
        alignHeader: 'right',
      }
    )
  } else {
    columns.push(
      {
        Header: <Typography variant="h4">Rango en UVT</Typography>,
        accessor: 'range',
      },
      {
        Header: <Typography variant="h4">Tarifa marginal</Typography>,
        accessor: 'rate',
        Cell: ({ row }) => `${row.original.rate}%`,
      },
      {
        Header: (
          <Typography variant="h4">
            Instrucción para calcular la retención (&quot;Impuesto&quot;) en $
          </Typography>
        ),
        accessor: 'instruction',
        Cell: ({ row }) => <span>{row.original.instruction}</span>,
        customWidth: '21.875rem',
      }
    )
  }

  return columns
}

export const tableColumns = objectMap(tableTitles, getColumnsData)
