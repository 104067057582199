import { I18nProvider } from '@react-aria/i18n'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { withProfiler } from '@sentry/react'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { CssBaseline } from '@mui/material'

import loadAnalyticScripts from 'components/App/loadAnalyticScripts'
import ConfirmProvider from 'components/UI/ConfirmModal/ConfirmProvider'
import LoadingModalProvider from 'components/UI/Loading/LoadingModalProvider'

import BreadcrumbsProvider from './BreadcrumbsProvider'
import ErrorBoundaries from './Error/ErrorBoundaries'
import ModalsProvider from './ModalsManager/ModalsProvider'
import NotistackProvider from './Notistack/NotistackProvider'
import ReactQueryProvider from './ReactQueryProvider'
import AppRoutes from './Routes'
import SupportMenuProvider from './SupportMenuContext'
import ThemeProvider from './ThemeProvider'
import UserProvider from './UserContext/UserProvider'
import loadChatScript from './UserContext/loadChatScript'

const AppWrapper = () => {
  useEffect(() => {
    // Use the scripts for analytics in all the application
    loadAnalyticScripts()
    loadChatScript() // Load the chat script for user authenticated
  }, [])

  return (
    <I18nProvider locale="es-CO">
      <ReactQueryProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <ThemeProvider>
              <CssBaseline />
              <NotistackProvider>
                <ErrorBoundaries>
                  <LoadingModalProvider>
                    <UserProvider>
                      <ConfirmProvider>
                        <BreadcrumbsProvider>
                          <SupportMenuProvider>
                            <ModalsProvider>
                              <AppRoutes />
                            </ModalsProvider>
                          </SupportMenuProvider>
                        </BreadcrumbsProvider>
                      </ConfirmProvider>
                    </UserProvider>
                  </LoadingModalProvider>
                </ErrorBoundaries>
              </NotistackProvider>
            </ThemeProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </ReactQueryProvider>
    </I18nProvider>
  )
}

export default withProfiler(AppWrapper)
