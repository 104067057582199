import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useParams } from 'react-router-dom'

import { Button, Menu, MenuItem } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import useElectronicPayrollPdfService from 'utils/hooks/ElectronicPayroll/pdfService'
import useDownloadURI from 'utils/hooks/useDownloadURI'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'

const PdfDownloadMenu = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'pdfDownloadMenu',
  })

  const downloadFile = useEnqueueDownload()
  const { openPremiumFeatureModal, showPremiumFeatures } = usePremiumFeature()
  const { hideLoadingModal } = useLoadingModal()

  const { downloadPdfMutation } = useElectronicPayrollPdfService()
  const { periodId } = useParams()
  const downloadURI = useDownloadURI()

  const handleDownloadFile = (fileType) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    popupState.close()
    downloadPdfMutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'getAllElectronicPayrollPdfs',
        periodId,
        fileType,
        async: true,
      },
      {
        onSuccess: ({ file, message }) => {
          if (file) {
            downloadURI(file)
            hideLoadingModal()
          }

          if (message) {
            downloadFile({
              name: `Comprobantes de nómina electrónica`,
              namePlural: true,
              fileCode: 'electronic_payroll_pdf',
              pathname: `electronic_payroll/periods/${periodId}/payslips`,
            })
          }
        },
        onError: () => {
          hideLoadingModal()
        },
      }
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={
          showPremiumFeatures ? (
            <Icon name="arrow-down" basic />
          ) : (
            <PremiumFeatureIcon />
          )
        }
        onClick={showPremiumFeatures ? null : openPremiumFeatureModal}
        {...(showPremiumFeatures ? bindTrigger(popupState) : null)}
      >
        Descargar archivos
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleDownloadFile('pdf')}>
          Representación gráfica Agrupada
        </MenuItem>
        <MenuItem onClick={() => handleDownloadFile('zip')}>
          Representación gráfica Individual
        </MenuItem>
      </Menu>
    </>
  )
}

export default PdfDownloadMenu
