import { Box, Link, Typography } from '@mui/material'

import Modal from 'components/UI/Modal/Modal'

import usePeriodService from 'utils/hooks/payroll/periodService'
import useDownloadURI from 'utils/hooks/useDownloadURI'

import logoArus from 'assets/images/logos/arus/logo_arus_dark.png'

import getSeverance from '../ActionGroup/common/helpers'
import { usePeriod } from '../helpers'

const SeveranceFileModal = ({ state, handleClose, period }) => {
  const {
    options: { social_benefits: socialBenefits = [] },
  } = usePeriod()
  const { open } = state
  const severance = getSeverance(socialBenefits)
  const downloadURI = useDownloadURI()

  const { periodMutation } = usePeriodService({
    queryOptions: { enabled: false },
  })
  const handleOk = () => {
    periodMutation.mutate(
      {
        mutationMethod: 'GET_SEVERANCE_FILE',
        periodId: period.id,
        searchParams: severance.socialBenefitPeriodId,
      },
      {
        onSuccess: ({ file }) => {
          if (file) {
            downloadURI(file)
          }
        },
      }
    )
  }

  return (
    <Modal
      open={open}
      isLoading={periodMutation.isLoading}
      header={
        <Typography
          variant="h3"
          sx={(theme) => ({
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          })}
        >
          Paga tus cesantías con{' '}
          <img src={logoArus} alt="Isotipo de Arus" width={142} height={36} />
        </Typography>
      }
      okText="Descargar archivo para pago"
      onOk={handleOk}
      onCancel={handleClose}
      adornment="spring"
      paperSx={{
        maxWidth: '48.5rem',
      }}
    >
      <Typography variant="body1" color="black.dark">
        Con este archivo podrás realizar el pago de las cesantías de las
        personas con el operador{' '}
        <Box
          component="span"
          sx={{
            fontWeight: 'bold',
          }}
        >
          PILA SuAporte ARUS.
        </Box>{' '}
        <br />
        <br />
        Para saber cómo realizar el pago en SuAporte ARUS,{' '}
        <Link
          href="https://ayuda.aleluya.com/help/paga-las-cesantías-de-tus-empleados-con-suaporte-arus"
          target="_blank"
          color="accent4.main"
          fontWeight={600}
        >
          haz click aquí.
        </Link>
      </Typography>
    </Modal>
  )
}

export default SeveranceFileModal
