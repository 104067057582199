export const messageType = {
  error: {
    description: {
      label:
        'Ups!! Algo salió mal en tu proceso de emisión de nómina electrónica. Revisa los errores para finalizar el proceso.',
      backgroundColor: 'accent3.light',
    },
    button: {
      label: 'Ver errores',
      backgroundColor: 'complementary1.light',
    },
  },
  issued: {
    description: {
      label:
        '¡Tu nómina electrónica fue emitida exitosamente! Ahora descarga y guarda la representación gráfica.',
      backgroundColor: 'complementary2.light',
    },
    button: {
      label: 'Ver',
      backgroundColor: 'primary.light',
    },
  },
}
