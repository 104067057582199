import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'

import { downloadFileWebSocket } from 'services/httpService'

import useNotifications from './useNotifications'

/**
 * @param {string} name - Set name enqueuedownload
 * @param {string} fileCode - Set in enqueuedownload filecode
 * @param {string} pathname - Set literal string pathname for firebase request
 * @returns function that ejecute download
 */

const useEnqueueDownload = () => {
  const { enqueueDownload } = useDownloadManager()
  const { showErrorMessage } = useNotifications()

  return ({ name, fileCode, pathname, callback, ...rest }) => {
    enqueueDownload({
      name,
      fileCode,
      serviceCall: async () => {
        const file = await downloadFileWebSocket(pathname)

        if (file.error) {
          showErrorMessage(file.error[0].message)
        }
        return file
      },
      callback,
      ...rest,
    })
  }
}

export default useEnqueueDownload
