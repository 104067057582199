import { getUserEmail, getUserName } from 'utils/auth'
import { getCompanyId } from 'utils/company'

const onZohoAPIReady = () => {
  window.$zoho = window.$zoho || {}
  window.$zoho.salesiq.floatwindow.visible('hide')
  window.$zoho.salesiq.floatbutton.visible('hide')
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_ZOHO_WIDGET_CODE,
    values: {},
    ready: function () {
      window.$zoho.salesiq.visitor = {
        name: getUserName(),
        email: getUserEmail(),
        info: { companyId: getCompanyId() },
      }
    },
  }
}

const zohoScript = {
  src: 'https://salesiq.zoho.com/widget',
  id: 'zoho-script-loader',
  child: () => {
    if (window.$zoho && window.$zoho.salesiq) {
      onZohoAPIReady()
    } else {
      window.$zoho = window.$zoho || {}
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: process.env.REACT_APP_ZOHO_WIDGET_CODE,
        values: {},
        ready: onZohoAPIReady,
      }
    }
  },
  async: true,
  defer: true,
}

export default zohoScript
