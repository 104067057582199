import { Box, Card, Typography } from '@mui/material'

import { concatTitlePaidSocialBenefits } from 'components/Period/Payroll/helpers'
import Adornment from 'components/UI/Adornment'

import aleluyaDocumentsMan from 'assets/images/views/common/aleluya_documents_man.svg'

const configAdornment = [
  {
    variant: 'spring',
    width: '5.06rem',
    height: '2.25rem',
    sx: { left: '2.87rem', top: '1.31rem' },
  },
  {
    variant: 'spring',
    width: '5.06rem',
    height: '2.25rem',
    sx: { left: '5.43rem', top: '5.43rem' },
  },
  {
    variant: 'spring',
    width: '5.06rem',
    height: '2.25rem',
    sx: { left: '0.93rem', top: '9.5rem' },
  },
  {
    variant: 'signature',
    width: '2.75rem',
    height: '3rem',
    sx: { right: '3.31rem', top: '2.06rem' },
  },
  {
    variant: 'signature',
    width: '2.75rem',
    height: '3rem',
    sx: { right: '7.87rem', top: '7.43rem' },
  },
  {
    variant: 'signature',
    width: '2.75rem',
    height: '3rem',
    sx: { right: '0.93rem', top: '8.18rem' },
  },
  {
    variant: 'signature',
    width: '2.75rem',
    height: '3rem',
    sx: { right: '6.06rem', top: '13rem' },
  },
]

const DescriptionCardHistory = ({ paidSocialBenefits }) => {
  const titles = paidSocialBenefits?.map(({ label }) => label)
  const benefit = concatTitlePaidSocialBenefits(titles)

  return (
    <Card
      sx={(theme) => ({
        minHeight: { mobile: 'auto', desktop: '16rem' },
        display: 'grid',
        gridTemplateColumns: { mobile: '1fr', laptop: '1fr 1fr' },
        backgroundColor: 'white.main',
        marginBottom: theme.spacing(3),
        borderRadius: '1rem',
      })}
    >
      <Box sx={(theme) => ({ py: theme.spacing(4.75), px: theme.spacing(6) })}>
        <Typography
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
          variant="h2"
        >
          Este periodo liquidaste {benefit}
        </Typography>
        <Typography variant="body1">
          Puedes ir a verificar el valor pagado ingresando al módulo de
          Prestaciones Sociales.
        </Typography>
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            maxWidth: '35rem',
            position: 'relative',
            display: {
              mobile: 'none',
              laptop: 'block',
            },
          }}
        >
          <Box
            sx={{
              width: '21.5rem',
              height: '21.5rem',
              position: 'absolute',
              backgroundColor: 'accent2.light',
              borderRadius: '50%',
              top: '-8.12rem',
            }}
          />
          <Box
            sx={{
              width: '21.5rem',
              height: '21.5rem',
              position: 'absolute',
              backgroundColor: 'accent2.light',
              borderRadius: '50%',
              right: '-1rem',
              bottom: '-8.75rem',
            }}
          />

          {configAdornment.map(({ variant, width, height, sx }, index) => {
            const key = `${variant}-${index}`
            return (
              <Adornment
                key={key}
                variant={variant}
                width={width}
                height={height}
                sx={{ ...sx }}
                color="black.main"
              />
            )
          })}
          <img
            src={aleluyaDocumentsMan}
            alt="Document Man"
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              margin: 'auto',
              height: '15rem',
            }}
          />
        </Box>
      </Box>
    </Card>
  )
}

export default DescriptionCardHistory
