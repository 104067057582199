import { Box, Typography } from '@mui/material'

import useModalExtraWorker from 'components/Subscription/Atoms/ExtraWorkersPayment/useModalExtraWorker'
import Button from 'components/UI/Button/Button'
import Dropzone from 'components/UI/Dropzone/Dropzone'
import MAlert from 'components/UI/MaterialUI/Alert/Alert'
import Modal from 'components/UI/Modal/Modal'

import { getWordSingular } from 'utils/format'

import getWorkersUploadMessage from 'messages/workers_file'

const WorkerFileModal = ({ errors, onSubmitFile, onCancel }) => {
  const modalExtraWorker = useModalExtraWorker()

  const regex = /(tu plan es máximo para)/i
  const hasLimitFromWorkers = errors[0].message.match(regex)
  const workers = errors[0].message.split(' ')[5]
  const isEmpty = errors[0].message.includes('no tiene información')

  const handleOpenModal = () => modalExtraWorker.openModal()

  const copiesModal = () => {
    const LIMIT_WORKERS = {
      subtitle: `Estás excediendo el límite ${workers}
      ${getWordSingular('personas', workers <= 1)} que tiene tu plan.`,
      body: getWorkersUploadMessage(workers).workersLimit,
    }

    const ERRORS_WORKERS = {
      subtitle: `${errors.length} ${getWordSingular(
        'Registros',
        errors?.length < 1
      )} con error`,
      body: getWorkersUploadMessage().download,
    }

    const IMPORT_INFO_EMPTY = {
      subtitle: 'Los campos están vacíos',
      body: getWorkersUploadMessage().importInfoEmpty,
    }

    if (hasLimitFromWorkers) {
      return LIMIT_WORKERS
    }

    if (isEmpty) {
      return IMPORT_INFO_EMPTY
    }

    return ERRORS_WORKERS
  }

  const copiesAlert = (error) => {
    const LIMIT_WORKERS = {
      alert: {
        header: null,
        body: (
          <>
            Mejora tu plan y agrega más personas.
            <br />
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenModal}
              style={{ padding: '0px', backgroundColor: 'transparent' }}
            >
              Mejorar plan
            </Button>
          </>
        ),
      },
    }

    const ERRORS_WORKERS = {
      alert: {
        header: `${error?.worker_name} ${error?.worker_last_name}`,
        body: `${error?.column_name}: ${error?.message}.`,
      },
    }

    const IMPORT_INFO_EMPTY = {
      alert: {
        body: error.message,
      },
    }

    if (hasLimitFromWorkers) {
      return LIMIT_WORKERS
    }

    if (isEmpty) {
      return IMPORT_INFO_EMPTY
    }

    return ERRORS_WORKERS
  }

  return (
    <Modal
      open
      footer={null}
      header="No se puede cargar el archivo"
      onCancel={() => onCancel(null)}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
        })}
      >
        <Typography variant="h6">{copiesModal().subtitle}</Typography>
        {errors.map((error, index) => {
          const key = `error_${index}_${error.code}`
          return (
            <MAlert
              severity={hasLimitFromWorkers ? 'warning' : 'error'}
              key={key}
              closable
              header={!isEmpty ? copiesAlert(error).alert.header : null}
            >
              {copiesAlert(error).alert.body}
            </MAlert>
          )
        })}

        <Typography style={{ maringBottom: '1em' }}>
          {copiesModal().body}
        </Typography>

        <Dropzone handleAdd={onSubmitFile} />
      </Box>
    </Modal>
  )
}

export default WorkerFileModal
