/* eslint-disable */
export const flowxoScript = {
  src: 'https://widget.flowxo.com/embed.js',
  data: {
    name: 'fxo-widget',
    value: `${process.env.REACT_APP_FLOWXO_ID_INNPULSA}`,
  },
  async: true,
  defer: true,
}
