import Icon from 'components/UI/Icon'

import anniversaryState from 'assets/images/views/dashboard/anniversary_state.png'
import birthdayState from 'assets/images/views/dashboard/birthday_state.png'
import contractState from 'assets/images/views/dashboard/contract_state.png'

export const getDataConfig = (type, sizeData) => {
  const defaultCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Personas que cumplen años',
      description: 'La siguientes personas cumplen años:',
      icon: <Icon name="birthday-cake" twoTone />,
    },
    anniversaries: {
      shortTitle: 'Aniversarios',
      title: 'Aniversarios',
      description:
        'Las siguientes personas cumplen años trabajando en tu compañía:',
      icon: <Icon name="confetti" twoTone />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos a vencer',
      title: 'Contratos próximos a vencer',
      description:
        'Las siguientes personas tienen contratos próximos a vencer:',
      icon: <Icon name="calendar" twoTone />,
    },
  }

  const emptyStateCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Cumpleaños',
      description: 'Este mes no tienes cumpleaños que celebrar.',
      image: birthdayState,
      icon: <Icon name="birthday-cake" twoTone />,
    },
    anniversaries: {
      title: 'Aniversarios',
      shortTitle: 'Aniversarios',
      description: 'Este mes no tienes aniversarios que celebrar.',
      image: anniversaryState,
      icon: <Icon name="confetti" twoTone />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos a vencer',
      title: 'Contratos próximos a vencer',
      description: 'Este mes no hay contratos próximos a vencer.',
      image: contractState,
      icon: <Icon name="calendar" twoTone />,
    },
  }
  return sizeData > 0 ? defaultCopies[type] : emptyStateCopies[type]
}

export default { getDataConfig }
