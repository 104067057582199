import { Box } from '@mui/material'

import Steps from 'components/UI/Steps/Steps'

import FormBox from '../FormBox'

const PaperForm = ({ steps, children }) => {
  const { stepsData, currentStep, progressStep, onChangeStep } = steps

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '336px 1fr',
        gap: theme.spacing(3.5),
        [theme.breakpoints.down('lg')]: {
          gridTemplateColumns: '1fr',
        },
        '& .MuiStepper-root': {
          padding: 0,
        },
      })}
    >
      <section>
        <Steps
          orientation="vertical"
          stepsData={stepsData}
          current={currentStep}
          progress={progressStep}
          onChangeStep={onChangeStep}
          outlined
        />
      </section>
      <FormBox
        sx={(theme) => ({
          minWidth: 280,
          padding: theme.spacing(4, 2.5),
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
          },
        })}
      >
        {children}
      </FormBox>
    </Box>
  )
}

export default PaperForm
