import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useQueries, useQueryClient } from 'react-query'
import { Navigate } from 'react-router-dom'

import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormBox from 'components/UI/FormBox'
import Page from 'components/UI/Page/Page'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyConfigurationService from 'utils/hooks/settings/configurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getCompanyConfiguration,
  getConfigurationOptions,
} from 'services/settings/configurationService'

import { SETTINGS } from 'config/routes'

import messages from 'messages/notification'

import Fields from './Fields/Fields'
import {
  getCompanyConfigurationInitialValues,
  validationSchema,
} from './helpers'

const SettingsAdvanced = () => {
  const queryClient = useQueryClient()
  const [redirectToSettings, setRedirectToSettings] = useState(false)
  const queryFunction = [getConfigurationOptions, getCompanyConfiguration]
  const [companyConfigurationOptionsQuery, companyConfigurationQuery] =
    useQueries(
      ['companyConfigurationOptions', 'companyConfiguration'].map(
        (query, index) => {
          return {
            queryKey: query,
            queryFn: () => queryFunction[index](),
          }
        }
      )
    )

  const { companyConfigurationMutation } = useCompanyConfigurationService({
    queryOptions: { enabled: false },
  })
  const isQueriesLoading =
    companyConfigurationOptionsQuery.isLoading ||
    companyConfigurationQuery.isLoading

  const { data: companyConfigurationOptions } = companyConfigurationOptionsQuery
  const { data: companyConfiguration } = companyConfigurationQuery
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()

  const companyConfigurationInitialValues =
    getCompanyConfigurationInitialValues(companyConfiguration?.data || {})

  const onSubmit = (values, form) => {
    const formData = new FormData()

    const dirtyValues = getDirtyValues(
      companyConfigurationInitialValues,
      values
    )

    if (isObjectEmpty(dirtyValues)) return setRedirectToSettings(true)

    Object.entries(dirtyValues).forEach((value) => {
      formData.append(value[0], value[1])
    })

    return companyConfigurationMutation.mutate(
      {
        mutationMethod: 'PUT',
        configuration: formData,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('companyConfiguration')
          showSuccessMessage(messages.COMPANY_UPDATE_SUCCESS)
          setRedirectToSettings(true)
        },
        onError: (error) => {
          handleError(error, form)
        },
      }
    )
  }

  if (redirectToSettings) return <Navigate to={SETTINGS()} />

  return (
    <Page
      header="Editar configuración empresa"
      isLoading={isQueriesLoading}
      isLoadingWithModal={companyConfigurationMutation.isLoading}
      grid
    >
      <FormBox sx={{ gridColumn: '1 / -1' }}>
        <Formik
          initialValues={companyConfigurationInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form>
              <Fields options={companyConfigurationOptions?.data || {}} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button type="submit" onClick={handleSubmit}>
                  Guardar
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </FormBox>
    </Page>
  )
}

export default SettingsAdvanced
