import { getDistributor, isOrganizer } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isBuildForCI, isProduction } from 'utils/general'
import { chatScripts } from 'utils/integration'

let currentChat = ''

function getChat() {
  const companyId = getCompanyId()
  if (
    [
      'e50515f4-aceb-48f8-a140-adf8499cfe81',
      '56280701-8155-4f2b-8593-d42dbc252bfd',
    ].includes(companyId)
  ) {
    return 'zoho'
  }

  if (isOrganizer()) return ''

  const distributor = getDistributor()

  if (distributor === 'innpulsa') return 'flowxo'

  return 'hubspot'
}

function removeCurrentChat() {
  if (currentChat) document.getElementById(currentChat)?.remove()

  document
    .querySelectorAll('iframe')
    ?.forEach?.((iframe) => iframe.parentNode.removeChild(iframe))
}

function loadChatScript() {
  const newChatName = getChat()

  if ((!isProduction || isBuildForCI) && newChatName !== 'zoho') return

  if (!newChatName) {
    currentChat = ''
    removeCurrentChat()
  } else if (currentChat !== newChatName) {
    // Remove Current Chat
    removeCurrentChat()

    // Verify valid chat
    if (!newChatName) return

    // Create script
    currentChat = newChatName

    const chatBody = chatScripts[newChatName]

    const script = document.createElement('script')
    script.setAttribute('id', newChatName)

    if (chatBody.src) script.src = chatBody.src
    if (chatBody.async) script.async = chatBody.async
    if (chatBody.defer) script.defer = chatBody.defer

    if (chatBody.data) {
      Object.keys(chatBody.data).forEach((key) => {
        script.setAttribute(`data-${key}`, chatBody.data[key])
      })
    }

    if (chatBody.child) script.onload = chatBody.child()

    document.body.appendChild(script)
  }
}

export default loadChatScript
