import { useFormikContext } from 'formik'

import { Link as MuiLink, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

const TermsAcceptanceField = ({
  name = 'terms_accepted',
  paymentVariant,
  activePaymentMethod,
}) => {
  const { values } = useFormikContext()
  const { payment_method: paymentMethod } = values || {}
  const isAutomaticDebitPayment = [paymentMethod, activePaymentMethod].includes(
    'automatic_debit'
  )

  const getInitialCopy = () => {
    if (paymentVariant === 'new_payment') {
      return 'Al completar este pago'
    }
    if (paymentVariant === 'payment_data_update') {
      return 'Al actualizar tu método de pago'
    }
    if (paymentVariant === 'first_payment') {
      return 'Al pagar tu suscripción'
    }

    return 'Al completar esta acción'
  }

  const providerCopy = isAutomaticDebitPayment ? 'DRUO' : 'PayU o Wompi'

  const paymentMethodCopy = isAutomaticDebitPayment
    ? 'débito automático'
    : 'tarjeta débito/crédito'

  const handleLabelClick = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <FormField
        checked={values.terms_accepted}
        name={name}
        variant="checkbox"
        checkboxSx={{
          '& .MuiTypography-root': { fontSize: '0.75rem' },
        }}
        checkboxLabel={
          <Typography
            variant="body2"
            color="black.dark"
            onClick={handleLabelClick}
            sx={{ cursor: 'default', textAlign: 'justify' }}
          >
            {getInitialCopy()}, autorizas a Aleluya a renovar automáticamente tu
            plan por medio de {paymentMethodCopy}, a través de {providerCopy}{' '}
            como proveedor y procesador de cobros. Además, aceptas nuestros{' '}
            <MuiLink
              color="accent4.main"
              onClick={() => {
                window.open(
                  'https://aleluya.com/terminos-y-condiciones',
                  '_blank'
                )
              }}
              sx={{
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Términos y Condiciones
            </MuiLink>
            {isAutomaticDebitPayment
              ? ' y reconoces que la cuenta que estás registrando es de tu titularidad'
              : null}
            . Puedes cancelar o modificar tu suscripción en cualquier momento.
          </Typography>
        }
      />
    </>
  )
}

export default TermsAcceptanceField
