import { useMemo } from 'react'

import { Box, alpha, useTheme } from '@mui/material'

import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import { getPayrollSummary } from 'services/payroll/periodService'

import {
  usePeriod,
  usePeriodAPI,
  useReturnPayPayroll,
} from '../Payroll/helpers'
import getColumns, { getTableActions } from './helpers'

const SummaryTable = () => {
  const {
    period: { id: periodId, contracts_counter: contractsCounter = {} },
    payPreviousPayroll,
    locations,
    activeLocation,
    activeContract,
  } = usePeriod()
  const { openPayrollSummaryFileModal, setActiveLocation, setActiveContract } =
    usePeriodAPI()
  const theme = useTheme()
  const columns = useMemo(
    () => getColumns(periodId, payPreviousPayroll),
    [payPreviousPayroll, periodId]
  )
  const companyId = getCompanyId()

  const handleReturnToPayPayroll = useReturnPayPayroll()

  const tableActions = getTableActions({
    handleReturnToPayPayroll,
    openPayrollSummaryFileModal,
    payPreviousPayroll,
    contractsCounter,
    locations,
    activeLocation,
    setActiveLocation,
    activeContract,
    setActiveContract,
  })

  const expandedRows = useMemo(
    () => ({
      0: true,
    }),
    []
  )

  const fetchSummary = (pageIndex, search) => {
    return {
      queryKey: [
        'getPayrollSummary',
        pageIndex,
        search,
        companyId,
        periodId,
        activeLocation,
        activeContract,
      ],
      queryFunction: () =>
        getPayrollSummary({
          periodId,
          searchParams: {
            period_id: periodId,
            page: pageIndex + 1,
            search,
            location: activeLocation,
            contract_category: activeContract,
          },
        }),
    }
  }

  return (
    <Box>
      <Table
        columns={columns}
        data={fetchSummary}
        actions={tableActions}
        options={{
          expandableRows: true,
          sorting: false,
          version: {
            toolbar: 'v2',
          },
          rowStyle: (_, row) => {
            const { white, complementary1 } = theme.palette

            if (row.depth) {
              const parentIndex = parseInt(row.id, 10)
              return {
                backgroundColor:
                  parentIndex % 2
                    ? alpha(complementary1.light, 0.2)
                    : white.main,
              }
            }
            return {
              backgroundColor:
                row.index % 2 ? alpha(complementary1.light, 0.2) : white.main,
            }
          },
          expandedInitialState: expandedRows,
        }}
      />
    </Box>
  )
}

export default SummaryTable
