import { Box, Typography } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import NoWrap from 'components/UI/NoWrap'

import { formatCurrency } from 'utils/format'
import {
  contractCategories,
  getGeneralContractCategory,
  getNameWorkerType,
} from 'utils/worker'

import WorkerExpander from './WorkerExpander'

export const getTableActions = ({
  handleReturnToPayPayroll,
  openPayrollSummaryFileModal,
  payPreviousPayroll,
  contractsCounter,
  locations,
  activeLocation,
  setActiveLocation,
  activeContract,
  setActiveContract,
}) => {
  const contractOptions = Object.entries(contractsCounter)
    .filter(([, count]) => count > 0)
    .map(([contract, count]) => ({
      label: `${getNameWorkerType(contract, true)} (${count})`,
      value: getGeneralContractCategory(contract),
    }))

  const locationOptions = locations.map((location) => ({
    label: `${location.name} (${location.workers})`,
    value: location.id,
  }))

  const actions = [
    {
      id: 'filterContract',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Contrato"
          options={contractOptions}
          value={activeContract}
          onChange={setActiveContract}
        />
      ),
    },
    {
      id: 'filterLocation',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Sede"
          options={locationOptions}
          value={activeLocation}
          onChange={setActiveLocation}
        />
      ),
    },
    {
      id: 'editPayroll',
      tooltip: payPreviousPayroll ? 'Regresar' : 'Editar nómina',
      isFreeAction: true,
      buttonVariant: 'outlined',
      onClick: handleReturnToPayPayroll,
      icon: (
        <Icon name="arrow-right" basic sx={{ transform: 'rotate(180deg)' }} />
      ),
    },
    {
      id: 'reportPayroll',
      tooltip: 'Descargar resumen',
      isFreeAction: true,
      icon: <Icon name="download" basic />,
      buttonProps: {
        endIcon: <PremiumFeatureIcon />,
      },
      onClick: openPayrollSummaryFileModal,
    },
  ]

  return actions
}

const ItemText = ({ expanded = false, children }) => {
  return (
    <NoWrap>
      <Typography
        variant="small"
        sx={(theme) => ({
          ...(expanded && {
            fontWeight: 'bold',
            color: theme.palette.black.dark,
          }),
        })}
      >
        {children}
      </Typography>
    </NoWrap>
  )
}

const TotalItem = ({ topValue, bottomValue, expanded }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(0.5),
        })}
      >
        <ItemText expanded={expanded}>{topValue}</ItemText>
      </Box>
      <ItemText expanded={expanded}>{bottomValue}</ItemText>
    </Box>
  )
}

const ColumnHeader = ({ children }) => {
  return (
    <Typography
      variant="lead2"
      sx={(theme) => ({
        fontSize: theme.typography.small.fontSize,
      })}
    >
      {children}
    </Typography>
  )
}

// Function to get the original row data from a sub row
const getMainRowData = (row, rowsById) => {
  const parentRowId = row.id.split('.').slice(0, -1).join('.')

  if (parentRowId) {
    const parentRow = rowsById[parentRowId]

    return parentRow?.original
  }

  return undefined
}

const getColumns = (periodId, payPreviousPayroll) => {
  return [
    {
      id: 'expander',
      excludeOnEdit: true,
      customMinWidth: '200px',
      Header: <ColumnHeader>Información del empleado</ColumnHeader>,
      Cell: ({ row, toggleRowExpanded, state, rowsById }) => {
        const toggleExpandedProps = row.getToggleRowExpandedProps()

        const onExpand = () => {
          if (!row.isExpanded) {
            Object.keys(state.expanded).forEach((key) => {
              toggleRowExpanded(key, false)
            })
          }
          toggleExpandedProps?.onClick?.()
        }

        if (row.canExpand) {
          return (
            <WorkerExpander
              workerId={row.original.worker_id}
              payrollId={row.original.payroll_id}
              expanded={row.isExpanded}
              onExpand={onExpand}
              workerName={row.original.worker_name}
              periodId={periodId}
              payPreviousPayroll={payPreviousPayroll}
            />
          )
        }

        if (!row.canExpand && row.index === 0) {
          const mainRowData = getMainRowData(row, rowsById)

          if (!mainRowData) return null

          return (
            <ItemText>
              {contractCategories[mainRowData?.contract_category]}
            </ItemText>
          )
        }

        if (!row.canExpand && row.index === 1) {
          const mainRowData = getMainRowData(row, rowsById)

          if (!mainRowData) return null

          return (
            <ItemText>
              {`${mainRowData?.worked_time?.label}: ${mainRowData?.worked_time?.value}`}
            </ItemText>
          )
        }

        return null
      },
    },
    {
      id: 'incomes',
      Header: <ColumnHeader>Ingresos</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '250px',
      columns: [
        {
          accessor: 'income.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.income.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'income.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.income.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.income.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'deductions',
      Header: <ColumnHeader>Seguridad social y deducciones</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '250px',
      columns: [
        {
          accessor: 'deductions.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.deductions.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'deductions.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.deductions.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.deductions.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'social_security',
      Header: <ColumnHeader>Seguridad social Emp.</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'social_security.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.social_security.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'social_security.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.social_security.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.social_security.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'parafiscals',
      Header: <ColumnHeader>Parafiscales</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'parafiscals.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.parafiscals.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'parafiscals.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.parafiscals.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.parafiscals.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'social_benefits',
      Header: <ColumnHeader>Provisiones</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '200px',
      columns: [
        {
          accessor: 'social_benefits.label',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            return (
              <ItemText expanded={row.isExpanded && !row.depth}>
                {row.original.social_benefits.label}
              </ItemText>
            )
          },
        },
        {
          accessor: 'social_benefits.value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (row.original.social_benefits.value !== null) {
              return (
                <ItemText expanded={row.isExpanded && !row.depth}>
                  {formatCurrency(row.original.social_benefits.value)}
                </ItemText>
              )
            }
            return null
          },
        },
      ],
    },
    {
      id: 'totals',
      Header: <ColumnHeader>Totales</ColumnHeader>,
      alignHeader: 'center',
      customMinWidth: '180px',
      columns: [
        {
          accessor: 'total_concept',
          Header: <ColumnHeader>Concepto</ColumnHeader>,
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={row.original.worker_payment.label}
                bottomValue={row.original.company_cost.label}
              />
            )
          },
        },
        {
          accessor: 'total_value',
          Header: <ColumnHeader>Valor</ColumnHeader>,
          alignHeader: 'right',
          alignCell: 'right',
          Cell: ({ row }) => {
            if (!row.canExpand) return null

            return (
              <TotalItem
                expanded={row.isExpanded && !row.depth}
                topValue={formatCurrency(row.original.worker_payment.value)}
                bottomValue={formatCurrency(row.original.company_cost.value)}
              />
            )
          },
        },
      ],
    },
  ]
}

export default getColumns
