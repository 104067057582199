import OrganizationEmail from 'components/UI/OrganizationEmail'

import { formatPeriodDateRange } from 'utils/dateTime'

export default (data, distributor) => {
  const { category, label, initialDay, endDay } = data
  const bgGeneratedPayroll = {
    nominapp: 'primary.dark',
    arus: 'primary.light',
    alegra_claro: 'primary.main',
  }

  const socialBenefits = {
    period_novelties: {
      title: `${label} del ${
        initialDay && endDay ? formatPeriodDateRange(initialDay, endDay) : ''
      }`,
      content:
        'Aquí encontrarás todas las novedades que tuvieron las Personas de tu equipo en este periodo. Recuerda que puedes editarlas ingresando a Liquidar nómina.',
      color: 'complementary1.dark',
    },
    service_bonus: {
      title: `${label}`,
      content:
        'Si tus empleados reciben comisiones, bonificaciones o tienen modificaciones de salario durante el mes, te recomendamos realizar la liquidación de la prima de servicios durante la liquidación de nómina de la 2da quincena del periodo. Si ya la realizaste y debes modificarla, utiliza la funcionalidad Reiniciar valores para calcular nuevamente la prima con la información actualizada.',
      color: 'accent1.light',
    },
    severance: {
      title: `${label}`,
      content:
        'Calcula el valor de las Cesantías, para pagar a través del operador tu operador de información. Revisa la base promedio (usamos la información la base de los intereses de las cesantías disponible en Aleluya), donde esta incluido el subsidio de transporte (si aplica) y modifícala si lo deseas. Si deseas conocer más sobre cómo realizar este proceso haz clic aquí.',
      color: 'primary',
    },
    severance_interests: {
      title: `${label}`,
      content:
        'Realiza el pago de los Intereses de las Cesantías fácilmente con Aleluya. La base promedio incluye incluye el salario, horas extra, recargos, ingresos salariales y subsidio de transporte (Si aplica). Usamos la información del año anterior disponible en Aleluya para calcular la base de los intereses, la cual puedes modificar. Si deseas conocer más sobre cómo realizar este proceso haz clic aquí.',
      color: 'accent2.dark',
    },
    generated_payroll: {
      title: label,
      content: (
        <>
          Creamos para ti tus archivos para pago en banco, colillas de pago,
          archivo para pago de seguridad social, interfaz contable y resumenes
          de nómina. Recuerda que puedes escribirnos a <OrganizationEmail /> si
          necesitas que te rescatemos con algo.
        </>
      ),
      color: bgGeneratedPayroll[distributor],
    },
    paid_benefit: {
      title: `Este periodo liquidaste ${label}`,
      content: `Puedes ir a verificar el valor pagado ingresando al módulo de Prestaciones Sociales.`,
      color: 'accent2.dark',
    },
  }

  return category ? socialBenefits[category] : {}
}
