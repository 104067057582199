import { Formik } from 'formik'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Loading from 'components/UI/Loading/Loading'
import Page from 'components/UI/Page/Page'

import {
  isFreeCompany,
  isPremiumExpiredByCancellationCompany,
} from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

import {
  DASHBOARD,
  SUBSCRIPTION_END_FREE,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_TRANSACTION_RESPONSE,
} from 'config/routes'

import SubscriptionSummary from './Summary'
import {
  getInitialValues,
  getPaymentData,
  getValidationSchema,
} from './helpers'

export const SubscriptionViewContext = createContext()
SubscriptionViewContext.displayName = 'SubscriptionViewContext'

const Paywall = () => {
  const location = useLocation()
  const { refreshCompany, isAPartnerChild } = useUser()
  const { subscription } = useSubscription()
  const navigate = useNavigate()
  const { showInfoMessage } = useNotifications()
  const formRef = useRef()
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const companyId = getCompanyId()

  const { modality: modalitySwitch, selectedPlan: planSelected } =
    location?.state || {}

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const subscriptionExpiredByCancellation =
    isPremiumExpiredByCancellationCompany(subscription)

  const [modality, setModality] = useState('month')
  const [selectedPlan, setSelectedPlan] = useState(planSelected || {})
  const queryClient = useQueryClient()
  const locationState = Boolean(location.state)
  const isSubscriptionPromoPath = location.pathname === SUBSCRIPTION_END_FREE()

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
    queryOptions: {
      enabled: isAFreeCompany && isSubscriptionPromoPath,
    },
  })
  const plansQueryData = useMemo(
    () => plansQuery?.data?.plans || [],
    [plansQuery?.data?.plans]
  )

  const basicPlan = plansQueryData?.find(
    (plan) => removeSuffix(plan.coded_name, -8) === 'premium_per_worker_plan'
  )

  const currentPlan = useMemo(
    () =>
      subscriptionExpiredByCancellation
        ? plansQueryData?.find(
            (plan) =>
              removeSuffix(plan.coded_name, -8) ===
              removeSuffix(subscription.plan.coded_name, -8)
          )
        : {},
    [
      plansQueryData,
      subscription.plan.coded_name,
      subscriptionExpiredByCancellation,
    ]
  )

  const { subscriptionQuery } = useSubscriptionService({
    queryOptions: {
      enabled: locationState || subscriptionExpiredByCancellation,
      onSuccess: ({ data }) => {
        formRef?.current?.setValues({
          ...formRef?.current?.values,
          modality: modality || data?.type,
          plan: selectedPlan?.id,
          workers_number: !data?.partner ? data?.payrolls_size || 1 : 1,
          isPartner: data?.partner,
        })

        if (data?.payment_category === 'clara_payment') {
          formRef?.current?.setValues({
            ...formRef?.current?.values,
            modality: 'year',
            workers_number: 20,
          })
        }
      },
    },
  })

  const { paymentMethodMutation } = usePaymentMethodService({
    queryOptions: {
      enabled: false,
    },
  })

  const onSelectPlan = (newPlan) => setSelectedPlan(newPlan)

  const navigateToTransactionResponse = (paymentData) => {
    navigate(
      SUBSCRIPTION_TRANSACTION_RESPONSE(
        `?status=${paymentData.payment_status}`
      ),
      {
        state: {
          payment_status: paymentData.payment_status,
        },
      }
    )
  }

  const [activePayment, setActivePayment] = useState(() => {
    const creditCardMethodAvailable = subscription?.payment_methods?.credit_card

    return creditCardMethodAvailable
      ? 'credit_card'
      : Object.keys(subscription?.payment_methods || {})[0] || 'credit_card'
  })

  const [expandedAccordion, setExpandedAccordion] = useState(activePayment)

  const onSubmit = (values) => {
    const paymentData = getPaymentData(
      values,
      values?.payment_method,
      selectedPlan?.id,
      values?.modality,
      Number(values?.workers_number),
      subscriptionQuery?.data?.partner
    )

    paymentMethodMutation.mutate(
      {
        paymentMethod: values?.payment_method,
        paymentMethodData: paymentData,
      },
      {
        onSuccess: async (response) => {
          if (activePayment === 'pse') window.location.href = response.bank_url

          if (['credit_card', 'automatic_debit'].includes(activePayment)) {
            if (response.payment_status === 'APPROVED') {
              await refreshCompany()
              navigate(SUBSCRIPTION_STATUS(), {
                state: {
                  payment_status: response.payment_status,
                  value: response.value,
                  plan_id: response.plan_id,
                  paid_workers: response.paid_workers,
                },
              })
              openTransactionResponseModal()
            } else if (
              ['PENDING', 'DECLINED'].includes(response.payment_status)
            ) {
              navigateToTransactionResponse(response)
            } else {
              showInfoMessage(response.message)
            }
          }
          queryClient.invalidateQueries(['getSubscription', getCompanyId()])
        },
      }
    )
  }

  const subscriptionViewContextValue = useMemo(() => {
    return {
      modality,
      selectedPlan,
      activePayment,
      expandedAccordion,
      isSubscriptionPromoPath,
      subscription: subscriptionQuery?.data,
      loadingPayment: paymentMethodMutation.isLoading,
      onSelectPlan,
      setSelectedPlan,
      setModality,
      setActivePayment,
      setExpandedAccordion,
    }
  }, [
    modality,
    selectedPlan,
    activePayment,
    expandedAccordion,
    subscriptionQuery.data,
    isSubscriptionPromoPath,
    paymentMethodMutation.isLoading,
  ])

  useEffect(() => {
    if (!isSubscriptionPromoPath) {
      setModality(modalitySwitch || subscription?.type)
      setSelectedPlan(planSelected || currentPlan)
    }

    if (isSubscriptionPromoPath && isAFreeCompany) {
      setSelectedPlan(basicPlan)
    }
  }, [
    modalitySwitch,
    planSelected,
    isSubscriptionPromoPath,
    isAFreeCompany,
    basicPlan,
    subscription.plan,
    subscription?.type,
    currentPlan,
  ])

  if (plansQuery.isLoading) {
    return <Loading />
  }

  return (isSubscriptionPromoPath && isAFreeCompany) ||
    locationState ||
    subscriptionExpiredByCancellation ? (
    <SubscriptionViewContext.Provider value={subscriptionViewContextValue}>
      <Page
        documentTitle="Método de pago"
        rootSx={{
          ':after': { content: 'none' },
        }}
        contentSx={{ margin: '0 !important', height: '100%' }}
        isLoading={subscriptionQuery?.isLoading}
      >
        <Formik
          innerRef={formRef}
          onSubmit={onSubmit}
          initialValues={getInitialValues(planSelected?.id)}
          validationSchema={getValidationSchema()}
          enableReinitialize
        >
          {() => {
            return (
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <SubscriptionSummary />
              </Box>
            )
          }}
        </Formik>
      </Page>
    </SubscriptionViewContext.Provider>
  ) : (
    <Navigate
      to={isAPartnerChild || isAFreeCompany ? DASHBOARD : SUBSCRIPTION_STATUS()}
    />
  )
}

export default Paywall
