import { useLocation, useNavigate } from 'react-router-dom'

import useModals from 'components/App/ModalsManager/useModals'

import { Header, ModalExtraWorker } from './ModalExtraWorker'

const useModalExtraWorker = () => {
  const modals = useModals()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClose = () => {
    modals.closeModal()
    if (location.pathname === '/workers/new') {
      navigate(-1)
    }
  }

  const openModal = () => {
    modals.openModal({
      id: 'extraWorker',
      content: <ModalExtraWorker onClose={handleClose} />,
      modalProps: {
        header: <Header />,
        onCloseModal: handleClose,
        hideFooter: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        dialogProps: {
          fullWidth: true,
          maxWidth: 'sm',
        },
      },
    })
  }

  return { openModal }
}

export default useModalExtraWorker
