import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import { yupLocaleES } from 'utils/form'
import useDecree376Service from 'utils/hooks/payroll/decree376Service'
import useEnqueueDownload from 'utils/hooks/useEnqueueDownload'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { WORKER_INDEX } from 'config/routes'

const initialValues = {
  associated_form: '',
  payment_date: null,
}

yup.setLocale(yupLocaleES)

const validationSchema = yup.object({
  associated_form: yup.string().max(10).required(),
  payment_date: yup.string().required().nullable(),
})

const DecreeSocialSecurityFileModal = ({ state, handleClose }) => {
  const downloadFile = useEnqueueDownload()
  const { decree376Mutation } = useDecree376Service({
    queryOptions: { enabled: false },
  })
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()
  const confirm = useConfirm()

  const { periodId, decreePeriodId } = state

  const handleSubmit = (values) => {
    const handleDownloadError = (error) => {
      if (error.status === 402) {
        handleError(error)
      } else if (error.errors && error.errors[0].code === '0607') {
        confirm({
          type: 'info',
          okText: 'Editar trabajadores',
          title: 'Completa la información de tus empleados',
          description: error.errors[0].message,
          onOk: () =>
            navigate(WORKER_INDEX('?tab=active'), {
              state: { filter: 'not_finished' },
            }),
        })

        return true
      }

      return false
    }

    decree376Mutation.mutate(
      {
        mutationMethod: 'GET',
        mutationKey: 'getDecree376SocialSecurityFile',
        params: { periodId, decreePeriodId, searchParams: values },
      },
      {
        onSuccess: () => {
          handleClose()

          return downloadFile({
            name: 'archivo seguridad social decreto 376',
            fileCode: 'decree_social_security_file',
            pathname: `periods/${periodId}/decree376/social_security_file/${decreePeriodId}`,
            handleError: handleDownloadError,
          })
        },
      }
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit }) => {
        return (
          <Modal
            open
            header="Generar archivo de seguridad social por corrección del Decreto 376 de 2021"
            okText="Generar"
            onOk={onSubmit}
            onCancel={handleClose}
          >
            <Typography gutterBottom>
              Se generará un archivo de corrección con las personas a las cuales
              les hiciste el aporte para 376 en este periodo para que lo subas a
              tu operador (debe ser el mismo que usaste para pagar la planilla a
              corregir).
            </Typography>
            <Form>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(3),
                })}
              >
                <FormField
                  label="Número de planilla a corregir"
                  name="associated_form"
                  variant="number"
                  decimalScale={0}
                  allowLeadingZeros
                  format="##########"
                />
                <FormField
                  variant="datepicker"
                  name="payment_date"
                  label="Fecha en que realizaste el pago de la planilla a corregir"
                  minDate="2020-04-01"
                  maxDate="2020-06-30"
                />
              </Box>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default DecreeSocialSecurityFileModal
