import { useState } from 'react'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import ActivationModal from './Modals/ActivationModal'

const WorkerShowActivateButton = ({ isWorker, worker }) => {
  const [showActivationModal, setShowActivationModal] = useState(false)
  return !isWorker && !worker?.terminated ? (
    <>
      <Button
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
        variant="outlined"
        size="large"
        endIcon={
          worker?.active ? (
            <Icon name="close" basic />
          ) : (
            <Icon name="approved-checked" basic />
          )
        }
        onClick={() => setShowActivationModal(true)}
      >
        {worker?.active ? 'Desactivar' : 'Activar'}
      </Button>
      {showActivationModal ? (
        <ActivationModal onCancel={() => setShowActivationModal(false)} />
      ) : null}
    </>
  ) : null
}

export default WorkerShowActivateButton
