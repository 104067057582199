import { Button } from '@mui/material'

import Icon from 'components/UI/Icon'

import helpOnClick, { zohoHelpOnClick } from 'utils/chat'

import { EMAIL_SUPPORT } from 'config/organization'

import { useSupportMenuContext } from '../SupportMenuContext'

const SupportActionButton = ({ variant }) => {
  const { setEmailOpen, setShowChat, setShowSupportActionGroup } =
    useSupportMenuContext()

  const actionData = {
    whatsapp: {
      text: 'WhatsApp',
      icon: 'whatsapp',
      action: () => {
        window.open(
          'https://api.whatsapp.com/send/?phone=573242914164&text&type=phone_number&app_absent=0',
          '_blank'
        )
      },
    },
    chat: {
      text: 'Chat en línea',
      icon: 'chat-online',
      action: () => {
        setShowChat(true)
        helpOnClick()
      },
    },
    zohoChat: {
      text: 'Zoho Chat en línea',
      icon: 'chat-online',
      action: () => zohoHelpOnClick(),
    },
    mail: {
      text: 'Correo electrónico',
      icon: 'mail-notification',
      action: () => {
        setEmailOpen(true)
        window.location.href = `mailto:${EMAIL_SUPPORT}`
      },
    },
  }

  const data = actionData[variant]

  return (
    <Button
      variant="outlined"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: theme.palette.white.main,
        marginTop: theme.spacing(2),
      })}
      onClick={() => {
        setShowSupportActionGroup(false)
        data.action()
      }}
      endIcon={<Icon name={data.icon} />}
    >
      {data.text}
    </Button>
  )
}

export default SupportActionButton
