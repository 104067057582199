const onConversationsAPIReady = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '#hubspot-chat_inline-embed',
    enableWidgetCookieBanner: true,
    disableAttachment: false,
  }
}

const hubspotScript = {
  src: `//js-na1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT}.js`,
  id: 'hs-script-loader',
  child: () => {
    if (window.HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }
  },
  async: true,
  defer: true,
}

export default hubspotScript
