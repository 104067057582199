import { Link as MuiLink } from '@mui/material'

import * as organizationConfig from 'config/organization'

const OrganizationEmail = ({
  variant = 'support',
  color = 'black.main',
  ...props
}) => {
  const emailByVariant = {
    support: organizationConfig.EMAIL_SUPPORT,
    data: organizationConfig.EMAIL_DATA,
    hello: organizationConfig.EMAIL_HELLO,
    activation: organizationConfig.EMAIL_ACTIVATION,
    billing: organizationConfig.EMAIL_ACCOUNTANT,
  }

  const email = emailByVariant[variant]

  return (
    <MuiLink href={`mailto:${email}`} color={color} fontWeight={600} {...props}>
      {email}
    </MuiLink>
  )
}

export default OrganizationEmail
