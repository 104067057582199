import { Box, IconButton, OutlinedInput, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { useCalculateExtraWorkers } from '../helpers'

const ExtraWorkersCounter = ({
  extraWorkers = 0,
  setExtraWorkers,
  setTotalPayment,
  selectedPlan,
  selectedModality,
  getImmediatePayment,
  paymentModality,
  currentWorkers,
}) => {
  const disableDecreaseButton = extraWorkers < 1

  const { calculateSubscriptionFromInput } = useCalculateExtraWorkers(
    getImmediatePayment,
    currentWorkers
  )

  const callback = (data) =>
    setTotalPayment(
      getImmediatePayment
        ? data?.additional_workers_info?.extra_workers_value
        : data?.subscription_value?.[paymentModality]?.total
    )

  const handleChange = (event) => {
    setExtraWorkers(Number(event.target.value))
    calculateSubscriptionFromInput(
      {
        values: event.target.value,
        newPlanId: selectedPlan?.id,
        subscriptionType: selectedModality,
        workersNumber: currentWorkers + Number(event.target.value),
      },
      callback
    )
  }

  const handleIncrease = () => {
    const newValue = extraWorkers + 1
    handleChange({ target: { value: newValue } })
  }

  const handleDecrease = () => {
    const newValue = extraWorkers - 1
    handleChange({ target: { value: newValue } })
  }

  return (
    <>
      <Typography variant="lead2">
        ¿Cuántas personas adicionales quieres agregar?
      </Typography>
      <Box
        sx={(theme) => ({
          width: '45%',
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
          gridTemplateColumns: '1fr 4fr 1fr',
          columnGap: theme.spacing(1),
          marginTop: theme.spacing(1),
        })}
      >
        <IconButton
          aria-label="decrease"
          color="primary"
          onClick={handleDecrease}
          disabled={disableDecreaseButton}
          size="large"
        >
          <Icon name="minus" basic />
        </IconButton>
        <OutlinedInput
          name="workers_number"
          variant="number"
          value={extraWorkers || ''}
          onChange={handleChange}
          inputProps={{
            type: 'number',
          }}
          sx={{
            height: '2.25rem',
            '& .MuiOutlinedInput-input': {
              textAlign: 'center',
            },
          }}
        />
        <IconButton
          aria-label="increase"
          color="primary"
          onClick={handleIncrease}
          size="large"
        >
          <Icon name="plus" basic />
        </IconButton>
      </Box>
    </>
  )
}

export default ExtraWorkersCounter
