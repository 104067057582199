import { useTheme } from '@emotion/react'

import { Box, Typography } from '@mui/material'

import OrganizationEmail from 'components/UI/OrganizationEmail'

import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { getDetailCard } from '../../helpers'
import PartnerSubscriptionCardsModalContent from './PartnerSubscriptionCardsModalContent'

const PartnerSubscriptionCardsModal = () => {
  const themeInstance = useTheme()

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', getCompanyId()],
    },
  })

  const plansData =
    (plansQuery?.data?.plans || []).filter(
      (plan) => removeSuffix(plan.coded_name, -8) !== 'pro_per_worker_plan'
    ) || []

  const plans = getDetailCard(plansData, themeInstance) || []
  const currentPlan = plans?.find((plan) => plan?.planData.current_plan)
  const currentPlanIndex = plans.indexOf(currentPlan)

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'grid',
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(2),
          marginTop: theme.spacing(3),
          [theme.breakpoints.up('tablet')]: {
            marginTop: theme.spacing(9),
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(2),
            alignContent: 'center',
            rowGap: theme.spacing(8),
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        })}
      >
        {plans.map((plan, index) => {
          const { planData, cardsStyles, codedName } = plan
          const planId = planData.id

          return (
            <PartnerSubscriptionCardsModalContent
              key={planId}
              cardsStyles={cardsStyles}
              codedName={codedName}
              planData={planData}
              selectedPlanIndex={index}
              currentPlanIndex={currentPlanIndex}
            />
          )
        })}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
        })}
      >
        <Typography variant="h3">Aclaración facturación Partners</Typography>
        <Typography color="black.dark">
          Realizar esta activación tendrá un costo que empezará a reflejarse en
          tu próxima facturación. Si tienes dudas o necesitas ayuda, escríbenos
          a <OrganizationEmail color="accent4.dark" />.
        </Typography>
      </Box>
    </>
  )
}

export default PartnerSubscriptionCardsModal
