import { Box, InputAdornment, TextField, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import NumberFormatField from 'components/UI/MaterialUI/NumberFormatField'

import { formatCurrency } from 'utils/format'

import messages from 'messages/payroll'

import Tooltip from '../common/Tooltip'

const Header = ({
  payroll,
  holidaysAverageSalary,
  noveltiesAverageSalary,
  licensesAverageSalary,
  isEdit,
  onEnableEditing,
  onDisableEditing,
  onChangeData,
  onOK,
  noveltyConcept,
}) => {
  const { contract_category: contractCategory } = payroll

  const noveltyData = {
    holidays: {
      title: 'Salario base vacaciones',
      tooltip: 'average_salary',
      noveltyKey: 'holidays_average_salary',
      salaryValue: holidaysAverageSalary,
      salaryType: 'holidaysAverageSalary',
    },
    incapacities: {
      title: 'Salario base incapacidades',
      tooltip: 'novelties_average_salary',
      noveltyKey: 'novelties_average_salary',
      salaryValue: noveltiesAverageSalary,
      salaryType: 'noveltiesAverageSalary',
    },
    licenses: {
      title: 'Salario base licencias',
      tooltip: 'licenses_average_salary',
      noveltyKey: 'licenses_average_salary',
      salaryValue: licensesAverageSalary,
      salaryType: 'licensesAverageSalary',
    },
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          marginBottom: theme.spacing(5),
        })}
      >
        {([
          'employee',
          'student_law_789',
          'terminated',
          'part_time_contract',
        ].includes(contractCategory) &&
          noveltyConcept === 'holidays') ||
        noveltyConcept !== 'holidays' ? (
          <SalaryBase
            isEdit={isEdit}
            title={noveltyData[noveltyConcept].title}
            contentTooltip={messages[noveltyData[noveltyConcept].tooltip]}
            onChangeData={(e) =>
              onChangeData(e, noveltyData[noveltyConcept].noveltyKey)
            }
            onEditing={onEnableEditing}
            salaryValue={noveltyData[noveltyConcept].salaryValue}
            salaryType={noveltyData[noveltyConcept].salaryType}
            concept={noveltyConcept === 'holidays' ? 'holidays' : 'all'}
            onDisableEditing={() =>
              onDisableEditing(noveltyData[noveltyConcept].noveltyKey)
            }
            onOK={onOK}
          />
        ) : null}
      </Box>
    </>
  )
}

export default Header

const SalaryBase = ({
  isEdit,
  title,
  contentTooltip,
  onChangeData,
  onEditing,
  salaryValue,
  salaryType,
  concept,
  onDisableEditing,
  onOK,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '9.75rem',
        boxShadow: theme.shadows[4],
        padding: theme.spacing(2),
        borderRadius: '0.5rem',
        borderBottom: `0.25rem solid ${
          concept === 'holidays'
            ? theme.palette.primary.light
            : theme.palette.complementary2.light
        }`,
        [theme.breakpoints.up('tablet')]: {
          width: 'max-content',
          minWidth: '16.125rem',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        })}
      >
        <Typography variant="body1" htmlFor={`input_${salaryType}`}>
          {title}
        </Typography>
        <Tooltip title={contentTooltip} disableInteractive={false} />
      </Box>
      {isEdit ? (
        <TextField
          id={`input_${salaryType}`}
          value={salaryValue}
          onChange={onChangeData}
          InputProps={{
            inputComponent: NumberFormatField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          data-cy={`input-${salaryType}`}
          sx={{
            maxWidth: '14rem',
          }}
        />
      ) : (
        <>
          <Typography
            component="b"
            variant="h1"
            display="block"
            data-cy={`${salaryType}-value`}
          >
            {formatCurrency(salaryValue)}
          </Typography>

          <Button
            onClick={onEditing}
            data-cy={`edit-${salaryType}`}
            variant="text"
            sx={(theme) => ({
              textDecoration: 'underline',
              color: theme.palette.accent4.main,
              padding: 0,
              height: 'auto',
              minWidth: 'max-content',
              '&:hover': {
                color: theme.palette.accent4.main,
              },
            })}
          >
            Editar
          </Button>
        </>
      )}
      {isEdit ? (
        <HeaderActions onCancel={onDisableEditing} onOK={onOK} />
      ) : null}
    </Box>
  )
}

const HeaderActions = ({ onCancel, onOK }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(1),
      })}
    >
      <Button onClick={onOK} size="small" data-cy="ok_button_average_salaries">
        Guardar
      </Button>
      <Button variant="outlined" onClick={onCancel} size="small">
        Cancelar
      </Button>
    </Box>
  )
}
