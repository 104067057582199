import AleluyaPerson from 'components/UI/AleluyaPerson'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { starEyesFace } from 'utils/emojis'
import { getWordSingular } from 'utils/format'

import aleluyaBeachMan from 'assets/images/views/common/aleluya_beach_man.svg'
import aleluyaDocumentsMan from 'assets/images/views/common/aleluya_documents_man_right.svg'

import { SUBSCRIPTION_STATUS, WORKER_INDEX } from 'config/routes'

export const getContentResponse = ({
  value,
  cardBrand,
  cardLastNumber,
  paymentMethodInfo,
  subscriptionType,
  activeUntil,
  state,
  extraworkersPayment,
}) => {
  let contentDialogs = {}
  const bank = typeof paymentMethodInfo === 'string' ? paymentMethodInfo : ''

  const SUBSCRIPTION_DIALOG = {
    APPROVED: {
      icon: <Icon name="smart-lock-card" />,
      subtitle: (
        <>
          Valor próxima{' '}
          {subscriptionType === 'mes' ? 'mensualidad' : 'anualidad'}
        </>
      ),
      body: (
        <b>
          Debitaremos {value}{' '}
          {cardBrand && cardLastNumber
            ? `de tu tarjeta ${cardBrand} ****${cardLastNumber}`
            : `de tu cuenta ${bank}`}{' '}
          cada {subscriptionType}.
        </b>
      ),
    },
    PENDING: {
      icon: <Icon name="smart-lock-card" />,
      subtitle: 'Transacción pendiente',
      body: <b>El pago aún se encuentra en proceso de aprobación.</b>,
    },
    DECLINED: {
      icon: <Icon name="smart-lock-card" />,
      subtitle: 'Transacción rechazada',
      body: (
        <b>
          No pudimos realizar el cobro a través del medio de pago seleccionado.
        </b>
      ),
    },
  }

  const NEXT_PAYMENT_DATE = {
    APPROVED: {
      icon: <Icon name="calendar" />,
      subtitle: 'Fecha del próximo pago',
      body: (
        <>
          <b>{activeUntil}</b> (el cobro aparecerá en tu extracto como grupo
          Quincena S.A.S).
        </>
      ),
    },
  }

  const ELECTRONIC_RECEIPT = {
    APPROVED: {
      icon: <Icon name="document" />,
      subtitle: 'Confirma la información de tu factura',
      body: (
        <b>
          Envia el RUT de tu empresa a <OrganizationEmail variant="billing" />{' '}
          junto al nombre y teléfono del encargado para recibir tu factura.
        </b>
      ),
    },
    PENDING: {
      icon: <Icon name="document" />,
      subtitle: 'Confirma el estado de tu pago',
      body: extraworkersPayment ? (
        <b>
          Si tu dinero fue debitado y aún no puedes agregar nuevas personas,
          escríbenos por el chat o a <OrganizationEmail />.
        </b>
      ) : (
        <b>
          Estamos confirmando tu pago y los ajustes en tu plan. Si tienes dudas,
          escríbenos por el chat o a <OrganizationEmail />.
        </b>
      ),
    },
    DECLINED: {
      icon: <Icon name="document" />,
      subtitle: 'Confirma el estado de tu pago',
      body: (
        <b>
          Si el error persiste, escríbenos por el chat o a <OrganizationEmail />
          .
        </b>
      ),
    },
  }

  if (state === 'APPROVED') {
    contentDialogs = {
      subscription: SUBSCRIPTION_DIALOG.APPROVED,
      paymentDate: NEXT_PAYMENT_DATE.APPROVED,
      electronicReceipt: ELECTRONIC_RECEIPT.APPROVED,
    }
  } else if (state === 'PENDING') {
    contentDialogs = {
      subscription: SUBSCRIPTION_DIALOG.PENDING,
      electronicReceipt: ELECTRONIC_RECEIPT.PENDING,
    }
  } else {
    contentDialogs = {
      subscription: SUBSCRIPTION_DIALOG.DECLINED,
      electronicReceipt: ELECTRONIC_RECEIPT.DECLINED,
    }
  }

  return contentDialogs
}

export const getContentSummary = ({
  state,
  name,
  plan,
  extraworkersPayment,
  extraworkersNumber,
  modalityChange,
  downLaptop,
  newPlan,
}) => {
  let contentSummary = {}

  const SUMMARY_TITLE = {
    APPROVED: <>¡Gracias, {name}! Recibimos tu pago.</>,
    PENDING: (
      <>
        Estamos procesando tu pago, {name}. Te enviaremos un email cuando se
        complete.
      </>
    ),
    DECLINED: <>Lo sentimos, {name}. Algo ha ocurrido con tu pago.</>,
  }

  const modalityChangeText = modalityChange
    ? 'Cambiaste la frecuencia de pago de tu plan y ahora'
    : 'Ahora'

  const SUMMARY_SUBTITLE = {
    APPROVED: extraworkersPayment ? (
      <>
        Ahora puedes tener{' '}
        <b>
          {extraworkersNumber}{' '}
          {getWordSingular('personas', extraworkersNumber === 1)} más{' '}
        </b>{' '}
        en tu empresa.
      </>
    ) : (
      <>
        {modalityChangeText} disfrutarás de{' '}
        {plan === 'Superpoderes' ? 'las funcionalidades' : 'los superpoderes'}{' '}
        de <b>Aleluya {plan}</b> para llevar la gestión de{' '}
        <b>nómina y recursos humanos</b> al máximo nivel.
        <Emoji code={starEyesFace} />
        <br />
        <br />
        ¡Estamos emocionados de que aproveches todas las funcionalidades que
        tenemos para ti! Revisa tu correo para consultar la información de la
        transacción ✉️.
      </>
    ),
    PENDING: extraworkersPayment ? (
      <>
        El pago para agregar personas adicionales se encuentra en proceso de
        aprobación. Una vez sea aprobado, podrás agregar a las nuevas personas.
      </>
    ) : (
      <>
        No te preocupes. Mientras el pago se aprueba, puedes disfrutar de todas
        las funcionalidades{' '}
        {newPlan ? (
          <>
            del plan <b>{newPlan}</b>
          </>
        ) : (
          'de tu nuevo plan'
        )}
        .
      </>
    ),
    DECLINED: (
      <>
        El cambio que intentaste realizar, no pudo ser aplicado. Revisa tu
        correo para consultar más información acerca de la transacción ✉️.
      </>
    ),
  }

  const SUMMARY_IMAGE = {
    APPROVED: (
      <AleluyaPerson
        color="primary"
        width={downLaptop ? 200 : 275}
        height={downLaptop ? 240 : 330}
        viewBox="0 0 340 400"
      />
    ),
    PENDING: aleluyaDocumentsMan,
    DECLINED: aleluyaBeachMan,
  }

  contentSummary = {
    heading: SUMMARY_TITLE[state] || SUMMARY_TITLE.DECLINED,
    description: SUMMARY_SUBTITLE[state],
    image: SUMMARY_IMAGE[state] || SUMMARY_IMAGE.DECLINED,
  }

  if (state === 'PENDING') {
    contentSummary.imageConfig = {
      height: downLaptop ? 200 : 400,
      width: downLaptop ? 124 : 248,
    }
  } else if (state === 'DECLINED') {
    contentSummary.imageConfig = {
      maxHeight: downLaptop ? 200 : 400,
      maxWidth: downLaptop ? 155 : 310,
    }
  } else {
    contentSummary.imageConfig = {}
  }

  return contentSummary
}

export const getActionButtons = (state, extraworkersPayment) => {
  let buttons = {}

  if (
    state?.paymentStatus === 'DECLINED' ||
    state?.paymentStatus === undefined
  ) {
    buttons = {
      ...buttons,
      primary: {
        to: extraworkersPayment ? WORKER_INDEX() : SUBSCRIPTION_STATUS(),
        text: 'Intentar de nuevo',
      },
    }
  } else {
    buttons = {
      ...buttons,
      primary: {
        href: 'https://efectoaleluya.zohodesk.com/portal/es/kb/cómo-empezar',
        target: '_blank',
        text: 'Accede gratis al curso Aleluya',
      },
    }
  }

  return buttons
}
