import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import SlideShow from 'components/UI/SlideShow/Index'

import { getDistributor } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { isTest } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import { ReactComponent as DocumentsMan } from 'assets/images/views/common/aleluya_documents_man.svg'

import DescriptionCard from '../Atoms/DescriptionCard'
import SummaryTable from '../Summary/Index'
import PayrollActions from './ActionGroup/Actions'
import PeriodTotals from './PeriodTotals'
import {
  concatTitlePaidSocialBenefits,
  getPaidSocialBenefits,
  usePeriod,
  useReturnPayPayroll,
} from './helpers'

const GeneratedPayroll = () => {
  const distributor = getDistributor()
  const labelDescriptionCard =
    distributor === 'nominapp'
      ? '¡Aleluya! Liquidaste tu nómina en menos de lo que canta un gallo.'
      : '¡lo has logrado, liquidaste tu nómina!'
  const { user } = useUser()
  const {
    period: { contracts_counter: contractsCounter = {}, id: periodId },
    options: { social_benefits: socialBenefits = [] },
    payPreviousPayroll,
    hasEarlyPayment,
  } = usePeriod()
  const handleReturnToPayPayroll = useReturnPayPayroll()
  const { showInfoMessage } = useNotifications()
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const payrollsCacheData = queryClient.getQueryData(
    ['periodPayrolls', companyId, periodId],
    {
      exact: false,
    }
  )
  const hasEarlyPaymentFromCache = payrollsCacheData?.data?.early_payment

  const getSlides = () => {
    const paidSocialBenefits = getPaidSocialBenefits(socialBenefits)
    const titles = paidSocialBenefits?.map(({ label }) => label)
    const title = concatTitlePaidSocialBenefits(titles)

    const slides = [
      {
        id: 'generated_payroll',
        content: (
          <DescriptionCard
            data={{
              category: 'generated_payroll',
              label: `${user.name || ''} ${labelDescriptionCard}`,
            }}
            textColor="white.main"
            actionOnClick={async () => {
              await handleReturnToPayPayroll()
            }}
            distributor={distributor}
          />
        ),
      },
    ]

    if (paidSocialBenefits.length > 0) {
      slides.push({
        id: 'payment_severance',
        content: (
          <DescriptionCard
            data={{
              category: 'paid_benefit',
              label: `${title}`,
              paidSocialBenefits,
            }}
            adornmentColor="white"
            textColor="white.main"
            actionOnClick={handleReturnToPayPayroll}
            distributor={distributor}
            imageComponent={DocumentsMan}
          />
        ),
      })
    }

    return slides
  }

  const showExclusiveFileAlert =
    ((hasEarlyPaymentFromCache && hasEarlyPayment) ||
      hasEarlyPaymentFromCache ||
      contractsCounter.terminated !== 0) &&
    !payPreviousPayroll &&
    !isTest

  useEffect(() => {
    if (showExclusiveFileAlert) {
      showInfoMessage(
        'Recuerda que las personas liquidadas o con pago anticipado de vacaciones (sin días trabajados después de vacaciones) genera un archivo exclusivo para pagar en el banco.',
        { persist: true, preventDuplicate: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!payPreviousPayroll ? (
        <div>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridColumn: '1 / -1',
              columnGap: theme.spacing(3),
              rowGap: theme.spacing(3),
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridAutoRows: 'max-content',
              [theme.breakpoints.up('tablet')]: {
                gridTemplateColumns: 'repeat(6, 1fr)',
                columnGap: theme.spacing(4),
              },
              [theme.breakpoints.up('desktop')]: {
                gridTemplateColumns: 'repeat(12, 1fr)',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                borderRadius: '1rem',
                gridColumn: '1 / -1',
                [theme.breakpoints.up('desktop')]: {
                  gridColumn: 'span 8',
                },
              })}
            >
              <SlideShow
                slides={getSlides()}
                delay={3000}
                showDots
                sx={{
                  height: '100%',
                  borderRadius: '1rem',
                }}
              />
            </Box>
            <Box
              sx={(theme) => ({
                gridColumn: '1 / -1',
                [theme.breakpoints.up('desktop')]: {
                  gridColumn: 'span 4',
                },
                height: '100%',
              })}
            >
              <Typography
                variant="h4"
                sx={(theme) => ({
                  marginBottom: theme.spacing(2),
                })}
              >
                Esto es lo que debes pagar
              </Typography>
              <PeriodTotals stack compact />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(8.75),
              paddingBottom: theme.spacing(10),
            })}
          >
            <PayrollActions />
          </Box>
        </div>
      ) : null}
      {payPreviousPayroll ? (
        <Box>
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(4),
            })}
          >
            Resumen novedades en este periodo
          </Typography>
          <SummaryTable />
        </Box>
      ) : null}
    </Box>
  )
}

export default GeneratedPayroll
