import { Box } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'

import messages from 'messages/CompanyConfiguration'

import SectionCard from '../SectionCard'

const PaymentConfiguration = ({ options }) => {
  return (
    <SectionCard subtitle="Configuración de pago">
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <FormField
          name="general_incapacity_payment"
          label="Forma de cálculo para la incapacidad general"
          variant="select"
          options={options.general_incapacity_payment}
          tooltipContent={messages.general_incapacity_payment}
          optional={false}
        />
        <FormField
          name="proportional_deductions"
          label="Forma de pago de las deducciones"
          variant="select"
          options={options.proportional_deductions}
          tooltipContent={messages.proportional_deductions}
          optional={false}
        />
        <FormField
          name="transport_subsidy_per_period"
          label="¿Cómo se paga el subsidio de transporte?"
          variant="select"
          options={options.transport_subsidy_per_period}
          tooltipContent={messages.transport_subsidy_per_period}
          optional={false}
        />
        <FormField
          name="transport_subsidy_base"
          label="¿Ingresos salariales afectan subsidio de transporte?"
          variant="select"
          options={options.transport_subsidy_base}
          tooltipContent={messages.transport_subsidy_base}
          optional={false}
        />
        <FormField
          name="pay_holidays_31"
          label="¿Pagar 31 del mes en vacaciones?"
          variant="select"
          options={options.pay_holidays_31}
          tooltipContent={messages.pay_holidays_31}
          optional={false}
        />
        <FormField
          name="worker_pension_unpaid_license"
          label="¿Pagar pensión completa en licencias no remuneradas?"
          variant="select"
          options={options.worker_pension_unpaid_license}
          tooltipContent={messages.worker_pension_unpaid_license}
          optional={false}
        />
        <FormField
          name="pay_february_extra_days_if_hired_in_month"
          label="¿Cómo es el pago de febrero si es contratado durante el mes?"
          variant="select"
          options={options.pay_february_extra_days_if_hired_in_month}
          tooltipContent={messages.pay_february_extra_days_if_hired_in_month}
          optional={false}
        />
      </Box>
    </SectionCard>
  )
}

export default PaymentConfiguration
