import http, { getApiURL } from '../httpService'

export const getSubscription = ({
  extraWorkersNumber = null,
  crmEvent = null,
}) => {
  const url = getApiURL({
    pathname: '/subscription',
    searchParams: {
      extra_workers_number: extraWorkersNumber,
      crm_event: crmEvent,
    },
  })

  return http.getV2(url)
}

export const updateSubscription = (
  newPlanId,
  subscriptionType,
  isAPartnerChild
) => {
  let searchParams = {}

  if (!newPlanId && !isAPartnerChild)
    searchParams = { active_premium_trial: true }

  if (newPlanId)
    searchParams = {
      plan_id: newPlanId,
      subscription_type: subscriptionType,
    }

  const url = getApiURL({
    pathname: '/subscription',
    searchParams,
  })

  return http.putV2(url)
}

export const getImmediatePaymentValue = (
  newPlanId,
  subscriptionType,
  extraWorkers = 0
) => {
  const url = getApiURL({
    pathname: '/subscription',
    searchParams: {
      plan_id: newPlanId,
      extra_workers_number: extraWorkers,
      subscription_type: subscriptionType,
    },
  })

  return http.getV2(url)
}

export const getNextCyclePaymentValue = (newPlanId, workersNumber = 0) => {
  const searchParams = workersNumber
    ? { workers_number: workersNumber }
    : { without_extra_workers: true }

  const url = getApiURL({
    pathname: `/plans/${newPlanId}`,
    searchParams,
  })

  return http.getV2(url)
}

export const getPlans = () => {
  const url = getApiURL({ pathname: '/plans' })

  return http.getV2(url)
}

export const getAllPayments = ({ page } = {}) => {
  const url = getApiURL({ pathname: '/payments', searchParams: { page } })

  return http.getV2(url)
}

export const sendInvoice = (paymentId) => {
  const url = getApiURL({
    pathname: `/payments/${paymentId}/send_invoice`,
  })

  return http.postV2(url)
}

export const updateAutomaticDebit = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/payments/automatic_debit' })

  const data = { payment_info: paymentMethodData }

  return http.putV2(url, data)
}

export const addCreditCard = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/payments/credit_card' })

  const data = { payment_info: paymentMethodData }

  return http.putV2(url, data)
}

export const addPSEPayment = (paymentMethodData) => {
  const url = getApiURL({ pathname: '/payments/pse' })

  const data = { payment_info: paymentMethodData }

  return http.postV2(url, data)
}

export const getPaymentResponsePSE = () => {
  const url = getApiURL({ pathname: '/payments/pse' })

  return http.getV2(url)
}

export const getExtraWorkersPlan = (planId, extraWorkers = 0) => {
  const url = getApiURL({
    pathname: `/plans/${planId}`,
    searchParams: {
      workers_number: extraWorkers,
    },
  })

  return http.getV2(url)
}

export const cancelSubscription = (cancellationData) => {
  const url = getApiURL({ pathname: '/subscription_cancellation' })

  return http.putV2(url, cancellationData)
}

export default {
  getSubscription,
  getAllPayments,
  sendInvoice,
  getPaymentResponsePSE,
  getExtraWorkersPlan,
  getPlans,
  updateAutomaticDebit,
  addCreditCard,
  addPSEPayment,
  cancelSubscription,
  updateSubscription,
  getNextCyclePaymentValue,
  getImmediatePaymentValue,
}
