import { isBefore, isToday, parseISO } from 'date-fns'

import { Box, Button, Typography, alpha } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import AdvancedSubscriptionConfirmationModal from 'components/Subscription/Index/Plan/AdvancedSubscriptionConfirmationModal'
import Adornment from 'components/UI/Adornment'
import Icon from 'components/UI/Icon'

import { formatDisplayDate } from 'utils/dateTime'

import useSubscription from '../../useSubscription'

const ActiveUntilCard = () => {
  const modals = useModals()
  const { subscription } = useSubscription()
  const {
    active_until: activeUntil,
    status,
    plan,
    type: currentSubscriptionType,
    payrolls_size: currentWorkersNumber,
  } = subscription || {}

  const currentDate = new Date()
  const activeUntilDate = parseISO(activeUntil)

  const isValidDate =
    isBefore(currentDate, activeUntilDate) || isToday(activeUntilDate)

  const hasAnActivePlan = status === 'premium_canceled' && isValidDate

  const handleContinueUpdateSubscription = () => {
    modals.openModal({
      id: 'paymentModal',
      content: (
        <AdvancedSubscriptionConfirmationModal
          selectedPlan={plan}
          selectedModality={currentSubscriptionType}
          isAnUpgrade={false}
          currentPlanPayment
          activeUntil={activeUntil}
          planUpgrade={false}
          modalityUpgrade={false}
          currentWorkersNumber={currentWorkersNumber}
          isChangingModality={false}
          isCurrentPlan
          isPremiumExpiredSubscription
        />
      ),
      modalProps: {
        header: `Recupera tu plan ${plan.name}`,
        hideFooter: true,
        adornment: [
          {
            variant: 'signature',
            color: 'accent4.light',
            width: 165,
            height: 182,
            sx: {
              bottom: 85,
              right: 75,
            },
          },
        ],
        paperSx: {
          maxWidth: '45rem',
          width: '100%',
        },
      },
    })
  }

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        borderRadius: '0.5rem',
        padding: theme.spacing(2, 3),
        overflow: 'hidden',
        boxShadow: theme.shadows[3],
        backgroundColor: hasAnActivePlan
          ? theme.palette.primary.main
          : alpha(theme.palette.error.light, 0.3),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          marginBottom: theme.spacing(1),
          alignItems: 'flex-end',
          columnGap: theme.spacing(0.5),
        })}
      >
        <Icon name="calendar" />
        <Typography variant="lead1" sx={{ zIndex: 1 }}>
          {hasAnActivePlan ? 'Cuenta activa hasta el:' : 'Tu plan venció el:'}
        </Typography>
      </Box>
      <Typography variant="h2">{formatDisplayDate(activeUntil)}</Typography>
      {!hasAnActivePlan ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(2),
          })}
        >
          <Button variant="outlined" onClick={handleContinueUpdateSubscription}>
            Renovar mi plan
          </Button>
        </Box>
      ) : null}
      <Adornment
        variant="asterisk"
        height="5rem"
        width="6rem"
        color={hasAnActivePlan ? 'primary.dark' : 'error.dark'}
        sx={{
          top: '-1rem',
          right: '-1rem',
        }}
      />
    </Box>
  )
}

export default ActiveUntilCard
