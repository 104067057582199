import { useMemo } from 'react'

import { Box, IconButton } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatCurrency } from 'utils/format'

import { usePeriod, usePeriodAPI } from '../helpers'
import PayrollCostDetail from './PayrollCostDetail'
import { getNoveltiesCost } from './helpers'

const AddNovelties = ({ payroll = {}, dataCy }) => {
  const { period } = usePeriod()
  const total = getNoveltiesCost(payroll?.details)
  const formattedTotal = formatCurrency(Math.abs(total))
  const { openNoveltyTypeModal, openPeriodEditingInactiveModal } =
    usePeriodAPI()
  const { details = {}, contract_category: contractCategory } = payroll
  const hasNovelties = useMemo(() => {
    let status = false

    Object.keys(details).forEach((noveltyKey) => {
      if (status) return

      if (details[noveltyKey]?.value > 0 || details[noveltyKey]?.quantity > 0) {
        status = true
      }
    })

    return status
  }, [details])

  const handleEditingNoveltyModal = () => {
    if (!period.editable && !payroll.locked) {
      openPeriodEditingInactiveModal()
    } else {
      openNoveltyTypeModal({
        workerName: payroll.worker_name,
        workerContractCategory: contractCategory,
        workerPayroll: payroll,
      })
    }
  }

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
      })}
    >
      <PayrollCostDetail
        total={total >= 0 ? formattedTotal : `(${formattedTotal})`}
        noveltiesCost={payroll?.details}
      />
      <IconButton
        sx={{
          backgroundColor: 'black.main',
          '&:hover': { backgroundColor: 'black.light' },
          '&:focus': { backgroundColor: 'black.main' },
          width: '1.7rem',
          height: '1.7rem',
        }}
        onClick={handleEditingNoveltyModal}
        size="small"
        data-cy={dataCy}
      >
        {hasNovelties ? (
          <Icon name="edit-pencil" basic color="white" fontSize="small" />
        ) : (
          <Icon name="plus" basic color="white" fontSize="small" />
        )}
      </IconButton>
    </Box>
  )
}

export default AddNovelties
