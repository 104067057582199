import { Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { formatDisplayDate } from 'utils/dateTime'
import { starEyesFace } from 'utils/emojis'
import { formatCurrency, getWordSingular } from 'utils/format'

export const getModalDialogue = (subscriptionTypeValidators) => {
  if (subscriptionTypeValidators?.currentPlanPayment) {
    return 'currentPlanPayment'
  }

  if (subscriptionTypeValidators?.isPremiumExpiredSubscription) {
    return 'isPremiumExpiredSubscription'
  }

  if (
    subscriptionTypeValidators?.isCurrentPlan &&
    subscriptionTypeValidators?.isChangingModality
  ) {
    return 'modality'
  }

  if (
    !subscriptionTypeValidators?.isCurrentPlan &&
    subscriptionTypeValidators?.isChangingModality
  ) {
    return 'modalityPlan'
  }

  return 'plan'
}

export const modalDialogues = (currentPlan) => {
  const data = {
    isPremiumExpiredSubscription: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Continúa disfrutando de{' '}
          {currentPlan === 'Superpoderes'
            ? 'las funcionalidades'
            : 'los superpoderes'}{' '}
          que Aleluya te brinda con el plan <b>{currentPlan}</b> y sigue
          llevando la gestión de <b>nómina y recursos humanos</b> al máximo
          nivel. <Emoji code={starEyesFace} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          ¡Estamos emocionados de que sigas aprovechando todas las
          funcionalidades que tenemos para ti!
        </Typography>
      </>
    ),
    currentPlanPayment: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Continúa disfrutando de{' '}
          {currentPlan === 'Superpoderes'
            ? 'las funcionalidades'
            : 'los superpoderes'}{' '}
          de <b>Aleluya {currentPlan}</b> para seguir llevando la gestión de{' '}
          <b>nómina y recursos humanos</b> al máximo nivel.{' '}
          <Emoji code={starEyesFace} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          ¡Estamos emocionados de que sigas aprovechando todas las
          funcionalidades que tenemos para ti!
        </Typography>
      </>
    ),
    modalityPlan: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Cambiaste la frecuencia de pago de tu plan y ahora disfrutarás de{' '}
          {currentPlan === 'Superpoderes'
            ? 'las funcionalidades'
            : 'los superpoderes'}{' '}
          de <b>Aleluya {currentPlan}</b> para llevar la gestión de{' '}
          <b>nómina y recursos humanos</b> al máximo nivel.{' '}
          <Emoji code={starEyesFace} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          ¡Estamos emocionados de que aproveches todas las funcionalidades que
          tenemos para ti!
        </Typography>
      </>
    ),
    plan: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Ahora disfrutarás de{' '}
          {currentPlan === 'Superpoderes'
            ? 'las funcionalidades'
            : 'los superpoderes'}{' '}
          de <b>Aleluya {currentPlan}</b> para llevar la gestión de{' '}
          <b>nómina y recursos humanos</b> al máximo nivel.{' '}
          <Emoji code={starEyesFace} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          ¡Estamos emocionados de que aproveches todas las funcionalidades que
          tenemos para ti!
        </Typography>
      </>
    ),
    modality: (
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(3) })}
      >
        Cambiaste la frecuencia de pago de tu plan <b>Aleluya {currentPlan}</b>
        <br />
        Sigue disfrutando de tus superpoderes.
      </Typography>
    ),
  }

  return data
}

export const getTransactionResponseData = (subscription, totalPayment) => {
  const { active_until: activeUntil, payrolls_size: workersNumber } =
    subscription

  const data = [
    {
      id: 'active_workers',
      label: 'Personas activas',
      value: (
        <>
          {workersNumber} {getWordSingular('personas', workersNumber === 1)}
        </>
      ),
      icon: <Icon name="female-and-male" />,
      backgroundColor: 'primary.main',
      adornment: 'asterisk',
      adornmentColor: 'primary.dark',
      height: '3.8rem',
      width: '3.8rem',
    },
    {
      id: 'active_until',
      label: 'Fecha del próximo pago',
      value: formatDisplayDate(activeUntil),
      icon: <Icon name="calendar" />,
      backgroundColor: 'complementary2.light',
      adornment: 'explosion',
      adornmentColor: 'complementary2.dark',
      height: '4.37rem',
      width: '4.37rem',
    },
    {
      id: 'value',
      label: `Nuevo valor ${
        subscription.type === 'month' ? 'mensual' : 'anual'
      }`,
      value: formatCurrency(totalPayment),
      icon: <Icon name="document-coin" />,
      backgroundColor: 'accent2.main',
      adornment: 'spring',
      adornmentColor: 'accent2.dark',
      height: '3rem',
      width: '3rem',
    },
  ]

  return data
}

export default getTransactionResponseData
