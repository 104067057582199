import { formatLocateDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

const getFormattedResponse = (
  user,
  location,
  subscription,
  pseResponse,
  isPsePayment
) => {
  let dataFormatted = {
    name: user?.name,
    paymentMethodInfo: {},
    plan: subscription?.plan?.name,
    newPlan: pseResponse?.plan_name || null,
    type: subscription.type === 'month' ? 'mes' : 'año',
    value: formatCurrency(
      subscription?.type === 'year'
        ? subscription?.year_value
        : subscription?.month_value
    ),
    response: { state: { paymentStatus: null, planId: null } },
  }

  if (subscription.payment_method_info !== null)
    dataFormatted.paymentMethodInfo = subscription.payment_method_info
  if (subscription.active_until !== null)
    dataFormatted.activeUntil = formatLocateDateString(
      subscription.active_until
    )
  if (location.state !== undefined) {
    dataFormatted.response.state.paymentStatus = location.state?.payment_status
    dataFormatted.response.extraworkersPayment =
      location.state?.from_extraworkers_modal
  }
  if (isPsePayment)
    dataFormatted = {
      ...dataFormatted,
      response: {
        extraworkersPayment:
          pseResponse?.payment_type === 'year_additional_workers',
        extraworkersNumber: pseResponse?.paid_worker,
        modalityChange: pseResponse?.suscription_type_change,
        state: {
          paymentStatus: pseResponse?.payment_status,
          planId: pseResponse?.plan_id,
        },
      },
    }

  return dataFormatted
}

export default getFormattedResponse
