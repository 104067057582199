import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Adornment from 'components/UI/Adornment'

import HelloWoman from 'assets/images/views/common/hello_woman.svg'

import useSubscription from '../useSubscription'
import {
  getModalDialogue,
  getTransactionResponseData,
  modalDialogues,
} from './helpers'

const TransactionResponseModal = ({
  subscriptionTypeValidators,
  handleClose,
}) => {
  const { subscription } = useSubscription()

  const paymentPeriodicity =
    subscription.type === 'month' ? 'monthly' : 'yearly'

  const totalPayment =
    paymentPeriodicity === 'monthly'
      ? subscription?.month_value
      : subscription?.year_value

  const currentPlanName = subscription.plan.name

  const transactionResponseData = getTransactionResponseData(
    subscription,
    totalPayment
  )

  const modalDialogue = modalDialogues(currentPlanName)
  const switcher = getModalDialogue(subscriptionTypeValidators)

  const tabletDown = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <>
      {modalDialogue[switcher]}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: '1fr 7.313rem',
            columnGap: theme.spacing(4),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: theme.spacing(3),
            rowGap: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {transactionResponseData?.map((transactionResponse) => {
            const {
              id,
              icon,
              label,
              value,
              adornment,
              height,
              width,
              backgroundColor,
              adornmentColor,
            } = transactionResponse
            return (
              <Box
                key={id}
                sx={(theme) => ({
                  position: 'relative',
                  backgroundColor,
                  borderRadius: '1rem',
                  padding: theme.spacing(1.5, 2, 2),
                  maxWidth: '16rem',
                  height: '6.5rem',
                  overflow: 'hidden',
                  [theme.breakpoints.down('tablet')]: {
                    maxWidth: '100%',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    marginBottom: theme.spacing(2),
                    alignItems: 'center',
                    columnGap: theme.spacing(0.5),
                  })}
                >
                  {icon}
                  <Typography variant="body2" sx={{ zIndex: 1 }}>
                    {label}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h3">{value}</Typography>
                </Box>
                <Adornment
                  variant={adornment}
                  height={height}
                  width={width}
                  color={adornmentColor}
                  sx={{
                    top: '-0.5rem',
                    right: '-0.5rem',
                    transform: 'rotate(30deg)',
                  }}
                />
              </Box>
            )
          })}
        </Box>
        {!tabletDown ? (
          <Box
            component="img"
            alt="Disfruta de tus nuevos superpoderes"
            src={HelloWoman}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          marginTop: theme.spacing(7),
          [theme.breakpoints.down('tablet')]: {
            justifyContent: 'center',
            alignSelf: 'flex-end',
          },
        })}
      >
        <Button
          size="large"
          onClick={handleClose}
          sx={(theme) => ({ padding: theme.spacing(0, 10) })}
        >
          Finalizar
        </Button>
      </Box>
    </>
  )
}

export default TransactionResponseModal
