import { Box } from '@mui/material'

const Emoji = ({ code }) => {
  return (
    <Box
      component="span"
      sx={{
        fontWeight: 100,
      }}
    >
      {code}
    </Box>
  )
}

export default Emoji
