import { Box, Typography } from '@mui/material'

import EventCard from './EventCard'
import EventDetailModal from './EventDetailModal'
import useEventDetailModal from './useEventDetailModal'

const ComingSoonEvents = ({
  anniversaries,
  birthdays,
  contractsToExpire,
  isLoading,
}) => {
  const { eventDetailModalState, openEventDetailModal, closeEventDetailModal } =
    useEventDetailModal()

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumn: '1 / -1',
        gap: theme.spacing(1),
        [theme.breakpoints.up('laptop')]: {
          gridColumn: '10 / 13',
          marginTop: theme.spacing(-12.5),
          gridRow: '1 / 4',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          '&::after': {
            content: '""',
            display: 'block',
            height: '2rem',
            marginTop: theme.spacing(-1),
          },
        },
      })}
    >
      <Typography
        sx={(theme) => ({
          ...theme.typography.h4,
          [theme.breakpoints.only('laptop')]: {
            ...theme.typography.h5,
          },
        })}
      >
        Fechas importantes
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          rowGap: theme.spacing(2),
          columnGap: theme.spacing(4),
          gridTemplateColumns: '1fr',
          [theme.breakpoints.between('tablet', 'laptop')]: {
            gridTemplateColumns: '1fr 1fr 1fr',
          },
        })}
      >
        <EventCard
          eventType="birthdays"
          isLoading={isLoading}
          data={birthdays}
          handleShowDetail={openEventDetailModal}
        />
        <EventCard
          eventType="anniversaries"
          isLoading={isLoading}
          data={anniversaries}
          handleShowDetail={openEventDetailModal}
        />
        <EventCard
          eventType="contracts_to_expire"
          isLoading={isLoading}
          data={contractsToExpire}
          handleShowDetail={openEventDetailModal}
        />
      </Box>
      {eventDetailModalState.open ? (
        <EventDetailModal
          state={eventDetailModalState}
          handleClose={closeEventDetailModal}
        />
      ) : null}
    </Box>
  )
}

export default ComingSoonEvents
